// Lib
import React, { useEffect } from "react";

// Own components
import { Account } from "@components";

// Hooks
import { useAccountTeams, useGetRights } from "@hooks";

// Utils
import { isArrayWithContent } from "@utils";

//types
import type { Team } from "@types";

interface Props {
    selectedTeam?: Team;
    setTeam?: (selectedTeam: Team) => void;
    isSelectTeamPage?: boolean;
}

/**
 * Select team
 */
const SelectTeam = ({ selectedTeam, setTeam, isSelectTeamPage }: Props) => {
    /**
     * API
     */

    // Get rights
    const {
        getRights,
        response: rights,
        loading: fetchingRights,
    } = useGetRights();

    // Fetch account teams
    const {
        getAccountTeams,
        response: accountTeams,
        loading: fetchingTeams,
    } = useAccountTeams();

    /**
     * Initial fetch
     */
    useEffect(() => {
        let unmounted = false;

        if (!unmounted) {
            getAccountTeams();
            getRights();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    /**
     * Check if a team is already selected
     * if yes fetch it's users otherwise set the first one as a selected
     */
    useEffect(() => {
        if (!selectedTeam && !isArrayWithContent(accountTeams?.data?.records))
            return;

        if (
            !!setTeam &&
            !selectedTeam &&
            isArrayWithContent(accountTeams?.data?.records)
        ) {
            setTeam(accountTeams?.data?.records[0]);
        }
    }, [selectedTeam, accountTeams]);

    /**
     * Render
     */
    return (
        <Account
            accountTeams={{
                data: accountTeams?.data?.records,
                loading: fetchingTeams,
            }}
            rights={{ data: rights?.data, loading: fetchingRights }}
            selectedTeam={selectedTeam}
            setTeam={setTeam ? team => setTeam(team) : undefined}
            isSelectTeamPage={isSelectTeamPage}
        />
    );
};
export default React.memo(SelectTeam);
