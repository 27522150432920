// icons
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import InfoIcon from "@mui/icons-material/Info";

// lib
import {
    FormHelperText,
    Grid,
    InputLabel,
    TextField,
    Typography,
    InputAdornment,
    Tooltip,
    CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { type FormikProps } from "formik";
import React, { useCallback } from "react";

// Components
import { Autocomplete, Select } from "@components";

// Types
import {
    Brands,
    ContractCountries,
    Indications,
    Account,
    TherapeuticAreas,
    GeneralInformation,
} from "@types";

// Utils
import { convertDateForAPI, datePlaceholder } from "@utils";

// Style
import * as style from "./style.module.scss";
import { PERIODICITY } from "@constants";
import { useSelectedCountry } from "@hooks";

/**
 * Props type
 */
interface Props {
    formik: FormikProps<GeneralInformation>;
    brands: Brands;
    indications: Indications;
    therapeuticAreas: TherapeuticAreas;
    countries: ContractCountries;
    partners: {
        loading: boolean;
        data: Array<Account>;
        onSearch: (query: string) => void;
    };
    isContractRefUnique?: boolean;
    contractRefValidation?: {
        isNotUniqueValue: boolean;
        isNotUniqueMessage: boolean;
        duplicationValidating: boolean;
    };
    setResetFlowWarning?: (
        field: string,
        pendingKey: string,
        pendingValue: any,
        oldValue?: any,
    ) => void;
    id: string;
    resetCountryRelatedFields: (country: {
        isoCode: string;
        name: string;
    }) => void;
    canResetFlow?: boolean;
    isPerformanceBasedContract?: boolean;
}

/**
 * General Information Form
 */
const GeneralInformationForm: React.FC<Props> = (
    {
        formik,
        brands,
        indications,
        therapeuticAreas,
        countries,
        partners,
        contractRefValidation,
        setResetFlowWarning,
        resetCountryRelatedFields,
        id,
        canResetFlow,
        isPerformanceBasedContract,
    }, // isContractRefUnique,
) => {
    const { isGermanyTeam } = useSelectedCountry();
    /**
     * Show the warning and reset the flow
     */
    const resetFlow = useCallback(
        (
            field: string,
            pendingKey: string,
            pendingValue: any,
            oldValue?: any,
        ) => {
            setResetFlowWarning &&
                setResetFlowWarning(field, pendingKey, pendingValue, oldValue);
        },
        [canResetFlow],
    );

    return (
        <form
            noValidate
            onBlur={formik.handleBlur}
            className={style.form}
            id={`${id}-general-info-form`}
        >
            <Grid item xs={12} container rowSpacing={3} columnSpacing={4}>
                <Grid item xs={12} md={6}>
                    <InputLabel
                        error={
                            (!!formik.errors.reference &&
                                !!formik.touched.reference) ||
                            contractRefValidation?.isNotUniqueValue
                        }
                        id={`${id}-reference-label`}
                        shrink
                    >
                        {"Contract reference (*)"}
                    </InputLabel>

                    <TextField
                        fullWidth
                        name="reference"
                        size="small"
                        id={`${id}-reference-input`}
                        autoComplete="off"
                        value={formik.values.reference}
                        onChange={formik.handleChange}
                        variant="outlined"
                        error={
                            (!!formik.errors.reference &&
                                !!formik.touched.reference) ||
                            contractRefValidation?.isNotUniqueValue
                        }
                        InputProps={
                            contractRefValidation?.duplicationValidating
                                ? {
                                      endAdornment: (
                                          <InputAdornment position="start">
                                              <CircularProgress
                                                  color="inherit"
                                                  size={20}
                                              />
                                          </InputAdornment>
                                      ),
                                  }
                                : contractRefValidation?.isNotUniqueValue
                                  ? {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip
                                                    open={true}
                                                    arrow
                                                    title={
                                                        contractRefValidation?.isNotUniqueMessage
                                                    }
                                                    placement="top"
                                                >
                                                    <InfoIcon color="error" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }
                                  : undefined
                        }
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel
                        error={
                            !!formik.errors.country && !!formik.touched.country
                        }
                        id={`${id}-country-label`}
                        shrink
                    >
                        {"Country (*)"}
                    </InputLabel>
                    <Select
                        value={formik.values.country}
                        id={`${id}-select-country`}
                        name="country"
                        onChange={(_, index) => {
                            if (canResetFlow) {
                                resetFlow(
                                    "country",
                                    "country",
                                    countries.data[index],
                                );
                                return;
                            }
                            if (formik.values?.country?.isoCode) {
                                resetCountryRelatedFields(
                                    countries.data[index],
                                );
                                return;
                            }
                            formik.setFieldValue(
                                "country",
                                countries.data[index],
                            );
                        }}
                        error={
                            !!formik?.errors?.country &&
                            !!formik?.touched?.country
                        }
                        menuItemLabel={"name"}
                        menuItemId="isoCode"
                        list={countries.data}
                        loading={countries.loading}
                        onBlur={() => formik.setFieldTouched("country", true)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel
                        error={
                            !!formik.errors.startDate &&
                            !!formik.touched.startDate
                        }
                        shrink
                        id={`${id}-start-date-picker`}
                    >
                        {"Start date (*)"}
                    </InputLabel>

                    <DatePicker
                        value={datePlaceholder(formik.values.startDate)}
                        onChange={value => {
                            if (canResetFlow) {
                                resetFlow(
                                    "start date",
                                    "startDate",
                                    convertDateForAPI(value),
                                    formik.values.startDate,
                                );
                            }

                            formik.setFieldTouched("startDate", true);

                            formik.setFieldValue(
                                "startDate",
                                convertDateForAPI(value),
                            );
                        }}
                        format="dd/MM/yyyy"
                        slotProps={{
                            textField: {
                                variant: "outlined",
                                autoComplete: "off",
                                sx: { svg: { color: "#036", opacity: "0.5" } },
                                size: "small",
                                fullWidth: true,
                                placeholder: "dd/mm/yyyy",
                                required: true,
                                error:
                                    !!formik.errors.startDate &&
                                    !!formik.touched.startDate,
                                id: `${id}-start-date-input`,
                                name: "startDate",
                            },
                        }}
                        slots={{
                            // Override default <ActionBar /> with a custom one
                            openPickerIcon: CalendarTodayIcon,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel
                        error={
                            !!formik.errors.endDate && !!formik.touched.endDate
                        }
                        shrink
                        id={`${id}-end-date-label`}
                    >
                        {"End date (*)"}
                    </InputLabel>

                    <DatePicker
                        value={datePlaceholder(formik.values.endDate)}
                        onChange={value => {
                            if (canResetFlow) {
                                resetFlow(
                                    "end date",
                                    "endDate",
                                    convertDateForAPI(value),
                                    formik.values.endDate,
                                );
                            }
                            formik.setFieldTouched("endDate", true);
                            formik.setFieldValue(
                                "endDate",
                                convertDateForAPI(value),
                            );
                        }}
                        format="dd/MM/yyyy"
                        slotProps={{
                            textField: {
                                variant: "outlined",
                                autoComplete: "off",
                                sx: { svg: { color: "#036", opacity: "0.5" } },
                                size: "small",
                                fullWidth: true,
                                placeholder: "dd/mm/yyyy",
                                required: true,
                                error:
                                    !!formik.errors.endDate &&
                                    !!formik.touched.endDate,
                                id: `${id}-end-date-input`,
                                name: "endDate",
                            },
                        }}
                        minDate={datePlaceholder(formik.values.startDate, 1)}
                        slots={{
                            // Override default <ActionBar /> with a custom one
                            openPickerIcon: CalendarTodayIcon,
                        }}
                    />
                    {!!formik.errors.endDate &&
                        typeof formik.errors.endDate === "string" && (
                            <FormHelperText error>
                                {formik.errors.endDate}
                            </FormHelperText>
                        )}
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel
                        error={
                            !!formik.errors.therapeuticArea &&
                            !!formik.touched.therapeuticArea
                        }
                        id={`${id}-therapeutic-area-label`}
                        shrink
                    >
                        {"Therapeutic area (*)"}
                    </InputLabel>

                    <Select
                        id={`${id}-therapeutic-area-input`}
                        value={formik.values.therapeuticArea}
                        onChange={(_, index) => {
                            formik.setFieldValue(
                                "therapeuticArea",
                                therapeuticAreas?.data[index],
                            );
                        }}
                        disabled={!formik.values?.country?.isoCode}
                        menuItemLabel={"therapeuticAreaName"}
                        menuItemId="therapeuticAreaId"
                        error={
                            !!formik?.errors?.therapeuticArea &&
                            !!formik?.touched?.therapeuticArea
                        }
                        list={therapeuticAreas.data}
                        loading={therapeuticAreas.loading}
                        name="therapeuticArea"
                        onBlur={() =>
                            formik.setFieldTouched("therapeuticArea", true)
                        }
                    />
                </Grid>
                {isPerformanceBasedContract && (
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            error={
                                !!formik.errors.indication &&
                                !!formik.touched.indication
                            }
                            id={`${id}-indication-label`}
                            shrink
                        >
                            {"Indication"}
                        </InputLabel>

                        <Select
                            id={`${id}-indication-input`}
                            name="indication"
                            value={formik.values.indication}
                            onChange={(_, index) => {
                                formik.setFieldValue(
                                    "indication",
                                    indications?.data[index],
                                );
                            }}
                            menuItemLabel={"indicationName"}
                            menuItemId="indicationId"
                            error={
                                !!formik?.errors?.indication &&
                                !!formik?.touched?.indication
                            }
                            list={indications.data}
                            loading={indications.loading}
                            onBlur={() =>
                                formik.setFieldTouched("indication", true)
                            }
                        />
                    </Grid>
                )}
                {isPerformanceBasedContract && (
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            error={
                                !!formik.errors.brand && !!formik.touched.brand
                            }
                            shrink
                            id={`${id}-brand-label`}
                        >
                            {"Brand (*)"}
                        </InputLabel>

                        <Select
                            id={`${id}-brand-input`}
                            value={formik.values.brand}
                            onChange={(_, index) => {
                                if (canResetFlow) {
                                    resetFlow(
                                        "brand",
                                        "brand",
                                        brands?.data[index],
                                    );
                                    return;
                                }
                                formik.setFieldValue(
                                    "brand",
                                    brands?.data[index],
                                );
                            }}
                            menuItemLabel={"brandName"}
                            menuItemId="brandId"
                            name="brand"
                            error={
                                !!formik?.errors?.brand &&
                                !!formik?.touched?.brand
                            }
                            list={brands.data}
                            loading={brands.loading}
                            onBlur={() => formik.setFieldTouched("brand", true)}
                        />
                    </Grid>
                )}

                <Grid item xs={12} md={6}>
                    <InputLabel
                        id={`${id}-partner-label`}
                        error={
                            !!formik.errors.primaryPartner &&
                            !!formik.touched.primaryPartner
                        }
                        shrink
                    >
                        {"Primary contract partner (*)"}
                    </InputLabel>

                    <Autocomplete
                        id={`${id}-partner-input`}
                        data={partners.data}
                        loading={partners.loading}
                        name="primaryPartner"
                        fullWidth
                        onBlur={event => {
                            formik.handleBlur(event);
                        }}
                        value={formik.values.primaryPartner || undefined}
                        size="small"
                        disabled={!formik.values?.country?.isoCode}
                        keysToMatch={[
                            "accountName",
                            "accountCity",
                            "sapAccountCode",
                            "knowyoursupplierid",
                        ]}
                        onChange={value => {
                            // set blur when user click on (X) delete the current partner
                            if (!formik.touched["primaryPartner"]) {
                                formik.setTouched({
                                    ...formik.touched,
                                    primaryPartner: true,
                                });
                            }

                            formik.setFieldValue("primaryPartner", value);
                        }}
                        onSearch={(query: string) => {
                            partners.onSearch(query);
                        }}
                        variant="outlined"
                        error={
                            !!formik.errors.primaryPartner &&
                            !!formik.touched.primaryPartner
                        }
                    />
                </Grid>

                {isGermanyTeam && !isPerformanceBasedContract && (
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            error={
                                !!formik.errors.claimPeriodicity &&
                                !!formik.touched.claimPeriodicity
                            }
                            id={`${id}-claimPeriodicity-label`}
                            shrink
                        >
                            {"Claim periodicity (*)"}
                        </InputLabel>

                        <Select
                            id={`${id}-claimPeriodicity-area-input`}
                            value={formik.values.claimPeriodicity}
                            onChange={(_, index) => {
                                formik.setFieldValue(
                                    "claimPeriodicity",
                                    PERIODICITY[index],
                                );
                            }}
                            menuItemLabel={"label"}
                            menuItemId="id"
                            error={
                                !!formik?.errors?.claimPeriodicity &&
                                !!formik?.touched?.claimPeriodicity
                            }
                            list={PERIODICITY}
                            loading={false}
                            name="claimPeriodicity"
                            onBlur={() =>
                                formik.setFieldTouched("claimPeriodicity", true)
                            }
                        />
                    </Grid>
                )}
            </Grid>

            <Grid item xs={12} mt={6} mb={2}>
                <Typography variant="h3">
                    Additional contract information
                </Typography>
            </Grid>
            <Grid item xs={12} container rowSpacing={3} columnSpacing={4}>
                <Grid item xs={12} md={6}>
                    <InputLabel
                        id={`${id}-external-reference-label`}
                        error={
                            !!formik.errors.externalReference &&
                            !!formik.touched.externalReference
                        }
                        shrink
                    >
                        {"External reference"}
                    </InputLabel>

                    <TextField
                        id={`${id}-external-reference-input`}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        name="externalReference"
                        value={formik.values.externalReference}
                        onChange={formik.handleChange}
                        variant="outlined"
                        error={
                            !!formik.errors.externalReference &&
                            !!formik.touched.externalReference
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel
                        id={`${id}-responsible-label`}
                        error={
                            !!formik.errors.responsible &&
                            !!formik.touched.responsible
                        }
                        shrink
                    >
                        {"Contract responsible"}
                    </InputLabel>

                    <TextField
                        id={`${id}-responsible-input`}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        name="responsible"
                        value={formik.values.responsible}
                        onChange={formik.handleChange}
                        variant="outlined"
                        error={
                            !!formik.errors.responsible &&
                            !!formik.touched.responsible
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel
                        error={!!formik.errors.icdNo && !!formik.touched.icdNo}
                        shrink
                        id={`${id}-icd-number-label`}
                    >
                        {"ICD number"}
                    </InputLabel>

                    <TextField
                        id={`${id}-icd-number`}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        name="icdNo"
                        value={formik.values.icdNo}
                        onChange={formik.handleChange}
                        variant="outlined"
                        error={!!formik.errors.icdNo && !!formik.touched.icdNo}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel
                        error={
                            !!formik.errors.icdUrl && !!formik.touched.icdUrl
                        }
                        shrink
                        id={`${id}-icd-url-label`}
                    >
                        {"ICD url"}
                    </InputLabel>

                    <TextField
                        id={`${id}-icd-url`}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        name="icdUrl"
                        value={formik.values.icdUrl}
                        onChange={formik.handleChange}
                        variant="outlined"
                        error={
                            !!formik.errors.icdUrl && !!formik.touched.icdUrl
                        }
                    />
                    {!!formik.errors.icdUrl && !!formik.touched.icdUrl && (
                        <FormHelperText error>
                            {formik.errors.icdUrl}
                        </FormHelperText>
                    )}
                </Grid>
            </Grid>
        </form>
    );
};
export default React.memo(GeneralInformationForm);
