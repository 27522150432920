import React, { useEffect, useMemo, useState } from "react";

import { useRecoilState, useRecoilValue } from "recoil";

// Hooks
import {
    useUpdateStep,
    useBpfPaymentCondition,
    useSelectedCountry,
} from "@hooks";

// Atoms
import {
    BPFGeneralInformationState,
    BPFPaymentInformationState,
    selectContractsState,
} from "@atoms";

// Components
import { PaymentInformation as PaymentInformationComponent } from "@components";

/**
 * Props type
 */
interface Props {
    flowState?: string;
    location?: Location;
    isVolumeBased?: boolean;
    isApheresisBased?: boolean;
    isPerformanceBased?: boolean;
}

/**
 * Payment Information
 */
const BPFPaymentInformation = ({
    flowState,
    location,
    isVolumeBased,
    isApheresisBased,
    isPerformanceBased,
}: Props) => {
    const { load, loading } = useBpfPaymentCondition();
    const { isGermanyTeam } = useSelectedCountry();

    /**
     * Recoil
     */
    const selectedContracts = useRecoilValue(selectContractsState);

    const [dateErrors, setDateErrors] = useState({
        bpfProcessDate: false,
        paymentDueDate: false,
        bankType: false,
    });

    /**
     * BPF General Information State
     */
    const [values, updateValuesState] = useRecoilState(
        BPFPaymentInformationState,
    );

    /**
     * General Information State
     */
    const generalInformation = useRecoilValue(BPFGeneralInformationState);

    /**
     * Update step validation
     */
    const updateStepValidation = useUpdateStep(location, flowState);

    /**
     * Update recoil state
     */
    const onChange = (key: string | undefined, value: any) => {
        if (!key) {
            updateValuesState(prevValues => ({
                ...prevValues,
                ...value,
            }));
        } else
            updateValuesState(prevValues => ({
                ...prevValues,
                [key]: value,
            }));
    };

    useEffect(() => {
        if (
            !!values?.bankType ||
            !generalInformation?.contractPartner?.bankType
        )
            return;

        if (
            Number(generalInformation?.contractPartner?.bankType) > 10 ||
            Number(generalInformation?.contractPartner?.bankType) < 1
        ) {
            setDateErrors(prev => ({
                ...prev,
                bankType: true,
            }));
        }

        updateValuesState({
            ...values,
            bankType: generalInformation?.contractPartner?.bankType,
        });
    }, [generalInformation]);

    useEffect(() => {
        if (!isGermanyTeam || isPerformanceBased) return;

        if (
            !values?.informationForCoAccrual ||
            (isVolumeBased &&
                !values?.informationForCoAccrual?.startsWith("KK")) ||
            (isApheresisBased &&
                !values?.informationForCoAccrual?.startsWith("APHE"))
        ) {
            updateValuesState({
                ...values,
                ["informationForCoAccrual"]: isVolumeBased ? "KK" : "APHE",
            });
        }
    }, [isVolumeBased, isApheresisBased, values?.informationForCoAccrual]);

    const contractIds = useMemo(() => {
        return selectedContracts.map(contract => contract.id);
    }, [selectedContracts]);

    /**
     * Render
     */
    return (
        <PaymentInformationComponent
            bankType={values?.bankType}
            loadPaymentCondition={load}
            paymentCondition={values?.paymentCondition}
            loadingPaymentCondition={loading}
            handleChange={onChange}
            invoiceDate={values?.invoiceDate}
            paymentDueDate={values?.paymentDueDate}
            bpfProcessDate={values?.bpfProcessDate}
            invoice={values?.invoice}
            setDateErrors={setDateErrors}
            dateErrors={dateErrors}
            updateStepValidation={updateStepValidation}
            contractIds={contractIds}
            informationForCoAccrual={values?.informationForCoAccrual}
            isGermanyTeam={isGermanyTeam}
            isVolumeBased={isVolumeBased}
            isPerformanceBased={isPerformanceBased}
        />
    );
};

export default React.memo(BPFPaymentInformation);
