// icon
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// lib
import {
    Checkbox,
    CircularProgress,
    InputAdornment,
    MenuItem,
    Select as _Select,
} from "@mui/material";
import React, { useCallback } from "react";
import classNames from "classnames";

//utils
import { isArrayWithContent } from "@utils";

//import style
import * as style from "./style.module.scss";

interface Props {
    id: string;
    value: any;
    list: Array<any>;
    onChange: (event) => void;
    multipleSelectKey: string;
    loading?: boolean;
    name: string;
    size?: "small" | "medium";
    error?: boolean;
    menuItemLabel: string;
    disabled?: boolean;
    defaultOpen?: boolean;
    whiteBg?: boolean;
    displayEmpty?: boolean;
}

/**
 * MultiSelect with checkboxes
 */
const MultiSelect: React.FC<Props> = ({
    id,
    value,
    list,
    onChange,
    multipleSelectKey,
    loading = false,
    name,
    size = "small",
    error,
    menuItemLabel,
    disabled,
    defaultOpen,
    whiteBg,
    displayEmpty,
}) => {
    /**
     * Check if item is selected
     */
    const isItemChecked = useCallback(
        item => {
            if (!isArrayWithContent(list) && !isArrayWithContent(value)) return;

            return (
                value.findIndex(
                    (el: string) => el === item[multipleSelectKey],
                ) > -1
            );
        },
        [list, multipleSelectKey, value],
    );

    /**
     * label instead of value as renderValue
     */
    const renderMenuItemLabel = selected => {
        if (!isArrayWithContent(selected)) return selected;

        const labelList: any = [];
        if (isArrayWithContent(list)) {
            selected.map(selection => {
                const label = list.find(
                    obj => obj[multipleSelectKey] === selection,
                );

                label && labelList.push(label[menuItemLabel]);
            });
        }

        return labelList.join(", ");
    };

    /**
     * Render
     */
    return (
        <_Select
            fullWidth
            multiple
            name={name}
            id={`${id}-multi-select`}
            size={size}
            disabled={loading || disabled}
            value={value}
            onChange={onChange}
            defaultOpen={defaultOpen}
            displayEmpty={displayEmpty}
            variant="outlined"
            error={error}
            renderValue={renderMenuItemLabel}
            className={classNames({
                [style.disableField]: disabled,
                [style.whiteBg]: whiteBg,
            })}
            IconComponent={props =>
                loading ? (
                    <InputAdornment position="start">
                        <CircularProgress color="inherit" size={20} />
                    </InputAdornment>
                ) : (
                    <KeyboardArrowDownIcon {...props} color="primary" />
                )
            }
        >
            {!loading && isArrayWithContent(list) ? (
                list.map((item, index) => (
                    <MenuItem
                        id={`${id}-multi-select-item-${name}-${index}`}
                        value={JSON.stringify(item)}
                        key={`${item}-${name}-${index}`}
                    >
                        <Checkbox checked={isItemChecked(item)} />
                        {item[menuItemLabel]}
                    </MenuItem>
                ))
            ) : (
                <MenuItem disabled>No Data</MenuItem>
            )}
        </_Select>
    );
};
export default React.memo(MultiSelect);
