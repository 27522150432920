//Lib
import {
    Box,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
} from "@mui/material";

import React, { Fragment, useMemo } from "react";

// Own components
import { Dialog } from "@components";

/**
 * Props
 */
interface Props {
    openDialog: boolean;
    onConfirm: () => void;
    loading: boolean;
    onReset: () => void;
    className?: string;
    values: {
        approved: string;
        rejectionReason: string;
    };
    onBlur: (event: React.SyntheticEvent) => void;
    onChange: any;
    id: string;
    fileName: string;
    evidenceDataApprovalTask: boolean;
    lauerDataApprovalTask: boolean;
    iqviaDataApprovalTask: boolean;
    accrualAdjustmentDataApprovalTask: boolean;
    localAgreementDataApprovalTask: boolean;
    apheresisDataLoadApprovalTask: boolean;
    performanceIncentiveDataApprovalTask: boolean;
}

/**
 * price data approval task
 */
const LoadData = ({
    id,
    openDialog,
    loading,
    onReset,
    className,
    values,
    onBlur,
    onChange,
    onConfirm,
    fileName,
    evidenceDataApprovalTask,
    lauerDataApprovalTask,
    iqviaDataApprovalTask,
    accrualAdjustmentDataApprovalTask,
    localAgreementDataApprovalTask,
    apheresisDataLoadApprovalTask,
    performanceIncentiveDataApprovalTask,
}: Props) => {
    const title = useMemo(() => {
        if (evidenceDataApprovalTask) {
            return "Approve evidence data Load";
        }

        if (lauerDataApprovalTask) {
            return "Approve Lauer Taxe data Load";
        }

        if (iqviaDataApprovalTask) {
            return "Approve IQVIA data Load";
        }

        if (accrualAdjustmentDataApprovalTask) {
            return "Approve accrual adjustment data Load";
        }

        if (localAgreementDataApprovalTask) {
            return "Approve local agreement data Load";
        }

        if (apheresisDataLoadApprovalTask) {
            return "Approve apheresis data Load";
        }

        if (performanceIncentiveDataApprovalTask) {
            return "Approve performance incentive data Load";
        }
    }, [
        evidenceDataApprovalTask,
        lauerDataApprovalTask,
        iqviaDataApprovalTask,
        accrualAdjustmentDataApprovalTask,
        localAgreementDataApprovalTask,
        apheresisDataLoadApprovalTask,
        performanceIncentiveDataApprovalTask,
    ]);
    return (
        <Fragment>
            <Dialog
                id={`${id}-tasks-approval-load-data-approval-task`}
                title={title}
                open={openDialog}
                message={
                    <Fragment>
                        {values?.approved === "true" &&
                            localAgreementDataApprovalTask && (
                                <Typography variant="subtitle2" color="black">
                                    You are about to approve the local agreement
                                    data load file
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {` (${fileName})`}
                                    </Typography>
                                    . The data in this file will be added in
                                    SCP. Are you sure you want to continue?
                                </Typography>
                            )}
                        {values?.approved === "false" &&
                            localAgreementDataApprovalTask && (
                                <Typography variant="subtitle2" color="black">
                                    This will cancel the processing of the data
                                    in the uploaded local agreement data load
                                    file
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {` (${fileName})`}
                                    </Typography>
                                    . Are you sure you want to continue?
                                </Typography>
                            )}

                        {values?.approved === "true" &&
                            evidenceDataApprovalTask && (
                                <Typography variant="subtitle2" color="black">
                                    You are about to approve the evidence data
                                    file
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {` (${fileName})`}
                                    </Typography>
                                    . Are you sure you want to continue?
                                </Typography>
                            )}

                        {values?.approved === "false" &&
                            evidenceDataApprovalTask && (
                                <Typography variant="subtitle2" color="black">
                                    This will cancel the processing of the data
                                    in the uploaded evidence data file
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {` (${fileName})`}
                                    </Typography>
                                    . Are you sure you want to continue?
                                </Typography>
                            )}

                        {values?.approved === "true" &&
                            lauerDataApprovalTask && (
                                <Typography variant="subtitle2" color="black">
                                    You are about to approve the Lauer Taxe
                                    evidence data file
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {` (${fileName})`}
                                    </Typography>
                                    . The data in this file will be added in
                                    SCP. Are you sure you want to continue?
                                </Typography>
                            )}

                        {values?.approved === "false" &&
                            lauerDataApprovalTask && (
                                <Typography variant="subtitle2" color="black">
                                    This will cancel the processing of the data
                                    in the uploaded Lauer Taxe evidence data
                                    file
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {` (${fileName})`}
                                    </Typography>
                                    . Are you sure you want to continue?
                                </Typography>
                            )}
                        {values?.approved === "true" &&
                            iqviaDataApprovalTask && (
                                <Typography variant="subtitle2" color="black">
                                    You are about to approve the IQVIA evidence
                                    data file
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {` (${fileName})`}
                                    </Typography>
                                    . The data in this file will be added in
                                    SCP. Are you sure you want to continue?
                                </Typography>
                            )}

                        {values?.approved === "false" &&
                            iqviaDataApprovalTask && (
                                <Typography variant="subtitle2" color="black">
                                    This will cancel the processing of the data
                                    in the uploaded IQVIA evidence data file
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {` (${fileName})`}
                                    </Typography>
                                    . Are you sure you want to continue?
                                </Typography>
                            )}

                        {values?.approved === "true" &&
                            accrualAdjustmentDataApprovalTask && (
                                <Typography variant="subtitle2" color="black">
                                    You are about to approve the accrual
                                    adjustment evidence data file load
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {` (${fileName})`}
                                    </Typography>
                                    . The data in this file will be added in
                                    SCP. Are you sure you want to continue?
                                </Typography>
                            )}

                        {values?.approved === "false" &&
                            accrualAdjustmentDataApprovalTask && (
                                <Typography variant="subtitle2" color="black">
                                    This will cancel the processing of the data
                                    in the uploaded accrual adjustment evidence
                                    data file
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {` (${fileName})`}
                                    </Typography>
                                    . Are you sure you want to continue?
                                </Typography>
                            )}

                        {values?.approved === "true" &&
                            apheresisDataLoadApprovalTask && (
                                <Typography variant="subtitle2" color="black">
                                    You are about to approve the apheresis data
                                    load file
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {` (${fileName})`}
                                    </Typography>
                                    . The data in this file will be added in
                                    SCP. Are you sure you want to continue?
                                </Typography>
                            )}

                        {values?.approved === "false" &&
                            apheresisDataLoadApprovalTask && (
                                <Typography variant="subtitle2" color="black">
                                    This will cancel the processing of the data
                                    in the uploaded apheresis data file
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {` (${fileName})`}
                                    </Typography>
                                    . Are you sure you want to continue?
                                </Typography>
                            )}

                        {values?.approved === "true" &&
                            performanceIncentiveDataApprovalTask && (
                                <Typography variant="subtitle2" color="black">
                                    You are about to approve the performance
                                    incentive data load file
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {` (${fileName})`}
                                    </Typography>
                                    . The data in this file will be added in
                                    SCP. Are you sure you want to continue?
                                </Typography>
                            )}

                        {values?.approved === "false" &&
                            performanceIncentiveDataApprovalTask && (
                                <Typography variant="subtitle2" color="black">
                                    This will cancel the processing of the data
                                    in the uploaded performance incentive data
                                    file
                                    <Typography
                                        variant="body2"
                                        component="span"
                                    >
                                        {` (${fileName})`}
                                    </Typography>
                                    . Are you sure you want to continue?
                                </Typography>
                            )}
                    </Fragment>
                }
                primaryButton={{
                    text: "Confirm",
                    action: onConfirm,
                    loading: loading,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: onReset,
                }}
            />

            <Box mb={3} className={className}>
                <form noValidate onBlur={onBlur}>
                    <FormControl>
                        <RadioGroup
                            id={`${id}-load-data-approval`}
                            value={values?.approved}
                            onChange={onChange}
                            name="approved"
                        >
                            <FormControlLabel
                                value={"true"}
                                id={`${id}-task-details-approve-load-data`}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I approve this decision
                                    </Typography>
                                }
                            />

                            <FormControlLabel
                                id={`${id}-task-details-reject-load-data`}
                                value={"false"}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I reject this decision
                                    </Typography>
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </form>
            </Box>
        </Fragment>
    );
};

export default React.memo(LoadData);
