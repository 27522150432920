// Libs
import { Typography } from "@mui/material";
import React from "react";

// Own components
import { ContractReview } from "@components";

// Styles
import * as style from "../style.module.scss";
import { Contract } from "@types";

// Props types
interface Props {
    contract: Contract;
    hasPermissionToEditOutcome?: boolean;
    downloadEvidence: (contractId: string, evidenceId: string) => void;
    fetchingEvidences: boolean;
    downloadingEvidence: boolean;
    evidences: any;
}

const ContractReviewSection = ({
    contract,
    hasPermissionToEditOutcome,
    downloadEvidence,
    fetchingEvidences,
    downloadingEvidence,
    evidences,
}: Props) => {
    return (
        <div className={style.boxWrapper}>
            <Typography variant="h2" className={style.header}>
                Contract details
            </Typography>

            <ContractReview
                id={`task-details-contract-review`}
                contractType={contract?.type}
                model={contract?.datamodel}
                categories={contract?.categories}
                hideEdit={true}
                shrinkColumns
                generalInformation={{
                    reference: contract?.reference,
                    country: contract?.country,
                    startDate: contract?.startDate,
                    endDate: contract?.endDate,
                    therapeuticArea: contract?.therapeuticArea,
                    indication: contract?.indication,
                    brand: contract?.brand,
                    primaryContractPartner: contract?.primaryContractPartner,
                    externalReference: contract?.externalReference,
                    responsible: contract?.responsible,
                    icdNo: contract?.icdNo,
                    icdUrl: contract?.icdUrl,
                    claimPeriodicity: contract?.claimPeriodicity,
                }}
                productsDetails={contract?.products}
                financialConditions={contract?.products[0]?.financialConditions}
                contractMilestones={contract?.products[0]?.milestones}
                comments={contract?.comments || ""}
                additionalPartners={contract?.additionalPartners}
                status={contract?.status}
                evidences={{
                    downloadEvidence: hasPermissionToEditOutcome
                        ? (evidenceId: string) =>
                              downloadEvidence(contract?.id, evidenceId)
                        : undefined,
                    loading: fetchingEvidences || downloadingEvidence,
                    data: evidences?.data?.records,
                }}
                currency={contract?.datamodel?.currency}
            />
        </div>
    );
};

export default ContractReviewSection;
