import { useAxios } from "./useAxios";
import type { Api } from "@types";
import { isSuccessfulCall, startDownload } from "@utils";
import { useEffect } from "react";

const BASE_URI = `/v0`;

/**
 * Outcomes
 */
export const useOutcomes = (financialTriggerType?: string) => {
    const url = `${BASE_URI}/outcomes`;

    // check the outcome type
    const isFinancialConditions =
        !!financialTriggerType && financialTriggerType === "rebateAtInfusion";

    const {
        response: list,
        loading: listLoading,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${url}${financialTriggerType ? "?financialTriggerType=" + financialTriggerType : ""}`,
        },
        {
            errorHandler: `Failed to fetch ${
                isFinancialConditions ? "financial conditions" : "outcomes"
            }`,
        },
    );

    const {
        loading: canceling,
        fetch: cancel,
        error: cancelError,
    }: Api = useAxios(
        {
            method: "POST",
        },
        {
            errorHandler: `Failed to cancel ${
                isFinancialConditions ? "financial condition" : "outcome"
            }`,
        },
    );

    return {
        list: list,
        loading: { listLoading, canceling },
        error: { listError, cancelError },
        reload: () => loadList(),
        search: (searchParams: string) =>
            loadList({
                url: financialTriggerType
                    ? `${url}?financialTriggerType=${financialTriggerType}${searchParams}`
                    : `${url}${searchParams}`,
            }),
        cancel: (
            outcomeId: string,
            salessystemOrderId: string,
            reasonCancellation,
        ) =>
            cancel(
                {
                    url: `${url}/${outcomeId}/cancel`,
                    data: { reasonCancellation },
                },
                {
                    errorHandler: `Failed to request ${
                        isFinancialConditions
                            ? "financial condition"
                            : "outcome"
                    } cancellation`,
                    successHandler: `${
                        isFinancialConditions
                            ? "Financial condition"
                            : "Outcome"
                    } cancellation for ${salessystemOrderId} was successfully requested`,
                },
            ),
    };
};

/**
 * Outcome
 */
export const useOutcome = (path: string, type: string) => {
    const url = `${BASE_URI}/outcomes/${path}`;

    const {
        response: list,
        loading: listLoading,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to fetch ${type.split("|")[1]}` },
    );

    return {
        list: list,
        loading: listLoading,
        error: listError,
        reload: () => loadList(),
        search: (searchParams: string) =>
            loadList({
                url: `${url}${searchParams}`,
            }),
    };
};

/**
 * Fetch cotracts that match the order sap number
 */

export const useGetMatchedContractsWithOrder = () => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        load: (orderId: string) =>
            fetch(
                {
                    method: "GET",
                    url: `${BASE_URI}/outcomes/orders/${orderId}/contracts`,
                },
                { errorHandler: "Failed to fetch contracts" },
            ),
        error,
        loading,
        response,
    };
};

export const useCreateOutcome = () => {
    const {
        response: createOutcomeResponse,
        loading: createOutcomeLoading,
        error: createOutcomeError,
        fetch: createOutcome,
    }: Api = useAxios();

    const {
        loading: downloadingOutcomeAttachment,
        error: outcomeAttachmentLocationError,
        fetch: fetchOutcomeAttachmentLocation,
    }: Api = useAxios({
        method: "GET",
    });

    return {
        createOutcome: (data: any) =>
            createOutcome(
                {
                    method: "POST",
                    url: `${BASE_URI}/outcome`,
                    data,
                },
                { errorHandler: "Failed to create outcome" },
            ),
        error: {
            createOutcomeError,
            outcomeAttachmentLocationError,
        },
        loading: {
            createOutcomeLoading,
            downloadingOutcomeAttachment,
        },
        response: createOutcomeResponse,
        downloadOutcomeAttachment: (
            outcomeAttachmentId: string,
            fileName: string,
        ) =>
            fetchOutcomeAttachmentLocation(
                {
                    url: `${BASE_URI}/outcome/attachment/${outcomeAttachmentId}/download`,
                },
                {
                    errorHandler: `Failed to fetch ${fileName}`,
                },
            ).then(res => {
                if (isSuccessfulCall(res?.status) && !!res?.data?.location) {
                    startDownload(res.data.location);
                    return res;
                }
                return res;
            }),
    };
};

export const useGetVariables = () => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        getVariables: (searchParams: string) =>
            fetch(
                {
                    method: "GET",
                    url: `${BASE_URI}/outcome/variables${searchParams}`,
                },
                { errorHandler: "Failed to fetch variables" },
            ),
        error,
        loading,
        response,
    };
};

/**
 *
 * Fetch orders
 */
export const useOutcomeOrders = (path: string, type: string) => {
    const url = `${BASE_URI}/${path}/`;

    const {
        response: list,
        loading: listLoading,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to fetch ${type.split("|")[1]}` },
    );

    return {
        list: list,
        loading: listLoading,
        error: listError,
        reload: (contractId: string) =>
            loadList({
                url: `${url}${contractId}/orders`,
            }),
        search: (contractId: string) =>
            loadList({
                url: `${url}${contractId}/orders`,
            }),
    };
};

export const useOutcomeStatuses = () => {
    const {
        response: list,
        loading: listLoading,
        fetch: load,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/outcomes/statuses`,
        },
        {
            errorHandler: "Failed to fetch outcome statuses",
        },
    );

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            load();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    return {
        loading: listLoading,
        error: listError,
        list: list,
    };
};
