// Libs
import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";

// Own component
import { Card, FieldRenderer } from "@components";

// Utils
import { constantMapper, displayDate, isArrayWithContent } from "@utils";

/**
 * Props type
 */
interface Props {
    id: string;
    bpfId: string;
    bpfName: string;
    dateProcessed: string;
    contractRef: string;
    customerRef: string;
    primaryContractPartner: string;
    status: string;
    invoiceDate: string;
    paymentDueDate: string;
    bpfProcessDate: string;
    file: string;
    additionalPartners: any[];
    outcomes: any[];
    claims: any[];
    evidence: any;
    downloadingEvidence: boolean;
    downloadEvidence: (bpfId: string, fileName: string, type: string) => void;
    hideEmpty?: boolean;
    className?: string;
    title?: string;
    invoice: {
        evidenceId: string;
        fileName: string;
        type: string;
    };
    bankType?: string;
    informationForCoAccrual?: string;
}

/**
 * BPF Details
 */
const BpfDetails = ({
    bpfId,
    bpfName,
    dateProcessed,
    contractRef,
    customerRef,
    primaryContractPartner,
    status,
    invoiceDate,
    paymentDueDate,
    bpfProcessDate,
    invoice,
    bankType,
    additionalPartners,
    outcomes,
    claims,
    evidence,
    downloadingEvidence,
    downloadEvidence,
    id,
    title = "BPF Details",
    className,
    informationForCoAccrual,
}: Props) => {
    /**
     * Render
     */
    return (
        <Fragment>
            <Card id={`${id}-card`} className={className} title={title}>
                <Grid item xs={12} container rowSpacing={3} columnSpacing={4}>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-bpfName`}
                            label="BPF Name"
                            value={bpfName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-dateProcessed`}
                            label="Date Processed"
                            value={displayDate(dateProcessed)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-contractRef`}
                            label="Contract reference"
                            value={contractRef}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-customerRef`}
                            label="Customer reference"
                            value={customerRef}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-primaryContractPartner`}
                            label="Contract partner"
                            value={primaryContractPartner}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-status`}
                            label="Status"
                            value={constantMapper(status)}
                        />
                    </Grid>
                    {evidence && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-evidence-fileName`}
                                value={evidence?.fileName}
                                label="Evidence"
                                loading={downloadingEvidence}
                                hasDownloadAction
                                onDownloadClick={() =>
                                    downloadEvidence(
                                        bpfId,
                                        evidence?.fileName,
                                        evidence?.type,
                                    )
                                }
                            />
                        </Grid>
                    )}
                </Grid>
            </Card>

            <Card id={`${id}-additional-partners`} title="Payment information">
                <Grid item xs={12} container rowSpacing={3} columnSpacing={4}>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-bpfName`}
                            label="Invoice date"
                            value={invoiceDate}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-bpfName`}
                            label="Payment due date"
                            value={paymentDueDate}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-bpfProcessDate`}
                            label="BPF Process Date"
                            value={bpfProcessDate}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-bankType`}
                            label="Bank type"
                            value={bankType}
                        />
                    </Grid>
                    {!!invoice && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-original-invoice`}
                                label="Original invoice"
                                value={invoice?.fileName}
                                hasDownloadAction
                                onDownloadClick={() =>
                                    downloadEvidence(
                                        bpfId,
                                        invoice?.fileName,
                                        invoice?.type,
                                    )
                                }
                                loading={downloadingEvidence}
                            />
                        </Grid>
                    )}
                    {!!informationForCoAccrual && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-information-for-co-accrual`}
                                label="Information for CO/Accrual"
                                value={informationForCoAccrual}
                            />
                        </Grid>
                    )}
                </Grid>
            </Card>

            <Card id={`${id}-additional-partners`} title="Additional partners">
                {!!additionalPartners &&
                !!isArrayWithContent(additionalPartners) ? (
                    <Grid
                        item
                        xs={12}
                        container
                        rowSpacing={3}
                        columnSpacing={4}
                    >
                        {additionalPartners?.map((partner, index) => (
                            <Grid item xs={12} key={index} md={6}>
                                <FieldRenderer
                                    id={`${id}-${index}`}
                                    label=""
                                    value={partner?.name}
                                />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Box display="flex" justifyContent="center">
                        <Typography variant="caption1">
                            No additional partners found!
                        </Typography>
                    </Box>
                )}
            </Card>
            {isArrayWithContent(outcomes) && (
                <Fragment>
                    {outcomes?.map(outcome => (
                        <Card
                            id={`${id}-additional-partners`}
                            title={outcome?.salessystemOrderId}
                            key={outcome?.outcomeId}
                        >
                            <Grid
                                item
                                xs={12}
                                container
                                rowSpacing={3}
                                columnSpacing={4}
                            >
                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-outcome-amount`}
                                        label="Amount"
                                        value={outcome?.amount}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-outcome-measure`}
                                        label="Measure"
                                        value={outcome?.measure}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-outcome-percentage`}
                                        label="Percentage"
                                        value={
                                            outcome?.percentage
                                                ? `${outcome?.percentage}%`
                                                : undefined
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-outcome-salessystemOrderId`}
                                        label="Cquence Id"
                                        value={outcome?.salessystemOrderId}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-outcome-sapOrderNo`}
                                        label="SAP order no"
                                        value={outcome?.sapOrderNo}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-outcome-status`}
                                        label="Status"
                                        value={constantMapper(outcome?.status)}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-outcome-vatAmount`}
                                        label="VAT amount"
                                        value={outcome?.vatAmount}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-outcome-vatPercentage`}
                                        label="VAT percentage"
                                        value={outcome?.vatPercentage}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    ))}
                </Fragment>
            )}

            {isArrayWithContent(claims) && (
                <Fragment>
                    {claims?.map(claim => (
                        <Card
                            id={`${id}-additional-partners`}
                            key={claim.claimHeaderId}
                            title={`Claim details (${claim?.filename})`}
                        >
                            <Grid
                                item
                                xs={12}
                                container
                                rowSpacing={3}
                                columnSpacing={4}
                            >
                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-claim-supplier`}
                                        label="Supplier"
                                        value={claim?.supplier}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-claim-status`}
                                        label="Status"
                                        value={constantMapper(claim?.status)}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-claim-dateFrom`}
                                        label="Date from"
                                        value={displayDate(claim?.dateFrom)}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-claim-date-to`}
                                        label="Date to"
                                        value={displayDate(claim?.dateTo)}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-claim-file-name`}
                                        label="File name"
                                        value={claim?.filename}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-claim-file-date`}
                                        label="File date"
                                        value={displayDate(claim?.filedate)}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-claim-date-created`}
                                        label="Date created"
                                        value={displayDate(claim?.createdAt)}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-claim-total-units`}
                                        label="Units"
                                        value={claim?.totalUnits}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-claim-total-value`}
                                        label="Sales"
                                        value={
                                            claim?.priceCorrectionSum
                                                ? claim?.priceCorrectionSum
                                                : claim?.totalValue
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-claim-total-rebate`}
                                        label="Rebate"
                                        value={
                                            claim?.priceCorrectionRebateSum
                                                ? claim?.priceCorrectionRebateSum
                                                : claim?.totalRebateValue
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-claim-vatIncluded`}
                                        label="VAT included"
                                        value={
                                            claim?.vatIncluded ? "Yes" : " No"
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-claim-vatPercentage`}
                                        label="VAT percentage"
                                        value={
                                            claim.vatIncluded
                                                ? claim.vatPercentage
                                                    ? `${claim.vatPercentage}%`
                                                    : ""
                                                : "NA"
                                        }
                                    />
                                </Grid>

                                {claim?.priceCorrectionStatus && (
                                    <Grid item xs={12} md={6}>
                                        <FieldRenderer
                                            id={`${id}-claim-price-correction-status`}
                                            label="Price correction status"
                                            value={constantMapper(
                                                claim.priceCorrectionStatus,
                                            )}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Card>
                    ))}
                </Fragment>
            )}
        </Fragment>
    );
};

export default BpfDetails;
