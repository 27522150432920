import { useLayoutEffect, useRef } from "react";
import { useAxios } from "./useAxios";
import type { Api } from "@types";
import { useRecoilValue } from "recoil";
import { paginationState } from "@atoms";

const BASE_URI = `/v0`;

export const usePartners = (path: string, type: string, getList = true) => {
    const pagination = useRecoilValue<any>(paginationState);
    const currentUrlRef = useRef("");

    currentUrlRef.current = `${BASE_URI}/${path}?page=${pagination.page}`;

    const listAxios: Api = useAxios(
        { method: "GET" },
        { errorHandler: `Failed to fetch ${type.split("|")[1]}` },
    );

    const createAxios: Api = useAxios(
        { method: "POST" },
        { errorHandler: `Failed to create ${type.split("|")[0]}` },
    );

    const updateAxios: Api = useAxios(
        { method: "PUT" },
        { errorHandler: `Failed to update ${type.split("|")[0]}` },
    );

    useLayoutEffect(() => {
        if (!getList) return;
        listAxios.fetch?.({ url: currentUrlRef.current });
    }, []);

    const load = () => {
        if (!listAxios.fetch)
            return Promise.reject("Fetch method not available");
        return listAxios.fetch({
            url: currentUrlRef.current,
        });
    };

    const search = (searchParams: string) => {
        if (!listAxios.fetch)
            return Promise.reject("Fetch method not available");
        return listAxios.fetch({
            url: `${currentUrlRef.current}${searchParams}`,
        });
    };

    return {
        list: listAxios.response,
        loading: {
            creating: createAxios.loading,
            fetching: listAxios.loading,
            updating: updateAxios.loading,
        },
        error: {
            list: listAxios.error,
            create: createAxios.error,
            update: updateAxios.error,
        },
        response: createAxios.response,
        create: listItem => {
            if (!createAxios.fetch)
                return Promise.reject("Create method not available");
            return createAxios.fetch({
                url: `${BASE_URI}/${path}`,
                data: listItem,
            });
        },
        update: savedItem => {
            if (!updateAxios.fetch)
                return Promise.reject("Update method not available");
            return updateAxios.fetch(
                {
                    url: `${BASE_URI}/${path}`,
                    data: savedItem,
                },
                {
                    successHandler: `${savedItem.accountName} was successfully updated`,
                },
            );
        },
        search,
        load,
        reload: () => {
            listAxios.fetch?.({ url: `${BASE_URI}/${path}` });
        },
    };
};
