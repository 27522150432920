// Libs
import { Grid } from "@mui/material";
import React from "react";

// Own component
import { Card, FieldRenderer } from "@components";

// Utils
import { constantMapper, displayDate } from "@utils";
import { useSelectedCountry } from "@hooks";

/**
 * Props type
 */
interface Props {
    id: string;
    treatmentSite: string;
    infusionDate: string;
    salessystemOrderId: string;
    contract?: any;
    orderDate: string;
    orderStatus: string;
    hideEmpty?: boolean;
    className?: string;
    poReceived?: boolean;
    soldToName?: string;
    payerName?: string;
}

/**
 * Order Details
 */
const OrderDetails = ({
    treatmentSite,
    infusionDate,
    salessystemOrderId,
    contract,
    orderDate,
    orderStatus,
    id,
    hideEmpty = false,
    className,
    poReceived,
    soldToName,
    payerName,
}: Props) => {
    const { isSpainTeam } = useSelectedCountry();

    /**
     * Render
     */
    return (
        <Card id={`${id}-card`} className={className}>
            <Grid item xs={12} container rowSpacing={3} columnSpacing={4}>
                {(!hideEmpty || treatmentSite) && (
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-shipTo`}
                            label="Ship To"
                            value={treatmentSite}
                        />
                    </Grid>
                )}
                {(!hideEmpty || infusionDate) && (
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-infusionDate`}
                            label="Infusion date"
                            value={displayDate(infusionDate)}
                        />
                    </Grid>
                )}
                {(!hideEmpty || salessystemOrderId) && (
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-salessystemOrderId`}
                            label="Cquence Id"
                            value={salessystemOrderId}
                        />
                    </Grid>
                )}
                {!hideEmpty && (
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-reference`}
                            label="Contract reference"
                            value={contract?.reference}
                        />
                    </Grid>
                )}
                {(!hideEmpty || contract?.brandName) && (
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-brandName`}
                            label="Brand"
                            value={contract?.brandName}
                        />
                    </Grid>
                )}
                {(!hideEmpty || orderDate) && (
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-orderDate`}
                            label="Order date"
                            value={displayDate(orderDate)}
                        />
                    </Grid>
                )}
                {(!hideEmpty || orderStatus) && (
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-orderStatus`}
                            label="Status"
                            value={constantMapper(orderStatus)}
                        />
                    </Grid>
                )}
                {(!hideEmpty || poReceived) && isSpainTeam && (
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-poReceived`}
                            label="PO Received"
                            value={poReceived ? "Yes" : "No"}
                        />
                    </Grid>
                )}
                {!!isSpainTeam && (!hideEmpty || soldToName) && (
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-soldToName`}
                            label="Sold to"
                            value={soldToName}
                            showTooltip
                        />
                    </Grid>
                )}
                {!!isSpainTeam && (!hideEmpty || payerName) && (
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-payerName`}
                            label="Payer"
                            value={payerName}
                            showTooltip
                        />
                    </Grid>
                )}
            </Grid>
        </Card>
    );
};

export default OrderDetails;
