// Icons
import GridOnIcon from "@mui/icons-material/GridOn";
// Libs
import { navigate } from "gatsby";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";

// Own components
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    LoadingWrapper,
    FlowLayout,
    FlowAside,
    FlowBody,
    FlowContent,
    FlowFooter,
} from "@components";

// Hooks
import {
    useFindStep,
    usePartners,
    useApiMapper,
    useSelectedCountry,
} from "@hooks";

//Atoms
import { createPartnerStepsState } from "@atoms";

//Types
import type { Location } from "@types";

// Containers
import { PartnerOverview } from "@containers";

// Utils
import { isSuccessfulCall } from "@utils";

/**
 * Props type
 */
interface Props {
    location: Location;
    children?: React.ReactNode;
    partnerId?: string;
}

/**
 * Create partner layout
 */
const CreatePartnerLayout = ({ location, children, partnerId }: Props) => {
    /**
     * find selected team country iso code
     */
    const { countryIsoCode: teamCountryIsoCode, isUkTeam } =
        useSelectedCountry();

    const [accountId, setAccountId] = useState<string>("");
    //Via this ref we can save the state of each page via stepper (higher order)
    const ref = useRef<any>();

    /**
     * Api Mapper
     */
    const { mapCreatePartner } = useApiMapper();

    /**
     * Check if the current page is edit page
     */
    const isEditMode = useMemo(() => {
        return location?.pathname.includes("edit-partner") && !!partnerId;
    }, [location, partnerId]);

    /**
     * API
     */

    // Update / create current partner
    const {
        list: partner,
        create: createPartner,
        update: updatePartner,
        loading: { fetching, updating, creating },
    } = usePartners(
        !!partnerId && isEditMode ? `partner/${partnerId}` : "partner",
        "partner|partners",
        !!partnerId && isEditMode,
    );

    //Steps hooks
    const { currentStep } = useFindStep(location, createPartnerStepsState);

    /**
     * Check if finished
     */
    const isFinished = useMemo(() => {
        return !!accountId;
    }, [accountId]);

    useEffect(() => {
        if (isEditMode && !partnerId) {
            navigate("/dashboard/partners/");
        }
    }, [partnerId]);

    /**
     * Next Click Handler
     */
    const onNextClick = () => {
        if (isFinished) {
            navigate(`/dashboard/partners/`);
            return;
        }
        const values = ref?.current?.updateState();
        const data = mapCreatePartner(values, isUkTeam);

        if (isEditMode) {
            updatePartner({
                ...data,
                accountId: partnerId,
            }).then(res => {
                if (!!res && isSuccessfulCall(res?.status)) {
                    navigate(`/dashboard/partners/`);
                }
            });
        } else {
            createPartner(data).then(res => {
                if (!!res && isSuccessfulCall(res?.status)) {
                    setAccountId(res?.data?.accountId);
                }
            });
        }
    };

    const onEditOverview = () => {
        navigate(`/partner/edit-partner/${accountId}/`);
    };

    const onDiscardClick = () => {
        navigate(`/dashboard/partners/`);
    };

    /**
     * Render
     */
    return (
        <Fragment>
            <Breadcrumbs
                id={`create-partner-breadcrumbs`}
                icon={<GridOnIcon color="primary" />}
                title="Dashboard"
                location={location}
                subPage={
                    isEditMode
                        ? "Edit partner"
                        : isFinished
                          ? "Partner overview"
                          : "Create partner"
                }
            />

            <FlowLayout>
                <FlowAside>
                    <Stepper
                        id={`create-partner-stepper`}
                        title={currentStep?.title}
                    />
                </FlowAside>

                <FlowBody>
                    <LoadingWrapper
                        id={`create-partner-create-new-partner-loading`}
                        loading={fetching}
                        fullHeight
                    >
                        <Fragment>
                            <FlowContent>
                                {!!isFinished && (
                                    <PartnerOverview
                                        id={"create-partner-overview"}
                                        partnerId={accountId}
                                        canAddRegionAndCods={isUkTeam}
                                    />
                                )}

                                {!isFinished &&
                                    (!isEditMode ||
                                        (isEditMode && !!partner)) &&
                                    React.Children.map(
                                        children as React.ReactElement,
                                        (child: React.ReactElement) =>
                                            React.cloneElement(child, {
                                                location,
                                                isEditMode,
                                                disabled: updating || creating,
                                                ref,
                                                id: `create-partner`,
                                                partner,
                                                teamCountryIsoCode,
                                                canAddRegionAndCods: isUkTeam,
                                            }),
                                    )}
                            </FlowContent>

                            <FlowFooter>
                                <StepActions
                                    id={`create-partner-step-actions`}
                                    loading={updating || creating}
                                    primaryButton={{
                                        text: isFinished
                                            ? "Return to overview"
                                            : isEditMode
                                              ? "Save changes"
                                              : "Create account",

                                        action: onNextClick,
                                        disabled:
                                            !currentStep?.isPrepared ||
                                            updating ||
                                            creating,
                                        isSubmitButton: true,
                                    }}
                                    tertiaryButton={{
                                        text: isFinished
                                            ? "Edit partner"
                                            : "Discard changes",
                                        action: isFinished
                                            ? onEditOverview
                                            : onDiscardClick,
                                        hidden:
                                            (!isEditMode && !isFinished) ||
                                            !!accountId,
                                    }}
                                />
                            </FlowFooter>
                        </Fragment>
                    </LoadingWrapper>
                </FlowBody>
            </FlowLayout>
        </Fragment>
    );
};

export default CreatePartnerLayout;
