// lib
import { Button, ButtonGroup, Typography } from "@mui/material";
import React from "react";

// styles
import * as style from "./style.module.scss";

interface Option {
    label: string;
    value: string;
    filterKey?: string;
    path?: string;
}

interface TaskTogglerProps {
    value: { filterKey?: string; label: string; value: string };
    onChange: (option: Option) => void;
    id: string;
    options: Option[];
}

const TaskToggler: React.FC<TaskTogglerProps> = ({
    value,
    onChange,
    id,
    options,
}) => {
    return (
        <div className={style.wrapper}>
            <ButtonGroup size="medium">
                {options.map(option => (
                    <Button
                        key={`${id}-${option.label}`}
                        id={`${id}-${option.label}`}
                        variant={
                            value?.value === option.value
                                ? "contained"
                                : "outlined"
                        }
                        onClick={() => onChange(option)}
                        className={
                            value?.value === option.value
                                ? style.selected
                                : undefined
                        }
                    >
                        <Typography
                            variant={
                                value?.value === option.value
                                    ? "body2Dark"
                                    : "caption2"
                            }
                        >
                            {option.label}
                        </Typography>
                    </Button>
                ))}
            </ButtonGroup>
        </div>
    );
};

export default TaskToggler;
