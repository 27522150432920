//libs
import GridOn from "@mui/icons-material/GridOn";
import { Box } from "@mui/material";
import React, { useMemo, useState } from "react";

//own components
import { Breadcrumbs } from "@components";
//hooks
import { useLandingPage } from "@hooks";
//types
import type { Location } from "@types";
import { breadcrumbsMapper } from "@utils";

/**
 * Props type
 */
interface Props {
    children?: React.ReactNode;
    location: Location;
}

interface ContractsRef {
    getRecordsCount: () => { itemCount: number | undefined };
}

// Add this interface near the top with other interfaces
interface ChildComponentProps {
    ref: React.RefObject<ContractsRef>;
    onDataReady: () => void;
}

/**
 * Dashboard layout
 */
const DashboardLayout = ({ children, location }: Props) => {
    const childRef = React.useRef<ContractsRef>(null);
    const [itemCount, setItemCount] = useState<number | undefined>(undefined);

    const handleDataReady = () => {
        if (childRef.current) {
            const data = childRef.current.getRecordsCount();
            setItemCount(data.itemCount);
        }
    };

    /**
     * Permissions
     */
    const {
        areContractsVisible,
        areFinancesVisible,
        areOrdersVisible,
        areOutcomesVisible,
        areClaimsVisible,
    } = useLandingPage();

    /**
     * Tabs
     */
    const tabs = useMemo(() => {
        return [
            {
                title: "Claims",
                path: "/dashboard/claims/",
                disabled: !areClaimsVisible,
                subpages: [
                    {
                        title: "Price correction claims",
                        path: "/dashboard/price-correction-claims/",
                    },
                ],
            },
            {
                title: "Contracts",
                path: "/dashboard/contracts/",
                disabled: !areContractsVisible,
            },
            {
                title: "Financial conditions",
                path: "/dashboard/financial-conditions/rebate-at-infusion/",
                disabled: !areOutcomesVisible,
            },
            {
                title: "Financial conditions",
                path: "/dashboard/financial-conditions/rebate-at-apheresis/",
                disabled: !areOutcomesVisible,
            },
            {
                title: "Batch payment file",
                path: "/dashboard/finances/bpf/",
                disabled: !areFinancesVisible,
                subpages: [
                    {
                        title: "Credit notes",
                        path: "/dashboard/finances/credit-notes",
                    },
                    {
                        title: "Credit note outcome",
                        path: "/dashboard/finances/credit-note-outcome/",
                    },
                    {
                        title: "Credit note claim",
                        path: "/dashboard/finances/credit-note-claim/",
                    },
                    {
                        title: "Invoice release",
                        path: "/dashboard/finances/invoice-release",
                    },
                ],
            },
            {
                title: "Orders",
                path: "/dashboard/orders/",
                disabled: !areOrdersVisible,
            },
            {
                title: "Outcomes",
                path: "/dashboard/outcomes/",
                disabled: !areOutcomesVisible,
            },
            {
                title: "Products",
                path: "/dashboard/products/",
            },
            {
                title: "Partners",
                path: "/dashboard/partners/",
            },
            {
                title: "Regions",
                path: "/dashboard/regions/",
            },
        ];
    }, [
        areContractsVisible,
        areFinancesVisible,
        areOrdersVisible,
        areOutcomesVisible,
        areClaimsVisible,
    ]);

    const findSubpage = useMemo(() => {
        return breadcrumbsMapper(tabs, location);
    }, [location.pathname, tabs]);

    // Update the childWithRef logic
    const childWithRef = React.useMemo(() => {
        return React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(
                    child as React.ReactElement<ChildComponentProps>,
                    {
                        ref: childRef,
                        onDataReady: handleDataReady,
                    },
                );
            }
            return child;
        });
    }, [children]);

    return (
        <div>
            <Breadcrumbs
                id={`dashboard-breadcrumb`}
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
                itemCount={itemCount}
                subPage={findSubpage?.title}
            />

            <Box px={5}>{childWithRef}</Box>
        </div>
    );
};
export default DashboardLayout;
