// Icons
import { Box, Button, Divider, InputLabel, Typography } from "@mui/material";
//Lib

import { isArrayWithContent } from "@utils";
import classnames from "classnames";
import clonedeep from "lodash.clonedeep";
import React, { useCallback, useEffect, useState } from "react";

// import style
import * as style from "../style.module.scss";

// Own components
import SelectFilter from "../SelectFilter";
import OneItemFilter from "../OneItemFilter";

import { Autocomplete, DataRange, DateFilter } from "@components";
import { Account } from "@types";

/**
 * Props type
 */
interface FiltersProps {
    id: string;
    setFilters: (filters: any) => void;
    searchCallback?: () => void;
    setShowFilters: (showFilters: boolean) => void;
    dateRange?: boolean;
    startDate?: boolean;
    endDate?: boolean;
    products?: { loading: boolean; data: Array<any> };
    countries?: { loading: boolean; data: Array<any> };

    taskDefinitionKeys?: {
        loading: boolean;
        data: Array<string>;
    };
    disableSelectionFilters?: boolean;
    accountTypes?: { loading: boolean; data: Array<any> };
    contractType?: boolean;
    filters?: any;
    status?: { loading: boolean; data: Array<any> };
    financeType?: { loading: boolean; data: Array<any> };
    brands?: { loading: boolean; data: Array<any> };
    therapeuticAreas?: { loading: boolean; data: Array<any> };
    partners?: {
        loading: boolean;
        data: Array<Account>;
        onSearch: (query: string) => void;
    };
    priceRange?: boolean;
    selectedCount?: (filterSource: Record<string, any>, key: string) => number;
    hasSelectedFilters?: (filterName: string, isString?: boolean) => boolean;
    isFiltersOverview?: boolean;
    initialFilters?: Record<string, any>;
}

/**
 * Filters
 */
const Filters: React.FC<FiltersProps> = ({
    dateRange,
    startDate,
    endDate,
    products,
    id,
    countries,
    disableSelectionFilters,
    taskDefinitionKeys,
    accountTypes,
    contractType,
    filters,
    status,
    financeType,
    brands,
    therapeuticAreas,
    partners,
    priceRange,
    selectedCount,
    hasSelectedFilters,
    isFiltersOverview,
    setFilters,
    searchCallback,
    setShowFilters,
    initialFilters,
}) => {
    /**
     * State
     */
    const [selectedFilters, setSelectedFilters] = useState<any>(filters);

    useEffect(() => {
        setSelectedFilters(filters);
    }, [filters]);

    const filterValueFinder = useCallback(
        (filter: string) => {
            if (isFiltersOverview) {
                return filters[filter];
            }

            return selectedFilters[filter];
        },
        [isFiltersOverview, selectedFilters, filters],
    );

    /**
     * Check if item is selected
     */
    const isItemChecked = useCallback(
        (filter: string, value: Record<string, string>, key: string) => {
            if (!filters[filter] && !selectedFilters[filter]) return;
            if (isFiltersOverview) {
                return typeof filters[filter] === "string"
                    ? filters[filter] === value[key]
                    : filters[filter].find(item => item[key] === value[key]);
            }

            return typeof selectedFilters[filter] === "string"
                ? selectedFilters[filter] === value[key]
                : selectedFilters[filter].find(
                      item => item[key] === value[key],
                  );
        },
        [filters, isFiltersOverview, selectedFilters],
    );

    /**
     * Change brands, therapeuticAreas and products
     */
    const handleFilterChange = (
        changeOriginalFilters: boolean = false,
        filter: string | Array<string>,
        value: Array<any> | string | Record<string, string>,
        key?: string,
        oneItemSelection = false,
    ) => {
        const filterValue = isArrayWithContent(value) ? value[0] : value;

        const copyFilters = changeOriginalFilters
            ? clonedeep(filters)
            : clonedeep(selectedFilters);

        if (typeof filter === "string" && copyFilters[filter] && key) {
            // Array with radio button
            if (oneItemSelection && typeof value === "object") {
                copyFilters[filter] = [{ ...value, filterKey: key }];
            } else {
                const idx = copyFilters[filter].findIndex(
                    item => item[key] === filterValue[key],
                );

                idx === -1
                    ? copyFilters[filter].push({
                          ...filterValue,
                          filterKey: key,
                      })
                    : copyFilters[filter].splice(idx, 1);
            }
        } else if (typeof filter !== "string" && isArrayWithContent(filter)) {
            filter.forEach(item => {
                copyFilters[item] = value[item];
            });
        } else {
            copyFilters[filter as string] = key ? [filterValue] : filterValue;
        }
        if (searchCallback) {
            searchCallback();
        }
        changeOriginalFilters
            ? setFilters(copyFilters)
            : setSelectedFilters(copyFilters);
    };

    return (
        <div
            className={classnames(style.filtersWrapper, {
                [style.filtersContainerSelected]: !!hasSelectedFilters,
            })}
        >
            {!hasSelectedFilters && (
                <>
                    <Typography variant="h4">Filters</Typography>
                    <Divider
                        variant="fullWidth"
                        className={style.divider}
                        style={{ marginBottom: "3rem" }}
                    />
                </>
            )}
            {(!hasSelectedFilters
                ? startDate && endDate
                : hasSelectedFilters(
                      dateRange ? "startDate" : "valid_from",
                      true,
                  ) ||
                  hasSelectedFilters(
                      dateRange ? "endDate" : "valid_to",
                      true,
                  )) && (
                <div className={style.filterItem}>
                    {!hasSelectedFilters &&
                        ((startDate && endDate) || dateRange) && (
                            <div className={style.resetOneFilterWrapper}>
                                <InputLabel
                                    id={`${id}-select-product-label`}
                                    shrink
                                >
                                    {"Date range"}
                                </InputLabel>
                                {!isFiltersOverview && (
                                    <Button
                                        variant="text"
                                        size="small"
                                        onClick={() => {
                                            // Reset both date filters at once
                                            const copyFilters =
                                                clonedeep(selectedFilters);
                                            copyFilters[
                                                dateRange
                                                    ? "startDate"
                                                    : "valid_from"
                                            ] = "";
                                            copyFilters[
                                                dateRange
                                                    ? "endDate"
                                                    : "valid_to"
                                            ] = "";
                                            setSelectedFilters(copyFilters);
                                        }}
                                    >
                                        Reset
                                    </Button>
                                )}
                            </div>
                        )}
                    <div
                        className={classnames({
                            [style.range]: true,
                        })}
                    >
                        <div>
                            {(!hasSelectedFilters ||
                                hasSelectedFilters(
                                    dateRange ? "startDate" : "valid_from",
                                    true,
                                )) && (
                                <DateFilter
                                    value={filterValueFinder(
                                        dateRange ? "startDate" : "valid_from",
                                    )}
                                    placeholder={"Start date"}
                                    onChange={value => {
                                        handleFilterChange(
                                            isFiltersOverview,
                                            dateRange
                                                ? "startDate"
                                                : "valid_from",
                                            value,
                                        );
                                    }}
                                    onClear={
                                        isFiltersOverview
                                            ? () => {
                                                  handleFilterChange(
                                                      true,
                                                      dateRange
                                                          ? "startDate"
                                                          : "valid_from",
                                                      "",
                                                  );
                                              }
                                            : undefined
                                    }
                                />
                            )}
                        </div>
                        <div>
                            {(!hasSelectedFilters ||
                                (!!hasSelectedFilters &&
                                    hasSelectedFilters(
                                        dateRange ? "endDate" : "valid_to",
                                        true,
                                    ))) && (
                                <DateFilter
                                    value={filterValueFinder(
                                        dateRange ? "endDate" : "valid_to",
                                    )}
                                    placeholder={"End date"}
                                    onChange={value => {
                                        handleFilterChange(
                                            isFiltersOverview,
                                            dateRange ? "endDate" : "valid_to",
                                            value,
                                        );
                                    }}
                                    onClear={
                                        isFiltersOverview
                                            ? () => {
                                                  handleFilterChange(
                                                      true,
                                                      dateRange
                                                          ? "endDate"
                                                          : "valid_to",
                                                      "",
                                                  );
                                              }
                                            : undefined
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}

            {(!hasSelectedFilters
                ? products
                : !!hasSelectedFilters && hasSelectedFilters("product")) && (
                <div className={style.filterItem}>
                    {!hasSelectedFilters && (
                        <div className={style.resetOneFilterWrapper}>
                            <InputLabel
                                id={`${id}-select-product-label`}
                                shrink
                            >
                                {"Product"}
                            </InputLabel>
                            {!isFiltersOverview && (
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() =>
                                        handleFilterChange(
                                            isFiltersOverview,
                                            "product",
                                            [],
                                        )
                                    }
                                >
                                    Reset
                                </Button>
                            )}
                        </div>
                    )}

                    <SelectFilter
                        id={`${id}-filter-product`}
                        renderValue={"Product"}
                        loading={products?.loading}
                        handleFilterChange={(filterName, value, valueKey) =>
                            handleFilterChange(
                                isFiltersOverview,
                                filterName,
                                value,
                                valueKey,
                            )
                        }
                        filterName="product"
                        labelKey="productName"
                        valueKey="productId"
                        data={products?.data}
                        isItemChecked={isItemChecked}
                        fullWidth={!hasSelectedFilters}
                        selectedCounter={key =>
                            selectedCount
                                ? selectedCount(
                                      isFiltersOverview
                                          ? filters
                                          : selectedFilters,
                                      key,
                                  )
                                : undefined
                        }
                        onClear={
                            isFiltersOverview
                                ? () => {
                                      handleFilterChange(
                                          isFiltersOverview,
                                          "product",
                                          [],
                                      );
                                  }
                                : undefined
                        }
                    />
                </div>
            )}

            {(!hasSelectedFilters
                ? countries
                : !!hasSelectedFilters && hasSelectedFilters("countries")) && (
                <div className={style.filterItem}>
                    {!hasSelectedFilters && (
                        <div className={style.resetOneFilterWrapper}>
                            <InputLabel
                                id={`${id}-select-countries-label`}
                                shrink
                            >
                                {"Countries"}
                            </InputLabel>
                            {!isFiltersOverview && (
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() =>
                                        handleFilterChange(
                                            isFiltersOverview,
                                            "countryIsoCode",
                                            [],
                                        )
                                    }
                                >
                                    Reset
                                </Button>
                            )}
                        </div>
                    )}

                    <SelectFilter
                        id={`${id}-filter-countries`}
                        renderValue={"Country"}
                        loading={countries?.loading}
                        handleFilterChange={(filterName, value, valueKey) =>
                            handleFilterChange(
                                isFiltersOverview,
                                filterName,
                                value,
                                valueKey,
                            )
                        }
                        filterName="countryIsoCode"
                        labelKey="country"
                        valueKey="countryIsoCode"
                        data={countries?.data}
                        isItemChecked={isItemChecked}
                        fullWidth={!hasSelectedFilters}
                        selectedCounter={key =>
                            selectedCount
                                ? selectedCount(
                                      isFiltersOverview
                                          ? filters
                                          : selectedFilters,
                                      key,
                                  )
                                : undefined
                        }
                        onClear={
                            isFiltersOverview
                                ? () => {
                                      handleFilterChange(
                                          isFiltersOverview,
                                          "countryIsoCode",
                                          [],
                                      );
                                  }
                                : undefined
                        }
                    />
                </div>
            )}

            {(!hasSelectedFilters
                ? taskDefinitionKeys
                : !!hasSelectedFilters &&
                  hasSelectedFilters("taskDefinitionKey")) && (
                <div className={style.filterItem}>
                    {!hasSelectedFilters && (
                        <div className={style.resetOneFilterWrapper}>
                            <InputLabel
                                id={`${id}-select-taskDefinitionKeys-label`}
                                shrink
                            >
                                {"Task definition keys"}
                            </InputLabel>
                            {!isFiltersOverview && (
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() =>
                                        handleFilterChange(
                                            isFiltersOverview,
                                            "taskDefinitionKey",
                                            [],
                                        )
                                    }
                                >
                                    Reset
                                </Button>
                            )}
                        </div>
                    )}

                    <SelectFilter
                        id={`${id}-filter-taskDefinitionKeys`}
                        renderValue={"Task definition keys"}
                        loading={taskDefinitionKeys?.loading}
                        disableItemSelection={disableSelectionFilters}
                        handleFilterChange={(filterName, value, valueKey) =>
                            handleFilterChange(
                                isFiltersOverview,
                                filterName,
                                value,
                                valueKey,
                            )
                        }
                        filterName="taskDefinitionKey"
                        labelKey="keyName"
                        valueKey="keyId"
                        data={taskDefinitionKeys?.data}
                        isItemChecked={isItemChecked}
                        selectedCounter={key =>
                            selectedCount
                                ? selectedCount(
                                      isFiltersOverview
                                          ? filters
                                          : selectedFilters,
                                      key,
                                  )
                                : undefined
                        }
                        onClear={
                            isFiltersOverview
                                ? () => {
                                      handleFilterChange(
                                          isFiltersOverview,
                                          "taskDefinitionKey",
                                          [],
                                      );
                                  }
                                : undefined
                        }
                    />
                </div>
            )}

            {(!hasSelectedFilters
                ? accountTypes
                : !!hasSelectedFilters &&
                  hasSelectedFilters("accountType")) && (
                <div className={style.filterItem}>
                    {!hasSelectedFilters && (
                        <div className={style.resetOneFilterWrapper}>
                            <InputLabel
                                id={`${id}-select-accountTypes-label`}
                                shrink
                            >
                                {"Account type"}
                            </InputLabel>
                            {!isFiltersOverview && (
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() =>
                                        handleFilterChange(
                                            isFiltersOverview,
                                            "accountType",
                                            [],
                                        )
                                    }
                                >
                                    Reset
                                </Button>
                            )}
                        </div>
                    )}

                    <SelectFilter
                        id={`${id}-filter-accountType`}
                        renderValue={"Account type"}
                        loading={accountTypes?.loading}
                        handleFilterChange={(filterName, value, valueKey) =>
                            handleFilterChange(
                                isFiltersOverview,
                                filterName,
                                value,
                                valueKey,
                            )
                        }
                        data={accountTypes?.data}
                        filterName="accountType"
                        labelKey="accountTypeName"
                        valueKey="accountTypeId"
                        isItemChecked={isItemChecked}
                        selectedCounter={key =>
                            selectedCount
                                ? selectedCount(
                                      isFiltersOverview
                                          ? filters
                                          : selectedFilters,
                                      key,
                                  )
                                : undefined
                        }
                        onClear={
                            isFiltersOverview
                                ? () => {
                                      handleFilterChange(
                                          isFiltersOverview,
                                          "accountType",
                                          [],
                                      );
                                  }
                                : undefined
                        }
                    />
                </div>
            )}

            {(!hasSelectedFilters
                ? contractType
                : !!hasSelectedFilters && hasSelectedFilters("type")) && (
                <div className={style.filterItem}>
                    {!hasSelectedFilters && (
                        <div className={style.resetOneFilterWrapper}>
                            <InputLabel
                                id={`${id}-select-contractType-label`}
                                shrink
                            >
                                {"Contract type"}
                            </InputLabel>
                            {!isFiltersOverview && (
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() =>
                                        handleFilterChange(
                                            isFiltersOverview,
                                            "type",
                                            [],
                                        )
                                    }
                                >
                                    Reset
                                </Button>
                            )}
                        </div>
                    )}

                    <OneItemFilter
                        id={`${id}-filter-contractType`}
                        renderValue={"Contract type"}
                        loading={false}
                        handleFilterChange={(
                            filterName,
                            value,
                            valueKey,
                            isOneItemFilter,
                        ) =>
                            handleFilterChange(
                                isFiltersOverview,
                                filterName,
                                value,
                                valueKey,
                                isOneItemFilter,
                            )
                        }
                        data={[
                            {
                                label: "Performance based",
                                value: "PERFORMANCE",
                            },
                            {
                                label: "Volume based",
                                value: "VOLUME",
                            },
                        ]}
                        filterName="type"
                        labelKey="label"
                        valueKey="value"
                        value={filterValueFinder("type")}
                        fullWidth={!hasSelectedFilters}
                        onClear={
                            isFiltersOverview
                                ? () => {
                                      handleFilterChange(
                                          isFiltersOverview,
                                          "type",
                                          [],
                                      );
                                  }
                                : undefined
                        }
                    />
                </div>
            )}

            {(!hasSelectedFilters
                ? status
                : !!hasSelectedFilters && hasSelectedFilters("status")) && (
                <div className={style.filterItem}>
                    {!hasSelectedFilters && (
                        <div className={style.resetOneFilterWrapper}>
                            <InputLabel id={`${id}-select-status-label`} shrink>
                                {"Status"}
                            </InputLabel>
                            {!isFiltersOverview && (
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() =>
                                        handleFilterChange(
                                            isFiltersOverview,
                                            "status",
                                            [],
                                        )
                                    }
                                >
                                    Reset
                                </Button>
                            )}
                        </div>
                    )}

                    {status &&
                        (isArrayWithContent(status?.data) &&
                        status?.data?.length === 2 ? (
                            <OneItemFilter
                                id={`${id}-filter-status`}
                                renderValue={"Status"}
                                loading={status?.loading}
                                handleFilterChange={(
                                    filterName,
                                    value,
                                    valueKey,
                                    isOneItemFilter,
                                ) =>
                                    handleFilterChange(
                                        isFiltersOverview,
                                        filterName,
                                        value,
                                        valueKey,
                                        isOneItemFilter,
                                    )
                                }
                                data={status?.data}
                                filterName="status"
                                labelKey="label"
                                valueKey="value"
                                value={filterValueFinder("status")}
                                fullWidth={!hasSelectedFilters}
                                onClear={
                                    isFiltersOverview
                                        ? () => {
                                              handleFilterChange(
                                                  isFiltersOverview,
                                                  "status",
                                                  [],
                                              );
                                          }
                                        : undefined
                                }
                            />
                        ) : (
                            <SelectFilter
                                id={`${id}-filter-status`}
                                renderValue={"Status"}
                                loading={status?.loading}
                                handleFilterChange={(
                                    filterName,
                                    value,
                                    valueKey,
                                ) =>
                                    handleFilterChange(
                                        isFiltersOverview,
                                        filterName,
                                        value,
                                        valueKey,
                                    )
                                }
                                data={status?.data}
                                filterName="status"
                                labelKey="label"
                                valueKey="value"
                                isItemChecked={isItemChecked}
                                fullWidth={!hasSelectedFilters}
                                selectedCounter={key =>
                                    selectedCount
                                        ? selectedCount(
                                              isFiltersOverview
                                                  ? filters
                                                  : selectedFilters,
                                              key,
                                          )
                                        : undefined
                                }
                                onClear={
                                    isFiltersOverview
                                        ? () => {
                                              handleFilterChange(
                                                  isFiltersOverview,
                                                  "status",
                                                  [],
                                              );
                                          }
                                        : undefined
                                }
                            />
                        ))}
                </div>
            )}

            {(!hasSelectedFilters
                ? financeType
                : !!hasSelectedFilters &&
                  hasSelectedFilters("financeType")) && (
                <div className={style.filterItem}>
                    {!hasSelectedFilters && (
                        <div className={style.resetOneFilterWrapper}>
                            <InputLabel
                                id={`${id}-select-financeType-label`}
                                shrink
                            >
                                {"Finance type"}
                            </InputLabel>
                            {!isFiltersOverview && (
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() =>
                                        handleFilterChange(
                                            isFiltersOverview,
                                            "financeType",
                                            [],
                                        )
                                    }
                                >
                                    Reset
                                </Button>
                            )}
                        </div>
                    )}

                    <OneItemFilter
                        id={`${id}-filter-finance-type`}
                        renderValue={"Finance type"}
                        loading={financeType?.loading ?? false}
                        handleFilterChange={(
                            filterName,
                            value,
                            valueKey,
                            isOneItemFilter,
                        ) =>
                            handleFilterChange(
                                isFiltersOverview,
                                filterName,
                                value,
                                valueKey,
                                isOneItemFilter,
                            )
                        }
                        data={financeType?.data ?? []}
                        filterName="financeType"
                        labelKey="label"
                        valueKey="value"
                        value={filterValueFinder("financeType")}
                        fullWidth={!hasSelectedFilters}
                        onClear={
                            isFiltersOverview
                                ? () => {
                                      handleFilterChange(
                                          isFiltersOverview,
                                          "financeType",
                                          [],
                                      );
                                  }
                                : undefined
                        }
                    />
                </div>
            )}

            {(!hasSelectedFilters ? brands : hasSelectedFilters("brand")) && (
                <div className={style.filterItem}>
                    {!hasSelectedFilters && (
                        <div className={style.resetOneFilterWrapper}>
                            <InputLabel id={`${id}-select-brands-label`} shrink>
                                {"Brand"}
                            </InputLabel>
                            {!isFiltersOverview && (
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() =>
                                        handleFilterChange(
                                            isFiltersOverview,
                                            "brand",
                                            [],
                                        )
                                    }
                                >
                                    Reset
                                </Button>
                            )}
                        </div>
                    )}

                    <SelectFilter
                        id={`${id}-filter-brand`}
                        renderValue={"Brand"}
                        loading={brands?.loading}
                        handleFilterChange={(filterName, value, valueKey) =>
                            handleFilterChange(
                                isFiltersOverview,
                                filterName,
                                value,
                                valueKey,
                            )
                        }
                        data={brands?.data}
                        filterName="brand"
                        labelKey="brandName"
                        valueKey="brandId"
                        isItemChecked={isItemChecked}
                        selectedCounter={key =>
                            selectedCount
                                ? selectedCount(
                                      isFiltersOverview
                                          ? filters
                                          : selectedFilters,
                                      key,
                                  )
                                : undefined
                        }
                        fullWidth={!hasSelectedFilters}
                        onClear={
                            isFiltersOverview
                                ? () => {
                                      handleFilterChange(
                                          isFiltersOverview,
                                          "brand",
                                          [],
                                      );
                                  }
                                : undefined
                        }
                    />
                </div>
            )}

            {(!hasSelectedFilters
                ? therapeuticAreas
                : hasSelectedFilters("therapeutic_area")) && (
                <div className={style.filterItem}>
                    {!hasSelectedFilters && (
                        <div className={style.resetOneFilterWrapper}>
                            <InputLabel
                                id={`${id}-select-therapeuticAreas-label`}
                                shrink
                            >
                                {"Therapeutic area"}
                            </InputLabel>
                            {!isFiltersOverview && (
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() =>
                                        handleFilterChange(
                                            isFiltersOverview,
                                            "therapeutic_area",
                                            [],
                                        )
                                    }
                                >
                                    Reset
                                </Button>
                            )}
                        </div>
                    )}

                    <SelectFilter
                        id={`${id}-filter-therapeuticAreas`}
                        renderValue={"Therapeutic area"}
                        loading={therapeuticAreas?.loading}
                        handleFilterChange={(filterName, value, valueKey) =>
                            handleFilterChange(
                                isFiltersOverview,
                                filterName,
                                value,
                                valueKey,
                            )
                        }
                        data={therapeuticAreas?.data}
                        filterName="therapeutic_area"
                        labelKey="therapeuticAreaName"
                        valueKey="therapeuticAreaId"
                        isItemChecked={isItemChecked}
                        selectedCounter={key =>
                            selectedCount
                                ? selectedCount(
                                      isFiltersOverview
                                          ? filters
                                          : selectedFilters,
                                      key,
                                  )
                                : undefined
                        }
                        fullWidth={!hasSelectedFilters}
                        onClear={
                            isFiltersOverview
                                ? () => {
                                      handleFilterChange(
                                          isFiltersOverview,
                                          "therapeutic_area",
                                          [],
                                      );
                                  }
                                : undefined
                        }
                    />
                </div>
            )}

            {(!hasSelectedFilters
                ? partners
                : !!partners && hasSelectedFilters("account")) && (
                <div className={style.filterItem}>
                    {!hasSelectedFilters && (
                        <div className={style.resetOneFilterWrapper}>
                            <InputLabel
                                id={`${id}-select-therapeuticAreas-label`}
                                shrink
                            >
                                {"Partner"}
                            </InputLabel>
                            {!isFiltersOverview && (
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() =>
                                        handleFilterChange(
                                            isFiltersOverview,
                                            "account",
                                            [],
                                        )
                                    }
                                >
                                    Reset
                                </Button>
                            )}
                        </div>
                    )}

                    <Box className={style.autocompleteSpacer}>
                        <Autocomplete
                            data={partners?.data || []}
                            id={`${id}-partners-filter`}
                            loading={partners?.loading || false}
                            name="account"
                            size="small"
                            label="Partner"
                            onChange={value => {
                                handleFilterChange(
                                    isFiltersOverview,
                                    "account",
                                    value,
                                    "accountId",
                                );
                            }}
                            keysToMatch={[
                                "accountName",
                                "accountCity",
                                "sapAccountCode",
                                "knowyoursupplierid",
                            ]}
                            value={
                                filterValueFinder("account")?.[0] || undefined
                            }
                            onSearch={(query: string) =>
                                partners?.onSearch(query)
                            }
                            variant="outlined"
                            fullWidth={!hasSelectedFilters}
                            onDelete={() => {
                                handleFilterChange(
                                    isFiltersOverview,
                                    "account",
                                    [],
                                );
                            }}
                        />
                    </Box>
                </div>
            )}

            {(!hasSelectedFilters
                ? priceRange
                : hasSelectedFilters("priceRangeEnd", true) ||
                  hasSelectedFilters("priceRangeStart", true)) && (
                <div className={style.filterItem}>
                    {!hasSelectedFilters && (
                        <div className={style.resetOneFilterWrapper}>
                            <InputLabel
                                id={`${id}-select-therapeuticAreas-label`}
                                shrink
                            >
                                {"Price range"}
                            </InputLabel>
                            {!isFiltersOverview && (
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() =>
                                        handleFilterChange(
                                            isFiltersOverview,
                                            "priceRange",
                                            [],
                                        )
                                    }
                                >
                                    Reset
                                </Button>
                            )}
                        </div>
                    )}

                    <DataRange
                        id={`${id}-priceRange-filter`}
                        value={{
                            priceRangeEnd: filterValueFinder("priceRangeEnd"),
                            priceRangeStart:
                                filterValueFinder("priceRangeStart"),
                        }}
                        handleFilterChange={(
                            price: Record<
                                "priceRangeStart" | "priceRangeEnd",
                                string
                            >,
                        ) =>
                            handleFilterChange(
                                isFiltersOverview,
                                ["priceRangeStart", "priceRangeEnd"],
                                price,
                            )
                        }
                        embeded={!!isFiltersOverview}
                    />
                </div>
            )}

            {!isFiltersOverview && (
                <Box className={style.filtersActions}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setFilters(selectedFilters);
                            setShowFilters(false);
                        }}
                    >
                        Apply filters
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setFilters(initialFilters);
                            setShowFilters(false);
                        }}
                    >
                        Reset all
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => setShowFilters(false)}
                    >
                        Cancel
                    </Button>
                </Box>
            )}
        </div>
    );
};

export default Filters;
