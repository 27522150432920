import { useEffect, useState } from "react";

import { ViewingOption } from "@types";
import { HEADERS } from "@constants";
import { useSelectedCountry } from "@hooks";

export const useViewingOptions = (optionKey: string) => {
    const [options, setOptions] = useState<Array<ViewingOption>>([]);

    const { countryIsoCode } = useSelectedCountry();

    useEffect(() => {
        const cols = localStorage.getItem(optionKey);
        if (!cols) {
            const colsCreator: any = [];
            HEADERS[optionKey].forEach((col, colIdx) => {
                if (
                    col?.showForCountries?.length &&
                    !col.showForCountries.includes(countryIsoCode)
                ) {
                    return;
                }
                colsCreator.push({
                    colIdx,
                    label: col.viewingOption,
                    rowKey: col.rowKey,
                    checked:
                        !col?.unchecked &&
                        !col?.hiddenByDefault?.includes(countryIsoCode),
                });
            });

            localStorage.setItem(optionKey, JSON.stringify(colsCreator));
            setOptions(colsCreator);
        } else {
            setOptions(JSON.parse(cols));
        }
    }, [optionKey]);

    /**
     * Change table view options
     */
    const setViewingOptions = (option: ViewingOption, index: number) => {
        const copyOptions = [...options];

        if (copyOptions[index]) {
            copyOptions[index].checked = !option.checked;
        }

        setOptions(copyOptions);
        localStorage.setItem(optionKey, JSON.stringify(copyOptions));
    };

    return { viewingOptions: options, setViewingOptions };
};
