// Libs
import GridOn from "@mui/icons-material/GridOn";
import { navigate } from "gatsby";
import clonedeep from "lodash.clonedeep";
import React, { useEffect, useMemo, useRef, Fragment, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";

// Own component
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    FlowLayout,
    FlowContent,
    FlowBody,
    FlowAside,
    FlowFooter,
    Dialog,
} from "@components";
// Hooks
import { useFindStep, useCreateOutcome, useScanner } from "@hooks";
// Recoil
import {
    createOutcomeStepsState,
    contractSelectionState,
    orderSelectionState,
} from "@atoms";
import { createOutcomeMapper } from "@selectors";

// Types
import type { Location } from "@types";
import { OutcomeOverview } from "@containers";
import { isArrayWithContent, isSuccessfulCall } from "@utils";
import { Typography } from "@mui/material";

/**
 * Props type
 */
interface Props {
    children: React.ReactNode;
    location: Location;
}

type OutcomeVariables = {
    inputVariableName: string;
    type: string;
    value: string;
};

/**
 * Create outcome layout
 */
const CreateOutcomeLayout = ({ location, children }: Props) => {
    /**
     * States
     */

    const [failedFiles, setFailedFiles] = useState<
        {
            fileData: string;
            fileName: string;
            fileType: "DEFAULT" | "EXCELFILE";
            index: number;
        }[]
    >([]);

    // via this ref we can save the state of each page via stepper (higher order)
    const stepRef = useRef<any>();

    const retryCallbackRef =
        useRef<(shouldRetry: boolean) => void | null>(null);

    //API
    const {
        loading: { createOutcomeLoading },
        createOutcome,
    } = useCreateOutcome();

    //Steps hooks
    const { currentIndex: currentStepIndex, currentStep } = useFindStep(
        location,
        createOutcomeStepsState,
    );

    // Scanner
    const { scan, loading: scanning } = useScanner();

    const [steps, setSteps] = useRecoilState(createOutcomeStepsState);
    const resetAtoms = useResetRecoilState(createOutcomeMapper);

    /**
     * States
     */

    // Contract state
    const contractSelection = useRecoilValue(contractSelectionState);
    // Order state
    const orderSelection = useRecoilValue(orderSelectionState);

    /**
     * Reset stored atom
     */
    const reset = () => {
        resetAtoms();
    };

    const isFinished = useMemo(() => {
        return !!steps[steps.length - 1]?.isCompleted;
    }, [steps]);

    /**
     * Reset recoil state on refresh
     */
    useEffect(() => {
        if (
            currentStepIndex > 0 &&
            steps[currentStepIndex - 1].isPrepared === false
        ) {
            reset();
            navigate(`/create-outcome/${steps[0].id}/`);
            return;
        }
    }, [currentStepIndex, location]);

    /**
     * Check if the request can be submitted
     */
    const canSubmit = useMemo(() => {
        if (currentStepIndex !== steps.length - 1) return false; //is not submit

        return !steps.find(
            (step, index) => index < currentStepIndex && !step.isCompleted,
        );
    }, [currentStepIndex, steps]);

    /**
     * Handle upload
     */
    const handleUpload = async (files?: Array<any>) => {
        const data: {
            mappedVariables: OutcomeVariables[];
            evidences: string[];
            reason: string;
            notes: Array<{
                note: string;
                noteInfo: string;
            }>;
        } = stepRef.current.getStateData(); // get the data of the state

        const mappedAttachments =
            !files || !isArrayWithContent(files)
                ? []
                : files.map(file => {
                      return {
                          fileKey: file?.fileKey,
                          fileDescription: file?.fileDescription,
                          fileName: file?.fileName,
                      };
                  });

        const mappedNotes = isArrayWithContent(data?.notes)
            ? data?.notes.map(note => {
                  return {
                      note: note.note,
                      noteInfo: note.noteInfo,
                  };
              })
            : [];

        const mapper = {
            contractId: contractSelection.id,
            orderId: orderSelection.orderId,
            outcomeVariables: data.mappedVariables,
            evidences: data.evidences,
            reason: data.reason,
            attachments: mappedAttachments,
            notes: mappedNotes,
        };

        createOutcome(mapper).then(response => {
            if (!!response && isSuccessfulCall(response.status)) {
                const copySteps = clonedeep(steps);
                copySteps[currentStepIndex].isCompleted = true;
                setSteps(copySteps);
            }
        });
    };

    const handleComplete = (complete: Array<any>) => {
        // Handle the completed files
        handleUpload(complete);
    };

    const handleRetry = (
        incomplete: Array<any>,
        retryCallback: (shouldRetry: boolean) => void,
    ) => {
        setFailedFiles(incomplete);
        (retryCallbackRef.current as any) = retryCallback;
    };

    /**
     * Next Click handler
     */
    const onNextClick = () => {
        // reset and navigate to dashboard
        if (isFinished) {
            navigate("/dashboard/outcomes/");
            reset();
            return;
        }

        if (!canSubmit && !isFinished && steps[currentStepIndex].isPrepared) {
            stepRef.current.updateState(); // store the state in an atom
            const copySteps = clonedeep(steps);
            copySteps[currentStepIndex].isCompleted = true;
            setSteps(copySteps);

            return navigate(
                `/create-outcome/${steps[currentStepIndex + 1].id}`,
            );
        }

        // Submit
        if (canSubmit) {
            const data = stepRef.current.getStateData(); // get the data of the state

            if (isArrayWithContent(data?.attachments)) {
                const mapAttachmentsWithDescriptions = data?.attachments.map(
                    file => {
                        return {
                            file,
                            fileDescription:
                                data?.descriptions[
                                    data?.attachments.indexOf(file)
                                ],
                        };
                    },
                );
                scan(
                    mapAttachmentsWithDescriptions,
                    setFailedFiles,
                    handleComplete,
                    handleRetry,
                );
            } else {
                handleUpload();
            }
        }
    };
    /**
     * Click back handler
     */
    const onBackClick = () => {
        navigate(`/create-outcome/${steps[currentStepIndex - 1].id}/`);
    };

    const handleModalConfirm = (shouldRetry: boolean) => {
        setFailedFiles([]);
        if (retryCallbackRef.current) {
            retryCallbackRef.current(shouldRetry);
        }
    };

    /**
     * Render
     */
    return (
        <Fragment>
            <Dialog
                open={!!failedFiles?.length}
                id={`dialog-confirm-cancel-or-proceed`}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Following files failed malware scanning, you can retry
                        scanning these files or opt to proceed uploading only
                        those files that passed the malware scan
                        {failedFiles.map((file, idx) => (
                            <Typography key={idx} variant="body2" ml={1}>
                                {file?.fileName}
                            </Typography>
                        ))}
                    </Typography>
                }
                primaryButton={{
                    text: "Retry",
                    action: () => {
                        handleModalConfirm(true);
                        setFailedFiles([]);
                    },
                    loading: scanning,
                }}
                secondaryButton={{
                    text: "Proceed with upload",
                    action: () => {
                        handleModalConfirm(false);
                    },
                    loading: false,
                }}
            />

            <Breadcrumbs
                id={`create-outcome-breadcrumb`}
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
                resetAtoms={() => reset()}
            />

            <FlowLayout>
                <FlowAside>
                    <Stepper
                        id={`create-outcome-stepper`}
                        steps={steps}
                        title="Register a new outcome"
                        location={location}
                        disabled={createOutcomeLoading || !!isFinished}
                    />
                </FlowAside>

                <FlowBody>
                    <FlowContent>
                        {!!isFinished && <OutcomeOverview />}
                        {!isFinished &&
                            React.Children.map(
                                children as React.ReactElement,
                                (child: React.ReactElement) =>
                                    React.cloneElement(child, {
                                        location,
                                        currentStep,
                                        ref: stepRef,
                                        isFinished,
                                        disabled: createOutcomeLoading,
                                        id: "create-outcome",
                                    }),
                            )}
                    </FlowContent>

                    <FlowFooter>
                        <StepActions
                            id={`create-outcome-step-actions`}
                            loading={createOutcomeLoading}
                            primaryButton={{
                                text: isFinished
                                    ? "Return to overview"
                                    : canSubmit
                                      ? "Process outcome"
                                      : "Next step",
                                action: onNextClick,
                                loading: scanning || createOutcomeLoading,
                                disabled:
                                    scanning ||
                                    createOutcomeLoading ||
                                    (currentStepIndex !== undefined &&
                                        !steps[currentStepIndex]?.isPrepared),
                                isSubmitButton:
                                    currentStepIndex === steps.length ||
                                    isFinished,
                            }}
                            secondaryButton={{
                                text: "Back",
                                action: onBackClick,
                                disabled: scanning || createOutcomeLoading,
                                hidden:
                                    isFinished ||
                                    (currentStepIndex !== undefined &&
                                        currentStepIndex === 0),
                            }}
                            tertiaryButton={{
                                text: "Register another outcome",
                                action: () => {
                                    reset();
                                    navigate(`/create-outcome/${steps[0].id}/`);
                                },
                                hidden: !isFinished,
                            }}
                        />
                    </FlowFooter>
                </FlowBody>
            </FlowLayout>
        </Fragment>
    );
};

export default CreateOutcomeLayout;
