// Icons
import GridOn from "@mui/icons-material/GridOn";

// Lib
import { Box, CircularProgress, Typography } from "@mui/material";
import React, { Fragment, useLayoutEffect, useRef, useState } from "react";
import { navigate } from "gatsby";

// Hooks
import {
    useBpfPaymentCondition,
    useEditBpfPaymentCondition,
    useScanner,
    useGetBpfById,
    useSelectedCountry,
} from "@hooks";

// Components
import {
    Breadcrumbs,
    Dialog,
    FlowAside,
    FlowBody,
    FlowContent,
    FlowFooter,
    FlowLayout,
    PaymentInformation,
    StepActions,
    Stepper,
} from "@components";

// Types
import { BPFPaymentInformation } from "@types";

// Utils
import { isSuccessfulCall } from "@utils";

type EditBpfProps = {
    bankType: string;
    bpfProcessDate: string;
    invoice:
        | {
              fileKey: string;
              fileName: string;
          }
        | File;
    invoiceDate: string;
    paymentDueDate: string;
    originalInvoice?: {
        fileKey: string;
        fileName: string;
    };
    informationForCoAccrual: string;
};

/**
 * Edit Bpf
 */
const EditBpf = ({ bpfId }: { bpfId: string }) => {
    const { isGermanyTeam } = useSelectedCountry();
    /**
     * API
     */
    // Get payment conditions
    const { load, loading } = useBpfPaymentCondition();

    const {
        load: loadBpfDetails,
        loading: { loading: loadingDetails, downloadingEvidence },
        list: bpfDetails,
        downloadEvidence,
    }: any = useGetBpfById();

    // Edit BPF
    const {
        loading: creating,
        load: editBpf,
        forceReset,
        sendForApproval,
    } = useEditBpfPaymentCondition();

    // Failed files
    const [failedFiles, setFailedFiles] = React.useState<
        {
            fileData: string;
            fileName: string;
            fileType: "DEFAULT" | "EXCEL";
            index: number;
        }[]
    >([]);

    /**
     * Submit button type
     */
    const [submitButtonType, setSubmitButtonType] = useState<
        "primary" | "tertiary" | ""
    >("");

    // Add new validation state
    const [dateErrors, setDateErrors] = useState({
        bpfProcessDate: false,
        paymentDueDate: false,
        bankType: false,
    });

    const retryCallbackRef =
        useRef<(shouldRetry: boolean) => void | null>(null);

    // Scanner
    const { scan, loading: scanning } = useScanner("DEFAULT");

    /**
     * BPF General Information State
     */
    const [values, updateValuesState] = useState<
        BPFPaymentInformation & {
            originalInvoice: { fileName: string } | undefined;
        }
    >({
        invoiceDate: "",
        paymentDueDate: "",
        bpfProcessDate: "",
        bankType: "",
        originalInvoice: undefined,
        invoice: undefined,
        informationForCoAccrual: "",
    });

    /**
     * Load BPF details
     */
    useLayoutEffect(() => {
        if (!bpfId) return;
        setSubmitButtonType("");
        loadBpfDetails(bpfId).then(res => {
            if (isSuccessfulCall(res?.status) && !!res?.data) {
                updateValuesState({
                    invoiceDate: res?.data?.invoiceDate,
                    paymentDueDate: res?.data?.paymentDueDate,
                    bpfProcessDate: res?.data?.bpfProcessDate,
                    bankType: res?.data?.bankType,
                    originalInvoice: res?.data?.invoice,
                    invoice: undefined,
                    informationForCoAccrual: res?.data?.informationForCoAccrual,
                });
            }
        });
    }, [bpfId]);

    /**
     * Change handler
     */
    const onChange = (key: string | undefined, value: any) => {
        if (!key) return;

        updateValuesState(prevValues => ({
            ...prevValues,
            [key]: value,
        }));
    };

    /**
     * Upload handler
     */
    const handleComplete = (
        complete?: Array<any>,
        isSendForApproval: boolean = false,
    ) => {
        forceReset();

        editBpf(bpfId, {
            ...(values as EditBpfProps),
            bankType: values?.bankType ? Number(values?.bankType) : undefined,
            invoice: values?.originalInvoice
                ? undefined
                : {
                      fileKey: complete?.[0]?.fileKey,
                      fileName: complete?.[0]?.fileName,
                  },
            originalInvoice: undefined, // For the UI only
        }).then(res => {
            setSubmitButtonType("");
            if (isSuccessfulCall(res?.status)) {
                setFailedFiles([]);
                updateValuesState({
                    invoiceDate: "",
                    paymentDueDate: "",
                    bpfProcessDate: "",
                    bankType: "",
                    originalInvoice: undefined,
                    invoice: undefined,
                    informationForCoAccrual: "",
                });

                if (isSendForApproval) {
                    sendForApproval(bpfId, bpfDetails?.data?.bpfName).then(
                        res => {
                            if (isSuccessfulCall(res?.status)) {
                                navigate("/dashboard/finances/bpf/");
                            }
                        },
                    );
                    return;
                } else {
                    navigate("/dashboard/finances/bpf/");
                }
            }
        });
    };

    /**
     * Retry handler
     */
    const handleRetry = (
        incomplete: Array<any>,
        retryCallback: (shouldRetry: boolean) => void,
    ) => {
        setFailedFiles(incomplete);
        (retryCallbackRef.current as any) = retryCallback;
    };

    /**
     * Modal confirm handler
     */
    const handleModalConfirm = (shouldRetry: boolean) => {
        if (!shouldRetry) {
            setFailedFiles([]);
            return;
        }

        if (retryCallbackRef.current) {
            retryCallbackRef.current(shouldRetry);
        }
    };

    /**
     * On Submit
     */
    const onSubmit = (isSendForApproval: boolean = false) => {
        if (!values?.originalInvoice) {
            scan(
                [{ file: values?.invoice }],
                setFailedFiles,
                handleComplete,
                handleRetry,
                isSendForApproval,
            );
        } else {
            handleComplete(undefined, isSendForApproval);
        }
    };

    /**
     * Disabled
     */
    const disabled =
        !values?.invoiceDate ||
        !values?.paymentDueDate ||
        (!values?.invoice && !values?.originalInvoice) ||
        !!dateErrors?.bpfProcessDate ||
        !!dateErrors?.paymentDueDate ||
        !!dateErrors?.bankType;

    /**
     * Render
     */
    return (
        <Fragment>
            <Dialog
                open={!!failedFiles?.length}
                id={`dialog-confirm-scanning`}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Following files failed malware scanning, you can retry
                        scanning these files or opt to proceed uploading only
                        those files that passed the malware scan
                        {failedFiles.map((file, idx) => (
                            <Typography key={idx} variant="body2" ml={1}>
                                {file?.fileName}
                            </Typography>
                        ))}
                    </Typography>
                }
                primaryButton={{
                    text: "Retry",
                    action: () => {
                        handleModalConfirm(true);
                        // setFailedFiles([]);
                    },
                    loading: scanning,
                }}
                secondaryButton={{
                    text: "Cancel claim file upload",
                    action: () => {
                        handleModalConfirm(false);
                    },
                    loading: false,
                }}
            />
            <Breadcrumbs
                id={`generate-bpf-breadcrumb`}
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
                subPage={`Edit BPF`}
            />

            <FlowLayout>
                <FlowAside>
                    <Stepper
                        id={`edit-bpf`}
                        title={`Edit BPF`}
                        location={location}
                        subTitle={bpfDetails?.data?.bpfName}
                    />
                </FlowAside>

                <FlowBody>
                    <Fragment>
                        <FlowContent>
                            {loadingDetails ? (
                                <Box
                                    height={1}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <PaymentInformation
                                    title={"Edit BPF"}
                                    bankType={values?.bankType}
                                    loadPaymentCondition={load}
                                    paymentCondition={values?.paymentCondition}
                                    loadingPaymentCondition={loading}
                                    handleChange={onChange}
                                    contractIds={bpfDetails?.data?.contractIds}
                                    downloadEvidence={downloadEvidence}
                                    downloadingEvidence={downloadingEvidence}
                                    isEditBpf
                                    bpfId={bpfId}
                                    originalInvoice={values?.originalInvoice}
                                    invoiceDate={values?.invoiceDate}
                                    paymentDueDate={values?.paymentDueDate}
                                    bpfProcessDate={values?.bpfProcessDate}
                                    invoice={values?.invoice}
                                    setDateErrors={setDateErrors}
                                    dateErrors={dateErrors}
                                    type={bpfDetails?.data?.invoice?.type}
                                    informationForCoAccrual={
                                        values?.informationForCoAccrual
                                    }
                                    isGermanyTeam={isGermanyTeam}
                                    isVolumeBased={
                                        bpfDetails?.data?.bpfType === "CLAIM"
                                    }
                                    isApheresisBased={
                                        bpfDetails?.data?.bpfType ===
                                        "REBATE_AT_APHERESIS"
                                    }
                                    isPerformanceBased={
                                        bpfDetails?.data?.bpfType === "OUTCOME"
                                    }
                                />
                            )}
                        </FlowContent>
                        <FlowFooter>
                            <StepActions
                                id={`generate-bpf-step-actions`}
                                primaryButton={{
                                    text: "Save and submit BPF for approval",
                                    isSubmitButton: false,
                                    action: () => {
                                        setSubmitButtonType("primary");
                                        onSubmit(true);
                                    },
                                    disabled: disabled || creating || scanning,
                                    loading:
                                        submitButtonType === "primary" &&
                                        (creating || scanning),
                                }}
                                tertiaryButton={{
                                    text: "Save the BPF",
                                    action: () => {
                                        setSubmitButtonType("tertiary");
                                        onSubmit(false);
                                    },
                                    disabled: disabled || creating || scanning,
                                    loading:
                                        submitButtonType === "tertiary" &&
                                        (creating || scanning),
                                }}
                                secondaryButton={{
                                    text: "Return to BPF overview",
                                    action: () =>
                                        navigate(`/dashboard/finances/bpf/`),
                                }}
                            />
                        </FlowFooter>
                    </Fragment>
                </FlowBody>
            </FlowLayout>
        </Fragment>
    );
};

export default React.memo(EditBpf);
