// Icons
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Lib
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import React, { useEffect, useState, Fragment, useMemo } from "react";
import classnames from "classnames";
import { navigate } from "gatsby";
// Constants
import { BREADCRUMBS_MAP } from "@constants";

// Types
import { Location } from "@types";

// Styles
import * as style from "./style.module.scss";
import { useRecoilValue } from "recoil";

// Atoms
import { systemState } from "@atoms";
import { useFlowCheck } from "@hooks";

/**
 * Breadcrumbs Page
 */

type BreadcrumbsPage = {
    label: string;
    id: string;
    homePath?: string;
};

/**
 * Props type
 */
interface Props {
    location: Location;
    icon: React.ReactNode;
    title: string;
    resetAtoms?: () => void;
    id: string;
    onClick?: () => void;
    itemCount?: number;
    subPage?: string;
}

/**
 * Breadcrumbs
 */
const Breadcrumbs: React.FC<Props> = ({
    location,
    title,
    id,
    itemCount,
    subPage,
    onClick,
    resetAtoms,
}) => {
    /**
     * Hooks
     */
    const { isFlowInProgress, resetFlow } = useFlowCheck();

    /**
     * System info
     */
    const sysInfo = useRecoilValue(systemState);

    // State
    const [currentPage, setPage] = useState<BreadcrumbsPage | undefined>(
        undefined,
    );

    useEffect(() => {
        if (!location) return;

        const index = BREADCRUMBS_MAP.findIndex(item =>
            location.pathname.includes(item.id),
        );

        if (index === -1) {
            setPage(undefined);
        } else setPage(BREADCRUMBS_MAP[index]);
    }, [location]);

    /**
     * Version
     */
    const version = useMemo(() => {
        if (!sysInfo?.version) return;
        return {
            version: sysInfo?.version?.branch
                ? `${sysInfo?.version?.branch}`
                : undefined,
            release: sysInfo?.version?.release,
            commitHash: sysInfo?.version?.commitHash
                ? sysInfo?.version?.commitHash
                : undefined,
        };
    }, [sysInfo]);

    /**
     * Handle main page click
     */
    const handleClick = () => {
        if (!currentPage?.homePath) return;
        // reset flow if it is in progress and the user is navigating to a different page
        if (isFlowInProgress) {
            resetFlow();
        }
        if (onClick) {
            onClick();
            return;
        }
        if (currentPage?.homePath) {
            navigate(currentPage.homePath);
            !!resetAtoms && resetAtoms();
        }
    };

    /**
     * Title constructor
     */
    const titleConstructor = useMemo(() => {
        return (
            <Box display="flex" alignItems="center">
                <div
                    className={classnames(style.item, {
                        [style.lightTitle]: !!subPage,
                        [style.clickable]: !!currentPage?.homePath,
                    })}
                    id={`${id}-home-page-btn`}
                    onKeyDown={handleClick}
                    onClick={handleClick}
                >
                    {title}
                </div>

                {subPage && (
                    <div className={style.item} id={`${id}-sub-page-btn`}>
                        <ArrowForwardIosIcon fontSize="small" />
                        {subPage}
                        {!!itemCount && (
                            <div className={style.chip}>{itemCount}</div>
                        )}
                    </div>
                )}
            </Box>
        );
    }, [subPage, itemCount, currentPage]);

    /**
     * Render
     */
    return (
        <Fragment>
            {title && (
                <Helmet>
                    <title>
                        {currentPage?.label
                            ? `${title} | ${currentPage.label}`
                            : `${title}`}
                    </title>
                </Helmet>
            )}

            <div className={style.wrapper}>
                {titleConstructor}
                {version && (
                    <div className={style.version}>
                        {!!version.version && <span>{version.version}</span>}
                        {!!version.release && (
                            <span>{`V.${version.release}`}</span>
                        )}
                        {!!version.commitHash && (
                            <span>{`${version.commitHash}`}</span>
                        )}
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default Breadcrumbs;
