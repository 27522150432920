import { Typography, Tooltip as _Tooltip } from "@mui/material";
import React from "react";

interface Props {
    field: string;
    stringLength: number;
}

const Tooltip: React.FC<Props> = ({ field, stringLength }) => {
    return field?.length > stringLength ? (
        <_Tooltip arrow placement="top" title={field}>
            <Typography variant="caption2">
                {field.substring(0, stringLength) + "..."}
            </Typography>
        </_Tooltip>
    ) : (
        <Typography variant="caption2">{field}</Typography>
    );
};
export default Tooltip;
