// Icons
import SquareIcon from "@mui/icons-material/Square";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// Lib
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { Fragment, useMemo } from "react";
import clonedeep from "lodash.clonedeep";

// Own components
import { FieldRenderer, MultiSelect } from "@components";

// Types
import { TeamRole } from "@types";

// Utils
import { isArrayWithContent, stringTransformer } from "@utils";

// Styles
import * as style from "./style.module.scss";

/**
 * Right type
 */
interface Right {
    category: string;
    rightId: string;
    rightName: string;
}

/**
 * Props type
 */
interface Props {
    id: string;
    onRightsChange?: (rightId: string) => void;
    onRoleChange?: (role: TeamRole[]) => void;
    disabled?: boolean;
    rights: Array<Right>;
    values?: Array<string>;
    roles?: {
        data: Array<TeamRole>;
        loading: boolean;
    };
    approvalRoles?: TeamRole[];
    onBlur?: (key: string) => void;
    isEditMode?: boolean;
}
/**
 * User Rights
 */
const UserRights: React.FC<Props> = ({
    id,
    onRightsChange,
    onRoleChange,
    disabled,
    rights,
    values,
    roles,
    approvalRoles,
    onBlur,
    isEditMode,
}) => {
    /**
     * Map rights to Category & right label
     */
    const rightsMapper = useMemo(() => {
        if (!isArrayWithContent(rights)) return;

        return rights.reduce((acc: any, curr: Right) => {
            const found = acc.find((right: Right) => {
                return right.category === curr.category;
            });

            if (found) {
                found.rightIds.push({
                    rightId: curr.rightId,
                    rightName: curr.rightName,
                });
            } else {
                acc.push({
                    category: curr.category,
                    rightIds: [
                        {
                            rightId: curr.rightId,
                            rightName: curr.rightName,
                        },
                    ],
                });
            }
            return acc;
        }, []);
    }, [rights]);

    /**
     * Handle role change
     */
    const handleRoleChange = (event: React.SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        //values is array of therapeuticAreaIds
        const valueSize = target.value.length;

        const value = JSON.parse(target.value[valueSize - 1]);

        const copyApprovalRoles = clonedeep(approvalRoles);

        const idx = copyApprovalRoles.findIndex(
            id => id["roleId"] === value["roleId"],
        );

        if (idx === -1) {
            copyApprovalRoles.push(value);
        } else {
            copyApprovalRoles.splice(idx, 1);
        }
        onRoleChange?.(copyApprovalRoles);
    };

    /**
     * Render
     */
    return (
        <FormControl fullWidth>
            <FormGroup>
                <Grid container item xs={12} spacing={2}>
                    {rightsMapper?.map(item => (
                        <Grid
                            container
                            item
                            xs={6}
                            key={`${item.category}`}
                            data-testid={item.category}
                            mb={5}
                            justifyContent={"space-between"}
                            alignItems={"baseline"}
                        >
                            <Grid item xs={3} pt={1}>
                                <Typography
                                    variant="caption1"
                                    color="text.disabled"
                                >
                                    {stringTransformer(item.category, {
                                        capitalize: true,
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {item.rightIds.map((el, rightsIndex) => (
                                    <Fragment
                                        key={`${id}-${rightsIndex}-${el.rightId}-${item.category}`}
                                    >
                                        <FormControlLabel
                                            id={`${id}-${rightsIndex}-${el.rightId}-${item.category}`}
                                            sx={{
                                                width: "100%",
                                            }}
                                            control={
                                                <Checkbox
                                                    id={`${id}-${rightsIndex}-${el.rightId}-${item.category}-checkbox`}
                                                    onBlur={() =>
                                                        onBlur?.("rightIds")
                                                    }
                                                    checked={
                                                        !!values &&
                                                        values?.indexOf(
                                                            el.rightId,
                                                        ) > -1
                                                    }
                                                    checkedIcon={
                                                        <CheckBoxIcon
                                                            sx={{
                                                                width: "0.85em",
                                                                height: "0.85em",
                                                                outlineOffset:
                                                                    "-3px",
                                                                outline:
                                                                    disabled
                                                                        ? "3px solid #9CA6C6"
                                                                        : "2px solid #036",
                                                                borderRadius:
                                                                    "3px",
                                                            }}
                                                        />
                                                    }
                                                    icon={
                                                        <SquareIcon
                                                            sx={{
                                                                width: "0.85em",
                                                                height: "0.85em",
                                                                border: "0.25px solid #036",
                                                                boxShadow:
                                                                    "inset 0px 0px 0px 0.2px #036",
                                                                outline:
                                                                    "7px solid white",
                                                                outlineOffset:
                                                                    "-8px",
                                                                borderRadius:
                                                                    "2px",
                                                                fill: "white",
                                                            }}
                                                        />
                                                    }
                                                    onChange={() =>
                                                        onRightsChange
                                                            ? onRightsChange(
                                                                  el.rightId,
                                                              )
                                                            : undefined
                                                    }
                                                    name={el.rightId}
                                                />
                                            }
                                            label={
                                                <Typography variant="caption1">
                                                    {stringTransformer(
                                                        el.rightName,
                                                    )}
                                                </Typography>
                                            }
                                            disabled={disabled}
                                        />

                                        {el?.rightId === "RightId15" &&
                                            !!values &&
                                            values?.indexOf("RightId15") >
                                                -1 && (
                                                <Fragment>
                                                    {isEditMode ? (
                                                        <Box mt={1}>
                                                            <label
                                                                className={
                                                                    style.label
                                                                }
                                                            >
                                                                {
                                                                    "Approver roles"
                                                                }
                                                            </label>

                                                            <MultiSelect
                                                                id={`${id}-team-approver-role`}
                                                                name="approverRoles"
                                                                whiteBg
                                                                value={
                                                                    approvalRoles?.map(
                                                                        role =>
                                                                            role.roleId,
                                                                    ) || []
                                                                }
                                                                onChange={
                                                                    handleRoleChange
                                                                }
                                                                multipleSelectKey="roleId"
                                                                menuItemLabel={
                                                                    "roleName"
                                                                }
                                                                list={
                                                                    roles?.data ||
                                                                    []
                                                                }
                                                                displayEmpty
                                                                disabled={
                                                                    disabled
                                                                }
                                                                loading={
                                                                    roles?.loading
                                                                }
                                                            />
                                                        </Box>
                                                    ) : values?.indexOf(
                                                          "RightId15",
                                                      ) > -1 ? (
                                                        <Fragment>
                                                            <label
                                                                className={
                                                                    style.label
                                                                }
                                                            >
                                                                {
                                                                    "Approver roles"
                                                                }
                                                            </label>
                                                            <FieldRenderer
                                                                id={`${id}-role`}
                                                                showTooltip
                                                                value={
                                                                    !!approvalRoles &&
                                                                    isArrayWithContent(
                                                                        approvalRoles,
                                                                    )
                                                                        ? approvalRoles
                                                                              ?.map(
                                                                                  role =>
                                                                                      role.roleName,
                                                                              )
                                                                              .join(
                                                                                  ", ",
                                                                              )
                                                                        : "None"
                                                                }
                                                            />
                                                        </Fragment>
                                                    ) : null}
                                                </Fragment>
                                            )}
                                    </Fragment>
                                ))}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </FormGroup>
        </FormControl>
    );
};
export default React.memo(UserRights);
