//Lib
import {
    Box,
    Button,
    Grid,
    Typography,
    ListItem,
    List,
    ListItemIcon,
    ListItemText,
    Checkbox,
    InputLabel,
    TextField,
} from "@mui/material";
import clonedeep from "lodash.clonedeep";
import { useFormik } from "formik";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import { navigate } from "gatsby";
import React, {
    useEffect,
    useImperativeHandle,
    Fragment,
    useCallback,
    useState,
    useMemo,
    useLayoutEffect,
} from "react";

//Own components
import {
    FormFooter,
    GrayBox,
    OutcomeVariables,
    FieldRenderer,
    LoadingWrapper,
    EditNotes,
    Dropzone,
} from "@components";
// Atoms
import { outcomeDetailsState, addOutcomeStepsState } from "@atoms";

// Custom hooks
import { useUpdateStep, useUploadDocument } from "@hooks";

// Utils
import { displayDate, isArrayWithContent } from "@utils";

// Schemas
import { outcomeDetailsSchema } from "@schemas";

// Style
import * as style from "./style.module.scss";

// Types
import { FlowStep, Location } from "@types";

/**
 * Props
 */
interface Props {
    currentStep?: FlowStep;
    order?: any;
    variables?: Array<any>;
    disabled?: boolean;
    location?: Location;
    id?: string;
    loading?: boolean;
}

/**
 * Add outcome
 */
const AddOutcome = React.forwardRef(
    (
        {
            order,
            variables,
            disabled,
            currentStep,
            location,
            id,
            loading,
        }: Props,
        ref,
    ) => {
        const [editNote, setEditNote] = useState<{
            index: number;
            note: string;
            noteInfo: string;
        }>();

        // Outcome details state
        const outcomeDetailsInitialState = useRecoilValue(outcomeDetailsState);

        // Update step hook
        const updateStepValidation = useUpdateStep(
            location,
            addOutcomeStepsState,
        );

        /**
         * API
         */
        // Get evidences
        const {
            list: evidences,
            loading: { fetching: fetchingEvidences },
            load: loadEvidences,
            download: downloadEvidence,
        } = useUploadDocument();

        //Formik state
        const formik = useFormik({
            enableReinitialize: true,
            isInitialValid: currentStep?.isPrepared,
            initialValues: {
                ...outcomeDetailsInitialState,
            },
            validationSchema: outcomeDetailsSchema,
            onSubmit: () => undefined,
        });

        const descriptionIsEmpty = useMemo(() => {
            if (!isArrayWithContent(formik?.values?.descriptions)) return false;
            return formik?.values?.descriptions?.some(el => !el);
        }, [formik?.values?.descriptions]);

        /**
         * Form validation check
         */
        const canSaveState =
            (currentStep?.isPrepared &&
                Object.keys(formik.errors).length === 0) ||
            (!!formik &&
                !!formik.isValid &&
                Object.keys(formik.errors).length === 0 &&
                Object.keys(formik.touched).length > 0 &&
                !editNote &&
                !descriptionIsEmpty);

        /**
         * Check if the form has at least one error
         */

        useEffect(() => {
            if (order) {
                formik.setFieldValue("infusionDate", order?.infusionDate);
            }
        }, [order]);

        useEffect(() => {
            if (isArrayWithContent(formik?.values?.attachments)) {
                const newDescriptions = formik?.values?.attachments.map(
                    (_, index) => {
                        return formik?.values?.descriptions?.[index] || "";
                    },
                );
                formik.setFieldValue("descriptions", newDescriptions);
            }
        }, [formik?.values?.attachments]);

        /**
         * load evidences
         */
        useLayoutEffect(() => {
            if (!order?.contract?.id) return;
            loadEvidences(order?.contract?.id);
        }, [order?.contract?.id]);

        useEffect(() => {
            if (!!variables && isArrayWithContent(variables)) {
                const mappedVariables = variables.map(el => {
                    return {
                        ...el,
                        value: undefined,
                    };
                });

                formik.setFieldValue("variables", mappedVariables);
            }
        }, [variables]);

        /**
         * Check if the current step is valid
         * then set isPrepared to true, otherwise set it false
         */
        useEffect(() => {
            updateStepValidation(canSaveState);
        }, [canSaveState, location]);

        /**
         * Save state and go to the next page (controlled by the layout)
         */
        useImperativeHandle(ref, () => ({
            getStateData() {
                // Avoid BE whitelist validation
                const outcomeVariables = formik.values.variables.map(el => {
                    const { inputVariableName, type, value } = el;
                    return {
                        inputVariableName,
                        type,
                        value,
                    };
                });

                return {
                    outcomeVariables,
                    reason: formik.values.reason,
                    evidences: formik.values.evidences,
                    descriptions: formik?.values?.descriptions,
                    attachments: formik?.values?.attachments,
                    notes: formik?.values?.notes,
                };
            },
        }));

        /**
         * Handle form change
         */
        const onDataChange = (elementIndex: number, value: any) => {
            const values = clonedeep(formik.values.variables);
            values[elementIndex].value = value;
            formik.setFieldValue("variables", values);
        };

        /**
         * Evidence handler
         */
        const addEvidence = (evidenceId: string) => {
            const ids: Array<string> = [...formik.values.evidences];
            const idExists = ids.includes(evidenceId);
            if (idExists) {
                const filtered = ids.filter(id => id !== evidenceId);
                formik.setFieldValue("evidences", filtered);
            } else {
                ids.push(evidenceId);
                formik.setFieldValue("evidences", ids);
            }
        };

        const isEvidenceChecked = useCallback(
            (evidenceId: string) => {
                if (!evidenceId) return;
                return formik?.values?.evidences?.includes(evidenceId);
            },
            [formik?.values?.evidences],
        );

        /**
         *
         * @param index number
         * @param value string
         */
        const onDescriptionChange = (
            type: "delete" | "edit",
            index: number,
            value: string,
        ) => {
            if (type === "edit") {
                const newDescriptions = [
                    ...(formik?.values?.descriptions as string[]),
                ];
                newDescriptions[index] = value;
                formik.setFieldValue("descriptions", newDescriptions);
            } else if (type === "delete") {
                const newDescriptions = [
                    ...(formik?.values?.descriptions as string[]),
                ];
                newDescriptions.splice(index, 1);
                formik.setFieldValue("descriptions", newDescriptions);
            }
        };

        /**
         * On attachment remove
         */
        const onAttachmentRemove = (index: number) => {
            if (!isArrayWithContent(formik.values?.attachments)) return;

            const copyAttachments = [...formik.values.attachments];
            const filteredAttachments = copyAttachments.filter(
                (_, idx) => idx !== index,
            );

            formik?.setFieldValue("attachments", filteredAttachments);
            onDescriptionChange("delete", index, "");
        };

        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height={1}
                id={order?.orderId}
                className={style.wrapper}
            >
                <div>
                    <Box mb={7}>
                        <Typography variant="h2">Outcome details</Typography>
                    </Box>

                    <Typography variant="h3" marginBottom={2}>
                        Order details
                    </Typography>

                    <Box className={style.card}>
                        <Grid
                            item
                            xs={12}
                            container
                            rowSpacing={3}
                            columnSpacing={4}
                        >
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-add-order-shipTo`}
                                    label="Ship To"
                                    value={order?.treatmentSite}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-add-order-infusionDate`}
                                    label="Infusion date"
                                    value={displayDate(order?.infusionDate)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-add-order-salessystemOrderId`}
                                    label="Cquence ID"
                                    value={order?.salessystemOrderId}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-add-order-reference`}
                                    label="Contract reference"
                                    value={order?.contract?.reference}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-add-order-brandName`}
                                    label="Brand"
                                    value={order?.contract?.brandName}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-add-order-orderDate`}
                                    label="Order date"
                                    value={displayDate(order?.orderDate)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-add-order-orderStatus`}
                                    label="Status"
                                    value={order?.orderStatus}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    {!!order?.contract?.id && (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            mt={5}
                        >
                            <Typography variant="h3" marginBottom={2}>
                                Contract details
                            </Typography>

                            <Button
                                id={`${id}-add-order-view-order-btn`}
                                variant="text"
                                onClick={() =>
                                    navigate(
                                        `/contract/view-contract/${order?.contract?.id}`,
                                        {
                                            state: {
                                                prevPath: location,
                                            },
                                        },
                                    )
                                }
                            >
                                <Typography
                                    variant="link"
                                    className={style.link}
                                >
                                    {"See full contract detail"}
                                </Typography>
                            </Button>
                        </Box>
                    )}

                    {order?.contract?.id && (
                        <GrayBox
                            id={`${id}-add-order-gray-box`}
                            padding={"1rem"}
                            header={
                                <Box>
                                    <Typography variant="h3">
                                        {order?.contract?.reference}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        className={classNames(
                                            style.details,
                                            style.separator,
                                        )}
                                    >
                                        {!!order?.contract?.treatmentSite && (
                                            <Typography variant="subtitle2">
                                                {order?.contract?.treatmentSite}
                                            </Typography>
                                        )}
                                        {!!order?.contract?.brandName && (
                                            <Typography variant="subtitle2">
                                                {order?.contract?.brandName}
                                            </Typography>
                                        )}
                                        {!!order?.contract?.indicationName && (
                                            <Typography variant="subtitle2">
                                                {
                                                    order?.contract
                                                        ?.indicationName
                                                }
                                            </Typography>
                                        )}
                                        {!!order?.contract?.startDate && (
                                            <Typography variant="subtitle2">
                                                {`Valid from ${displayDate(
                                                    order?.contract?.startDate,
                                                )} to ${displayDate(
                                                    order?.contract?.endDate,
                                                )}`}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            }
                        />
                    )}

                    <Box mt={5}>
                        <Typography variant="h3" mb={3}>
                            Fill in outcome details
                        </Typography>
                        <div className={style.inputSection}>
                            <OutcomeVariables
                                id={`${id}-add-order-outcome-variables`}
                                errors={formik.errors}
                                touched={formik.touched}
                                onBlur={formik.setFieldTouched}
                                onChange={onDataChange}
                                values={formik.values.variables}
                                disabled={disabled}
                            />

                            <div className={style.block}>
                                <InputLabel
                                    error={
                                        !!formik.errors["reason"] &&
                                        !!formik.touched["reason"]
                                    }
                                    shrink
                                >
                                    {`Reason of outcome ${
                                        isArrayWithContent(
                                            formik.values.evidences,
                                        )
                                            ? ""
                                            : "(*)"
                                    }`}
                                </InputLabel>

                                <TextField
                                    id={`${id}-reason`}
                                    fullWidth
                                    name={"reason"}
                                    // multiline
                                    //rows={5}
                                    onBlur={formik.handleBlur}
                                    size="small"
                                    autoComplete="off"
                                    variant="outlined"
                                    value={formik.values.reason}
                                    onChange={formik.handleChange}
                                    error={
                                        !!formik.errors["reason"] &&
                                        !!formik.touched["reason"]
                                    }
                                />

                                <InputLabel
                                    shrink
                                >{`or attach file(s)`}</InputLabel>
                                <LoadingWrapper
                                    id={`${id}-outcome-evidences-loading`}
                                    loading={fetchingEvidences || loading}
                                    customMsg={
                                        isArrayWithContent(
                                            evidences?.data?.records,
                                        )
                                            ? undefined
                                            : "No files found!"
                                    }
                                >
                                    <div className={style.list}>
                                        {isArrayWithContent(
                                            evidences?.data?.records,
                                        ) && (
                                            <List dense>
                                                {evidences?.data?.records?.map(
                                                    item => {
                                                        return (
                                                            <Fragment
                                                                key={`evidence-item-${item.evidenceId}`}
                                                            >
                                                                <ListItem
                                                                    id={`evidence-item-${item.evidenceId}`}
                                                                    disablePadding
                                                                >
                                                                    <ListItemIcon
                                                                        sx={{
                                                                            minWidth:
                                                                                "2rem",
                                                                        }}
                                                                    >
                                                                        <Checkbox
                                                                            disableRipple
                                                                            size="small"
                                                                            edge="start"
                                                                            checked={isEvidenceChecked(
                                                                                item.evidenceId,
                                                                            )}
                                                                            onChange={() =>
                                                                                addEvidence(
                                                                                    item.evidenceId,
                                                                                )
                                                                            }
                                                                            tabIndex={
                                                                                -1
                                                                            }
                                                                            inputProps={{
                                                                                "aria-labelledby":
                                                                                    item.evidenceId,
                                                                            }}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography
                                                                                variant="link"
                                                                                className={
                                                                                    style.link
                                                                                }
                                                                                onClick={() =>
                                                                                    downloadEvidence(
                                                                                        order
                                                                                            ?.contract
                                                                                            ?.id,
                                                                                        item.evidenceId,
                                                                                    )
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.fileName
                                                                                }
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            </Fragment>
                                                        );
                                                    },
                                                )}
                                            </List>
                                        )}
                                    </div>
                                </LoadingWrapper>
                            </div>

                            <div className={style.block}>
                                <Dropzone
                                    variant="big"
                                    id={"upload-claim-files"}
                                    fileTypes={{
                                        "application/pdf": [".pdf"],
                                        "image/png": [".png"],
                                        "image/jpeg": [".jpeg", ".jpg"],
                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                            [".docx"],
                                        "application/msword": [".doc"],
                                        "application/vnd.ms-excel": [".xls"],
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                                            [".xlsx"],
                                        "application/vnd.ms-outlook": [".msg"],
                                        "text/plain": [".txt"],
                                    }}
                                    files={formik?.values?.attachments || []}
                                    onUpload={newFiles =>
                                        formik.setFieldValue(
                                            "attachments",
                                            newFiles,
                                        )
                                    }
                                    onAttachmentRemove={onAttachmentRemove}
                                    maxNameLength="114"
                                    multiple
                                    disabled={disabled}
                                    hasFileDescription
                                    descriptions={formik?.values?.descriptions}
                                    onDescriptionChange={onDescriptionChange}
                                />
                            </div>
                            <div className={style.block}>
                                <EditNotes
                                    id={`${id}-edit-claim-notes`}
                                    storedNotes={formik?.values?.notes || []}
                                    setNotes={notes =>
                                        formik?.setFieldValue("notes", notes)
                                    }
                                    editNote={editNote}
                                    setEditNote={setEditNote}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                    </Box>
                </div>
                <FormFooter
                    id={`${id}-add-outcome-footer`}
                    error={
                        Object.keys(formik.errors).length > 0 &&
                        Object.keys(formik.touched).length > 0
                    }
                    textAlign="right"
                />
            </Box>
        );
    },
);

export default React.memo(AddOutcome);
