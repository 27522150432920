// Icons
import GridOn from "@mui/icons-material/GridOn";

// Libs
import { navigate } from "gatsby";
import React, {
    Fragment,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";

// Own components
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    LoadingWrapper,
    FlowLayout,
    FlowAside,
    FlowBody,
    FlowContent,
    FlowFooter,
    ClaimDetails as _ClaimDetails,
    Dialog,
} from "@components";

//Hooks
import { useGetClaimById, useScanner } from "@hooks";

//Types
import type { Location } from "@types";
import { isArrayWithContent, isSuccessfulCall } from "@utils";
import { Typography } from "@mui/material";

// Define the structure of a Note
type Note = {
    note: string;
    noteInfo: string;
    claimNoteId?: string;
};

// Notes is an array of Note or an empty array
type Notes = Array<Note> | [];

/**
 * Props type
 */
interface Props {
    location: Location;
    children?: React.ReactNode;
    claimId: string;
    id?: string;
    isEditClaim?: boolean;
}

/**
 * Claim details
 */
const ClaimDetails = ({
    claimId,
    location,
    id = "claim-details",
    isEditClaim,
}: Props) => {
    const [descriptions, setDescriptions] = useState<string[]>([]);
    const [files, uploadFiles] = useState([]);
    const [failedFiles, setFailedFiles] = useState<
        {
            fileData: string;
            fileName: string;
            fileType: "DEFAULT" | "EXCELFILE";
            index: number;
        }[]
    >([]);

    const [storedAttachments, setStoredAttachments] = useState<any>([]);
    const [storedNotes, setNotes] = useState<Notes>([]);

    const [editNote, setEditNote] = useState<{
        index: number;
        note: string;
        noteInfo: string;
    }>();

    const {
        download: downloadClaimData,
        load: loadClaimDetails,
        loading: {
            loading: fetchingClaimDetails,
            downloading,
            downloadingClaimDetails,
            downloadingClaimInvoice,
            editingNotes,
            uploadingAttachments,
            downloadingClaimAttachment,
        },
        list: claimDetails,
        error: { error: claimDetailsError },
        downloadClaimDetails,
        downloadClaimInvoice,
        downloadClaimAttachment,
        editNotes,
        uploadAttachments,
    }: any = useGetClaimById();

    const retryCallbackRef =
        useRef<(shouldRetry: boolean) => void | null>(null);

    const { scan, loading: scanning } = useScanner();

    useLayoutEffect(() => {
        if (!claimId) return;
        loadClaimDetails(claimId);
    }, [claimId]);

    useEffect(() => {
        // If it's not an edit claim, return

        if (
            !!claimDetails?.data?.notes &&
            isArrayWithContent(claimDetails?.data?.notes)
        ) {
            setNotes(claimDetails?.data?.notes);
        }

        if (
            !!claimDetails?.data?.attachments &&
            isArrayWithContent(claimDetails?.data?.attachments)
        ) {
            setStoredAttachments(claimDetails?.data?.attachments);
        }
    }, [claimDetails?.data?.notes, claimDetails?.data?.attachments]);

    useEffect(() => {
        if (isArrayWithContent(files)) {
            const newDescriptions = files.map((_, index) => {
                return descriptions?.[index] || "";
            });
            setDescriptions(newDescriptions);
        }
    }, [files]);

    /**
     * Memoize data response
     */
    const data = useMemo(() => {
        if (!claimDetails?.data) return {};
        return claimDetails?.data;
    }, [claimDetails?.data]);

    /**
     * Handle upload
     */
    const handleUpload = async (files: Array<any>) => {
        const mappedAttachments =
            !files || !isArrayWithContent(files)
                ? []
                : files.map(file => {
                      return {
                          claimAttachmentId: file?.claimAttachmentId,
                          fileKey: file?.fileKey,
                          fileDescription: file?.fileDescription,
                          fileName: file?.fileName,
                      };
                  });

        const mappedNotes = storedNotes.map(note => {
            return {
                note: note.note,
                noteInfo: note.noteInfo,
                claimNoteId: note?.claimNoteId || undefined,
            };
        });

        uploadAttachments(claimId, [
            ...mappedAttachments,
            ...storedAttachments,
        ]).then(attachmentsRes => {
            if (isSuccessfulCall(attachmentsRes?.status)) {
                editNotes(claimId, mappedNotes).then(notesRes => {
                    if (isSuccessfulCall(notesRes?.status)) {
                        navigate(`/claims/claim-details/${claimId}/`);
                    }
                });
            }
        });
    };

    const handleComplete = (complete: Array<any>) => {
        // Handle the completed files
        handleUpload(complete);
    };

    const handleRetry = (
        incomplete: Array<any>,
        retryCallback: (shouldRetry: boolean) => void,
    ) => {
        setFailedFiles(incomplete);
        (retryCallbackRef.current as any) = retryCallback;
    };

    const handleModalConfirm = (shouldRetry: boolean) => {
        setFailedFiles([]);
        if (retryCallbackRef.current) {
            retryCallbackRef.current(shouldRetry);
        }
    };

    /**
     * On submit
     */
    const onSubmit = () => {
        if (isEditClaim) {
            const mapAttachmentsWithDescriptions = files.map(file => {
                return {
                    file,
                    fileDescription: descriptions[files.indexOf(file)],
                };
            });

            scan(
                mapAttachmentsWithDescriptions,
                setFailedFiles,
                handleComplete,
                handleRetry,
            );
        } else {
            data?.priceCorrection?.priceCorrectionStatus
                ? navigate(`/dashboard/price-correction-claims/`)
                : navigate(`/dashboard/claims`);
        }
    };

    /**
     *
     * @param index number
     * @param value string
     */
    const onDescriptionChange = (
        type: "delete" | "edit",
        index: number,
        value: string,
        attachmentsType?: "stored" | "newAttachments",
    ) => {
        if (attachmentsType === "newAttachments") {
            if (type === "edit") {
                const newDescriptions = [...descriptions];
                newDescriptions[index] = value;
                setDescriptions(newDescriptions);
            } else if (type === "delete") {
                const newDescriptions = [...descriptions];
                newDescriptions.splice(index, 1);
                setDescriptions(newDescriptions);
            }
        } else {
            if (type === "edit") {
                const newDescriptions = [...storedAttachments];
                newDescriptions[index].fileDescription = value;
                setStoredAttachments(newDescriptions);
            } else if (type === "delete") {
                const newDescriptions = [...storedAttachments];
                newDescriptions.splice(index, 1);
                setStoredAttachments(newDescriptions);
            }
        }
    };

    /**
     * On attachment remove
     */
    const onAttachmentRemove = (
        index: number,
        attachmentsType: "stored" | "newAttachments",
    ) => {
        if (attachmentsType === "newAttachments") {
            uploadFiles(prev => prev.filter((_, idx) => idx !== index));
        } else {
            setStoredAttachments(prev =>
                prev.filter((_, idx) => idx !== index),
            );
        }
        onDescriptionChange("delete", index, "", attachmentsType);
    };

    const disabled = useMemo(() => {
        if (!isEditClaim) return false;
        const filesHasNoDescription =
            storedAttachments.some(item => !item.fileDescription) ||
            descriptions.some(item => !item);
        return (
            editingNotes ||
            uploadingAttachments ||
            editNote?.index !== undefined ||
            filesHasNoDescription ||
            scanning
        );
    }, [
        editNote?.index,
        editingNotes,
        isEditClaim,
        uploadingAttachments,
        storedAttachments,
        descriptions,
        scanning,
    ]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Dialog
                open={!!failedFiles?.length}
                id={`${id}-dialog-confirm-cancel`}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Following files failed malware scanning, you can retry
                        scanning these files or opt to proceed uploading only
                        those files that passed the malware scan
                        {failedFiles.map((file, idx) => (
                            <Typography key={idx} variant="body2" ml={1}>
                                {file?.fileName}
                            </Typography>
                        ))}
                    </Typography>
                }
                primaryButton={{
                    text: "Retry",
                    action: () => {
                        handleModalConfirm(true);
                        setFailedFiles([]);
                    },
                    loading: uploadingAttachments,
                }}
                secondaryButton={{
                    text: "Proceed with upload",
                    action: () => {
                        handleModalConfirm(false);
                    },
                    loading: uploadingAttachments,
                }}
            />

            <Breadcrumbs
                id={`claim-details-breadcrumbs`}
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
                subPage={isEditClaim ? "Edit claim" : "Claim details"}
            />
            <FlowLayout>
                <LoadingWrapper
                    fullHeight
                    id={`view-contract-loading`}
                    loading={fetchingClaimDetails}
                    error={claimDetailsError}
                    NoEmptyPage
                >
                    <Fragment>
                        <FlowAside>
                            <Stepper
                                id={`claim-details-stepper`}
                                title={
                                    isEditClaim ? "Edit claim" : "Claim details"
                                }
                                subTitle={data?.fileName}
                            />
                        </FlowAside>

                        <FlowBody>
                            <Fragment>
                                <FlowContent>
                                    <_ClaimDetails
                                        id={id}
                                        isEditClaim={isEditClaim}
                                        files={files}
                                        uploadFiles={uploadFiles}
                                        storedNotes={storedNotes}
                                        disabled={
                                            editingNotes || uploadingAttachments
                                        }
                                        setNotes={notes => setNotes(notes)}
                                        editNote={editNote}
                                        setEditNote={setEditNote}
                                        claimId={claimId}
                                        downloadClaimData={downloadClaimData}
                                        downloading={
                                            downloading ||
                                            downloadingClaimAttachment
                                        }
                                        downloadingClaimDetails={
                                            downloadingClaimDetails
                                        }
                                        downloadClaimDetails={
                                            downloadClaimDetails
                                        }
                                        downloadClaimInvoice={
                                            downloadClaimInvoice
                                        }
                                        downloadingClaimInvoice={
                                            downloadingClaimInvoice
                                        }
                                        onDescriptionChange={
                                            onDescriptionChange
                                        }
                                        onDownloadAttachment={idx =>
                                            downloadClaimAttachment(
                                                storedAttachments[idx]
                                                    ?.claimAttachmentId,
                                                storedAttachments[idx]
                                                    ?.fileName,
                                            )
                                        }
                                        descriptions={descriptions}
                                        storedAttachments={storedAttachments}
                                        onAttachmentRemove={onAttachmentRemove}
                                        {...data}
                                    />
                                </FlowContent>

                                <FlowFooter>
                                    <StepActions
                                        id={`claim-details-step-actions`}
                                        loading={
                                            editingNotes ||
                                            uploadingAttachments ||
                                            scanning
                                        }
                                        primaryButton={{
                                            text: isEditClaim
                                                ? "Submit"
                                                : "Return to overview",
                                            action: () => onSubmit(),
                                            isSubmitButton: true,

                                            disabled: disabled,
                                        }}
                                        tertiaryButton={{
                                            text: "Cancel",
                                            action: () => {
                                                navigate("/dashboard/claims");
                                                setEditNote(undefined);
                                                setNotes([]);
                                                uploadFiles([]);
                                            },
                                            hidden:
                                                !isEditClaim ||
                                                !!data?.priceCorrection
                                                    ?.priceCorrectionStatus,
                                        }}
                                    />
                                </FlowFooter>
                            </Fragment>
                        </FlowBody>
                    </Fragment>
                </LoadingWrapper>
            </FlowLayout>
        </Fragment>
    );
};

export default ClaimDetails;
