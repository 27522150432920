// Icons
// Libs
import GridOn from "@mui/icons-material/GridOn";
import { Typography } from "@mui/material";
import { navigate } from "gatsby";
import { useResetRecoilState } from "recoil";
import React, {
    Fragment,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";

// Own component
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    FlowLayout,
    FlowAside,
    FlowBody,
    FlowContent,
    FlowFooter,
    LoadingWrapper,
    Dialog,
} from "@components";
import { OutcomeOverview } from "@containers";

// Hooks
import {
    useFindStep,
    useOrders,
    useGetVariables,
    useCreateOutcome,
    useScanner,
} from "@hooks";

// Types
import type { Location } from "@types";

// Atoms
import {
    editOrderStepsState,
    linkOrderStepsState,
    viewOrderStepsState,
    addOutcomeStepsState,
    outcomeDetailsState,
    contractSelectionState,
} from "@atoms";

// Utils
import {
    constructQueryString,
    isArrayWithContent,
    isSuccessfulCall,
} from "@utils";

/**
 * Props type
 */
interface Props {
    children: React.ReactNode;
    location: Location;
    orderId: string;
}

type OutcomeVariables = {
    inputVariableName: string;
    type: string;
    value: string;
};

/**
 * Create contract layout
 */
const OrderLayout = ({ location, children, orderId }: Props) => {
    const [failedFiles, setFailedFiles] = useState<
        {
            fileData: string;
            fileName: string;
            fileType: "DEFAULT" | "EXCELFILE";
            index: number;
        }[]
    >([]);

    //Via this ref we can save the state of each page via stepper (higher order)
    const ref = useRef<any>();

    const retryCallbackRef =
        useRef<(shouldRetry: boolean) => void | null>(null);

    const [isOutcomeCreated, setOutcomeStatus] = useState(false);

    /**
     * Flow check
     */
    const isEditOrder = useMemo(() => {
        if (!location) return;
        return location.pathname.includes("edit-order");
    }, [location]);

    const isAddOutcome = useMemo(() => {
        if (!location) return;
        return location.pathname.includes("add-outcome");
    }, [location]);

    const isViewOrder = useMemo(() => {
        if (!location && isEditOrder) return;
        return location.pathname.includes("view-order");
    }, [location]);

    const isLinkOrder = useMemo(() => {
        if (!location && isEditOrder) return;
        return location.pathname.includes("link-order");
    }, [location]);

    /**
     * API
     */

    // Fetch order by ID
    const { fetchOrder, updateOrder, linkOrder, response, loading } =
        useOrders("orders");

    // Get variables
    const {
        getVariables,
        loading: loadingVariables,
        response: variables,
    } = useGetVariables();

    // Create outcome
    const {
        loading: { createOutcomeLoading },
        createOutcome,
    } = useCreateOutcome();

    // Scanner
    const { scan, loading: scanning } = useScanner();

    /**
     * Reset
     */
    const resetOutcomeDetailsAtom = useResetRecoilState(outcomeDetailsState);
    const resetContractSelectionAtom = useResetRecoilState(
        contractSelectionState,
    );

    const reset = () => {
        setOutcomeStatus(false);
        resetOutcomeDetailsAtom();
        resetContractSelectionAtom();
    };

    useLayoutEffect(() => {
        if (!orderId) return;
        fetchOrder(orderId);
        reset();
    }, [orderId]);

    useEffect(() => {
        if (
            !!isAddOutcome &&
            response?.order?.data.orderId &&
            response?.order?.data?.contract?.id
        ) {
            const order = response?.order?.data;
            const params = constructQueryString(
                {
                    contractId: order?.contract?.id,
                    orderId: order?.orderId,
                },
                true,
            );
            getVariables(params);
        }
    }, [response?.order?.data]);

    //Steps hooks
    const { currentStep } = useFindStep(
        location,
        isEditOrder
            ? editOrderStepsState
            : isViewOrder
              ? viewOrderStepsState
              : isAddOutcome
                ? addOutcomeStepsState
                : linkOrderStepsState,
    );

    /**
     * Handle upload
     */
    const handleUpload = async (files?: Array<any>) => {
        const data: {
            outcomeVariables: OutcomeVariables[];
            evidences: string[];
            reason: string;
            notes: Array<{
                note: string;
                noteInfo: string;
            }>;
        } = ref.current.getStateData(); // get the data of the state

        const mappedAttachments =
            !files || !isArrayWithContent(files)
                ? []
                : files.map(file => {
                      return {
                          fileKey: file?.fileKey,
                          fileDescription: file?.fileDescription,
                          fileName: file?.fileName,
                      };
                  });

        const mappedNotes = isArrayWithContent(data?.notes)
            ? data?.notes.map(note => {
                  return {
                      note: note.note,
                      noteInfo: note.noteInfo,
                  };
              })
            : [];

        const mapper = {
            contractId: response?.order?.data?.contract?.id,
            orderId: orderId,
            outcomeVariables: data?.outcomeVariables,
            evidences: data.evidences,
            reason: data.reason,
            attachments: mappedAttachments,
            notes: mappedNotes,
        };

        createOutcome(mapper).then(response => {
            if (!!response && isSuccessfulCall(response?.status)) {
                setOutcomeStatus(true);
            }
        });
        return;
    };

    const handleComplete = (complete: Array<any>) => {
        // Handle the completed files
        handleUpload(complete);
    };

    const handleRetry = (
        incomplete: Array<any>,
        retryCallback: (shouldRetry: boolean) => void,
    ) => {
        setFailedFiles(incomplete);
        (retryCallbackRef.current as any) = retryCallback;
    };

    /**
     * Save handler
     */
    const onNextClick = () => {
        if (isViewOrder || isOutcomeCreated)
            return navigate("/dashboard/orders/");
        if (isEditOrder) {
            const data = ref.current.onSave();
            updateOrder(orderId, data).then(res => {
                if (
                    !!res &&
                    isSuccessfulCall(res?.status) &&
                    res?.data?.orderId
                ) {
                    navigate(`/order/view-order/${res.data.orderId}/`);
                }
            });
        }

        if (isAddOutcome) {
            const data = ref.current.getStateData();

            if (isArrayWithContent(data?.attachments)) {
                const mapAttachmentsWithDescriptions = data?.attachments.map(
                    file => {
                        return {
                            file,
                            fileDescription:
                                data?.descriptions[
                                    data?.attachments.indexOf(file)
                                ],
                        };
                    },
                );
                scan(
                    mapAttachmentsWithDescriptions,
                    setFailedFiles,
                    handleComplete,
                    handleRetry,
                );
            } else {
                handleUpload();
            }
        }
        if (isLinkOrder) {
            const contract = ref.current.onSubmit();

            linkOrder(orderId, contract.id).then(response => {
                if (!!response && isSuccessfulCall(response.status)) {
                    reset();
                    navigate("/dashboard/orders/");
                }
            });
            return;
        }
    };

    const isLoading = useMemo(() => {
        if (!isAddOutcome) {
            return loading?.fetchingOrder;
        } else {
            return loading?.fetchingOrder || loadingVariables;
        }
    }, [isAddOutcome, loading, loadingVariables]);

    const handleModalConfirm = (shouldRetry: boolean) => {
        setFailedFiles([]);
        if (retryCallbackRef.current) {
            retryCallbackRef.current(shouldRetry);
        }
    };

    /**
     * Render
     */
    return (
        <Fragment>
            <Dialog
                open={!!failedFiles?.length}
                id={`dialog-confirm-cancel-or-proceed`}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Following files failed malware scanning, you can retry
                        scanning these files or opt to proceed uploading only
                        those files that passed the malware scan
                        {failedFiles.map((file, idx) => (
                            <Typography key={idx} variant="body2" ml={1}>
                                {file?.fileName}
                            </Typography>
                        ))}
                    </Typography>
                }
                primaryButton={{
                    text: "Retry",
                    action: () => {
                        handleModalConfirm(true);
                        setFailedFiles([]);
                    },
                    loading: scanning,
                }}
                secondaryButton={{
                    text: "Proceed with upload",
                    action: () => {
                        handleModalConfirm(false);
                    },
                    loading: false,
                }}
            />

            <Breadcrumbs
                id={`orders-breadcrumb`}
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
                subPage={
                    isEditOrder
                        ? "Edit order"
                        : isViewOrder
                          ? "View order"
                          : isAddOutcome
                            ? "Add outcome"
                            : "Link order"
                }
            />

            <FlowLayout>
                <FlowAside>
                    <Stepper
                        id={`orders-stepper`}
                        title={`${currentStep?.title}`}
                        subTitle={`${
                            (isEditOrder || isViewOrder) && !isLoading
                                ? response?.order?.data?.salessystemOrderId
                                : ""
                        }`}
                    />
                </FlowAside>

                <FlowBody>
                    <Fragment>
                        <FlowContent>
                            <LoadingWrapper
                                loading={isLoading}
                                fullHeight
                                id="orders"
                                NoEmptyPage
                            >
                                {isOutcomeCreated ? (
                                    <OutcomeOverview />
                                ) : (
                                    React.Children.map(
                                        children as React.ReactElement,
                                        (child: React.ReactElement) =>
                                            React.cloneElement(child, {
                                                location,
                                                ref,
                                                order: response?.order?.data,
                                                variables:
                                                    variables?.data?.records,
                                                disabled:
                                                    loading?.updatingOrder ||
                                                    createOutcomeLoading,
                                                id: "orders",
                                                loading: loadingVariables,
                                            }),
                                    )
                                )}
                            </LoadingWrapper>
                        </FlowContent>

                        <FlowFooter>
                            <StepActions
                                id={`orders-step-actions`}
                                hidden={isLoading}
                                loading={
                                    createOutcomeLoading ||
                                    loading?.updatingOrder ||
                                    loading?.linkingOrder
                                }
                                tertiaryButton={{
                                    text: "Cancel",
                                    action: () =>
                                        navigate("/dashboard/orders/"),

                                    hidden: isOutcomeCreated || isViewOrder,
                                }}
                                primaryButton={{
                                    text: isEditOrder
                                        ? "Save status"
                                        : isViewOrder
                                          ? "Return to overview"
                                          : isAddOutcome && !isOutcomeCreated
                                            ? "Process outcome"
                                            : isAddOutcome && isOutcomeCreated
                                              ? "Return to overview"
                                              : "Link order",
                                    action: onNextClick,
                                    disabled:
                                        !currentStep.isPrepared ||
                                        loading?.updatingOrder ||
                                        loading.fetchingOrder ||
                                        loading.linkingOrder ||
                                        createOutcomeLoading ||
                                        scanning,
                                    isSubmitButton: true,
                                    loading: scanning || createOutcomeLoading,
                                }}
                            />
                        </FlowFooter>
                    </Fragment>
                </FlowBody>
            </FlowLayout>
        </Fragment>
    );
};

export default OrderLayout;
