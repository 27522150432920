import { atom } from "recoil";

/**
 * Outcome details
 */
export const outcomeDetailsState = atom({
    key: "outcomeDetailsState",
    default: {
        infusionDate: undefined,
        variables: [],
        evidences: [] as string[],
        reason: undefined,
        attachments: [],
        notes: [],
        descriptions: [],
    },
});
