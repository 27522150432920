// Lib
import { FormHelperText, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import React, { Fragment } from "react";

// Types
import {
    ContractCountries as CountryListType,
    AccountTypes as AccountTypesType,
    CreateAccountForm as CreateAccountFormType,
    Account,
    BasicRegionType,
} from "@types";

// Own components
import { Select, Autocomplete, NumberInput } from "@components";

// Style
import * as style from "./style.module.scss";

/**
 * Props type
 */
interface Props {
    id: string;
    values: CreateAccountFormType;
    countries: CountryListType;
    countryOfSaleList: CountryListType;
    accountTypes: AccountTypesType;
    accountStatusList: any;
    errors?: any;
    touched?: any;
    setFieldValue: (type: string, value: any) => void;
    handleChange: (e: React.ChangeEvent<any>) => void;
    handleBlur?: (e: React.ChangeEvent<any>) => void;
    onBlur: (e: any) => void;
    disabled?: boolean;
    partners?: {
        loading: boolean;
        data: Array<Account>;
        onSearch: (query: string) => void;
    };
    setTouched?: (touched: any) => void;
    regionTypeRegions?: {
        data: BasicRegionType[];
        fetching: boolean;
    };
    cmuTypeRegions?: {
        data: BasicRegionType[];
        fetching: boolean;
    };
    subRegion1TypeRegion?: {
        data: BasicRegionType[];
        fetching: boolean;
    };
    subRegion2TypeRegion?: {
        data: BasicRegionType[];
        fetching: boolean;
    };
    canAddRegionAndCods?: boolean;
}
/**
 * Create Partner
 */
const CreatePartnerForm: React.FC<Props> = ({
    id,
    values,
    errors,
    touched,
    countries,
    accountTypes,
    accountStatusList,
    handleChange,
    onBlur,
    setFieldValue,
    disabled,
    countryOfSaleList,
    handleBlur,
    partners,
    setTouched,
    regionTypeRegions,
    cmuTypeRegions,
    subRegion1TypeRegion,
    subRegion2TypeRegion,
    canAddRegionAndCods,
}) => {
    /**
     *
     * @param error boolean indicating if there is an error
     * @param field string representing the field name
     * @param max number representing the maximum length
     * @returns string | undefined
     */
    const errorTransForm = (field: string, max: number) => {
        if (!field || !max) return;
        return `${field} has a maximum length of ${max} characters, please update the field value accordingly.`;
    };

    /**
     * Render
     */
    return (
        <form noValidate onBlur={onBlur} className={style.form}>
            <Grid container>
                <Box mb={3}>
                    <Typography variant="h3">Partner address</Typography>
                </Box>
                <Grid
                    item
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 0, md: 4 }}
                >
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            error={
                                !!errors.homeCountry && !!touched.homeCountry
                            }
                            shrink
                        >
                            {"Country (*)"}
                        </InputLabel>
                        <Select
                            id={`${id}-home-country`}
                            name="homeCountry"
                            onChange={(_, index) =>
                                setFieldValue(
                                    "homeCountry",
                                    countries?.data[index],
                                )
                            }
                            error={
                                !!errors.homeCountry && !!touched.homeCountry
                            }
                            menuItemLabel="country"
                            menuItemId="countryIsoCode"
                            list={countries?.data}
                            loading={countries?.loading}
                            value={values?.homeCountry}
                            disabled={disabled}
                            scrollable
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            shrink
                            error={
                                !!errors.accountAddress &&
                                touched.accountAddress
                            }
                        >
                            {"Street address (*)"}
                        </InputLabel>

                        <TextField
                            fullWidth
                            name="accountAddress"
                            id={`${id}-address`}
                            autoComplete="off"
                            value={values?.accountAddress}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                            error={
                                !!errors.accountAddress &&
                                !!touched.accountAddress
                            }
                            disabled={disabled}
                        />
                        {values?.accountAddress?.length > 128 && (
                            <FormHelperText error>
                                {errorTransForm("Street address", 128)}
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <InputLabel
                            shrink
                            error={
                                !!errors.accountPostalCode &&
                                touched.accountPostalCode
                            }
                        >
                            {"Postal code or ZIP code (*)"}
                        </InputLabel>

                        <TextField
                            fullWidth
                            name="accountPostalCode"
                            autoComplete="off"
                            id={`${id}-postal-code`}
                            value={values?.accountPostalCode}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                            error={
                                !!errors.accountPostalCode &&
                                !!touched.accountPostalCode
                            }
                            disabled={disabled}
                        />
                        {values?.accountPostalCode?.length > 32 && (
                            <FormHelperText error>
                                {errorTransForm("Postal code", 32)}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            shrink
                            error={!!errors.accountCity && touched.accountCity}
                        >
                            {"City (*)"}
                        </InputLabel>
                        <TextField
                            fullWidth
                            name="accountCity"
                            autoComplete="off"
                            id={`${id}-city`}
                            value={values?.accountCity}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                            error={
                                !!errors.accountCity && !!touched.accountCity
                            }
                            disabled={disabled}
                        />

                        {values?.accountCity?.length > 64 && (
                            <FormHelperText error>
                                {errorTransForm("City", 64)}
                            </FormHelperText>
                        )}
                    </Grid>
                </Grid>
                <Box mb={3} mt={5}>
                    <Typography variant="h3">Account details</Typography>
                </Box>
                <Grid
                    item
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 0, md: 4 }}
                >
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            shrink
                            error={!!errors.accountName && touched.accountName}
                        >
                            {"Account name (*)"}
                        </InputLabel>

                        <TextField
                            fullWidth
                            name="accountName"
                            id={`${id}-accountName`}
                            autoComplete="off"
                            value={values?.accountName}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                            error={!!errors.accountName && touched.accountName}
                            disabled={disabled}
                        />
                        {values?.accountName?.length > 400 && (
                            <FormHelperText error>
                                {errorTransForm("Account name", 400)}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            error={
                                !!errors.accountType && !!touched.accountType
                            }
                            shrink
                        >
                            {"Account type (*)"}
                        </InputLabel>

                        <Select
                            id={`${id}-account-type-list`}
                            name="accountType"
                            onChange={(_, index) =>
                                setFieldValue(
                                    "accountType",
                                    accountTypes?.data[index],
                                )
                            }
                            error={
                                !!errors.accountType && !!touched.accountType
                            }
                            menuItemLabel={"accountTypeName"}
                            menuItemId="accountTypeId"
                            list={accountTypes?.data}
                            loading={accountTypes?.loading}
                            value={values?.accountType}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            error={
                                !!errors.knowyoursupplierid &&
                                !!touched.knowyoursupplierid
                            }
                            shrink
                        >
                            {`Know Your Supplier ID ${
                                values?.sapAccountCode ? "" : "(*)"
                            }`}
                        </InputLabel>

                        <TextField
                            fullWidth
                            name="knowyoursupplierid"
                            id={`${id}-knowyoursupplierid`}
                            autoComplete="off"
                            value={values?.knowyoursupplierid}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                            error={
                                !!errors.knowyoursupplierid &&
                                !!touched.knowyoursupplierid
                            }
                        />
                        {values?.knowyoursupplierid?.length > 25 && (
                            <FormHelperText error>
                                {errorTransForm("Know Your Supplier ID", 25)}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            error={
                                !!errors.accountStatus &&
                                !!touched.accountStatus
                            }
                            shrink
                        >
                            {"Status (*)"}
                        </InputLabel>
                        <Select
                            id={`${id}-account-status`}
                            name="accountStatus"
                            menuItemLabel="label"
                            menuItemId="value"
                            onChange={(_, index) => {
                                setFieldValue(
                                    "accountStatus",
                                    accountStatusList[index],
                                );
                            }}
                            error={
                                !!errors.accountStatus &&
                                !!touched.accountStatus
                            }
                            list={accountStatusList}
                            loading={false}
                            value={values?.accountStatus}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            error={
                                !!errors.countryIsoCode &&
                                !!touched.countryIsoCode
                            }
                            shrink
                        >
                            {"Country of sale (*)"}
                        </InputLabel>
                        <Select
                            id={`${id}-country-iso-code`}
                            name="countryIsoCode"
                            onChange={(_, index) =>
                                setFieldValue(
                                    "countryIsoCode",
                                    countryOfSaleList?.data[index],
                                )
                            }
                            error={
                                !!errors.countryIsoCode &&
                                !!touched.countryIsoCode
                            }
                            menuItemLabel="country"
                            menuItemId="countryIsoCode"
                            list={countryOfSaleList?.data}
                            loading={countryOfSaleList?.loading}
                            value={values?.countryIsoCode}
                            disabled={disabled}
                        />
                    </Grid>
                    {values?.sapAccountCode && (
                        <Grid item xs={12} md={6}>
                            <InputLabel
                                shrink
                                error={
                                    !!errors.sapAccountCode &&
                                    touched.sapAccountCode
                                }
                            >
                                {"SAP Code (*)"}
                            </InputLabel>
                            <TextField
                                fullWidth
                                name="sapAccountCode"
                                autoComplete="off"
                                id={`${id}-sapAccountCode`}
                                value={values?.sapAccountCode}
                                onChange={handleChange}
                                variant="outlined"
                                size="small"
                                error={
                                    !!errors.sapAccountCode &&
                                    touched.sapAccountCode
                                }
                                disabled={disabled}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            shrink
                            error={!!errors.bankType && touched.bankType}
                        >
                            {"Bank type"}
                        </InputLabel>
                        <NumberInput
                            name="bankType"
                            variant="outlined"
                            fullWidth
                            value={values?.bankType}
                            id={`${id}-bankType`}
                            autoComplete="off"
                            error={!!errors.bankType && touched.bankType}
                            onChange={handleChange}
                            size="small"
                        />

                        {!!values?.bankType &&
                            values?.bankType?.length > 50 && (
                                <FormHelperText error>
                                    {errorTransForm("Bank type", 50)}
                                </FormHelperText>
                            )}
                    </Grid>
                    {canAddRegionAndCods && (
                        <Grid item xs={12} md={6}>
                            <InputLabel
                                shrink
                                error={!!errors.codsId && touched.codsId}
                            >
                                {"Cods ID"}
                            </InputLabel>

                            <TextField
                                fullWidth
                                name="codsId"
                                autoComplete="off"
                                id={`${id}-codsId`}
                                value={values?.codsId}
                                onChange={handleChange}
                                variant="outlined"
                                size="small"
                                error={!!errors.codsId && touched.codsId}
                            />
                        </Grid>
                    )}
                    {canAddRegionAndCods && (
                        <Fragment>
                            <Grid item xs={12} md={6}>
                                <InputLabel id={`${id}-partner-label`} shrink>
                                    {"Trust account"}
                                </InputLabel>

                                <Autocomplete
                                    id={`${id}-trustAccountCode-input`}
                                    data={partners?.data || []}
                                    loading={partners?.loading || false}
                                    name="trustAccountCode"
                                    onBlur={event => {
                                        handleBlur && handleBlur(event);
                                    }}
                                    fullWidth
                                    clearIfNoValueSelected
                                    value={
                                        values.trustAccountCode?.accountId
                                            ? values.trustAccountCode
                                            : ""
                                    }
                                    size="small"
                                    keysToMatch={[
                                        "accountName",
                                        "accountCity",
                                        "sapAccountCode",
                                        "knowyoursupplierid",
                                    ]}
                                    onChange={value => {
                                        // set blur when user click on (X) delete the current partner
                                        if (
                                            setTouched &&
                                            !touched["trustAccountCode"]
                                        ) {
                                            setTouched({
                                                ...touched,
                                                trustAccountCode: true,
                                            });
                                        }

                                        setFieldValue(
                                            "trustAccountCode",
                                            value,
                                        );
                                    }}
                                    onSearch={(query: string) => {
                                        partners?.onSearch(query);
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    shrink
                                    id={`${id}-cmu-region-code-input`}
                                >
                                    {"CMU region"}
                                </InputLabel>
                                <Select
                                    value={values?.cmuRegionCode}
                                    id={`${id}-cmu-region-code-list`}
                                    onChange={(_, index) =>
                                        setFieldValue(
                                            "cmuRegionCode",
                                            cmuTypeRegions?.data[index],
                                        )
                                    }
                                    list={cmuTypeRegions?.data || []}
                                    menuItemId="regionId"
                                    menuItemLabel={[
                                        "regionName",
                                        "regionCode",
                                        "type",
                                    ]}
                                    displayEmpty
                                    loading={cmuTypeRegions?.fetching}
                                    name="cmuRegionCode"
                                    onBlur={event =>
                                        handleBlur && handleBlur(event)
                                    }
                                    placeHolder={{
                                        title: "Not specified",
                                        value: "",
                                        selectable: true,
                                    }}
                                    scrollable
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    shrink
                                    id={`${id}-region-code-input`}
                                >
                                    {"Region"}
                                </InputLabel>
                                <Select
                                    value={values?.regionCode}
                                    id={`${id}-cmu-region-code-list`}
                                    onChange={(_, index) =>
                                        setFieldValue(
                                            "regionCode",
                                            regionTypeRegions?.data[index],
                                        )
                                    }
                                    list={regionTypeRegions?.data || []}
                                    menuItemId="regionId"
                                    menuItemLabel={[
                                        "regionName",
                                        "regionCode",
                                        "type",
                                    ]}
                                    loading={regionTypeRegions?.fetching}
                                    displayEmpty
                                    name="regionCode"
                                    onBlur={event =>
                                        handleBlur && handleBlur(event)
                                    }
                                    placeHolder={{
                                        title: "Not specified",
                                        value: "",
                                        selectable: true,
                                    }}
                                    scrollable
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    shrink
                                    id={`${id}-region-code-1-input`}
                                >
                                    {"Sub region 1"}
                                </InputLabel>
                                <Select
                                    value={values?.regionCode1}
                                    id={`${id}-sub-region-code-1-list`}
                                    onChange={(_, index) =>
                                        setFieldValue(
                                            "regionCode1",
                                            subRegion1TypeRegion?.data[index],
                                        )
                                    }
                                    list={subRegion1TypeRegion?.data || []}
                                    menuItemId="regionId"
                                    menuItemLabel={[
                                        "regionName",
                                        "regionCode",
                                        "type",
                                    ]}
                                    loading={subRegion1TypeRegion?.fetching}
                                    displayEmpty
                                    name="regionCode1"
                                    onBlur={event =>
                                        handleBlur && handleBlur(event)
                                    }
                                    placeHolder={{
                                        title: "Not specified",
                                        value: "",
                                        selectable: true,
                                    }}
                                    scrollable
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    shrink
                                    id={`${id}-region-code-2-input`}
                                >
                                    {"Sub region 2"}
                                </InputLabel>
                                <Select
                                    value={values?.regionCode2}
                                    id={`${id}-sub-region-code-2-list`}
                                    onChange={(_, index) =>
                                        setFieldValue(
                                            "regionCode2",
                                            subRegion2TypeRegion?.data[index],
                                        )
                                    }
                                    list={subRegion2TypeRegion?.data || []}
                                    menuItemId="regionId"
                                    loading={subRegion2TypeRegion?.fetching}
                                    menuItemLabel={[
                                        "regionName",
                                        "regionCode",
                                        "type",
                                    ]}
                                    displayEmpty
                                    name="regionCode2"
                                    onBlur={event =>
                                        handleBlur && handleBlur(event)
                                    }
                                    placeHolder={{
                                        title: "Not specified",
                                        value: "",
                                        selectable: true,
                                    }}
                                    scrollable
                                />
                            </Grid>
                        </Fragment>
                    )}
                </Grid>
            </Grid>
        </form>
    );
};
export default React.memo(CreatePartnerForm);
