// Lib
import { Box, Grid, Switch, Typography } from "@mui/material";
import { navigate } from "gatsby";
import React, {
    Fragment,
    useState,
    useMemo,
    useEffect,
    useRef,
    useImperativeHandle,
} from "react";

// Own components
import {
    SearchBar,
    Table,
    Dialog,
    ChangeInfusionDate,
    Modal,
    FieldRenderer,
} from "@components";

// Constants
import { filterByCountry, HEADERS, ROWRENDERERCONST } from "@constants";

// Hooks
import {
    useOrders,
    usePermission,
    useViewingOptions,
    useOrderStatuses,
    useEditInfusionDate,
    useSelectedCountry,
    useEditPOReceived,
} from "@hooks";

// Types
import { SearchFilters, Permissions } from "@types";

// Utils
import { constructQueryString, isSuccessfulCall } from "@utils";

type Order = {
    unlinkable: boolean;
    orderId: string;
    sapOrderNo: string;
    contractId: string;
    contractRef: string;
    contractName: string;
    salessystemOrderId: string;
    productName: string;
    extContractRef: string;
    treatmentSite: string;
    shipTo: string;
    orderStatus: string;
    infusionDate: string;
    orderDate: string;
    brandName: string;
    indicationName: string;
    sapSkuNo: string;
};

interface Props {
    onDataReady?: () => void;
}

interface OrdersRef {
    getRecordsCount: () => {
        itemCount: number | undefined;
    };
}

/**
 * Orders
 */
const Orders = React.forwardRef<OrdersRef, Props>(({ onDataReady }, ref) => {
    const [orderToUnlink, setOrder] = useState(undefined);
    const [orderToChangeInfusionDate, setOrderToChangeInfusionDate] = useState<
        Order | undefined
    >(undefined);

    const [orderToChangePOStatus, setOrderToChangePOStatus] = useState<
        | {
              orderId: string;
              poReceived: boolean;
              salessystemOrderId: string;
          }
        | undefined
    >(undefined);

    /**
     * Search Ref
     */

    const searchRef = useRef<any>();

    /**
     * Hooks
     */
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        ROWRENDERERCONST.ORDERS,
    );

    // Get team iso code
    const { isGermanyTeam, isSpainTeam, countryIsoCode } = useSelectedCountry();

    /**
     * Permissions
     */
    const {
        hasPermissionToEditOrder,
        hasPermissionToCreateOrder,
        hasPermissionToEditOutcome,
        hasPermissionToReadOrder,
    }: Permissions = usePermission();

    /**
     * API
     */

    // Orders
    const { response, loading, search, reload, unlinkOrder }: any =
        useOrders("orders");

    const {
        loading: editingInfusionDate,
        response: editInfusionDateResponse,
        editInfusionDate,
    }: any = useEditInfusionDate();

    const { loading: editingPOReceived, editPOReceived }: any =
        useEditPOReceived();

    // order statuses
    const { loading: fetchingOrderStatuses, list: orderStatuses } =
        useOrderStatuses();

    useEffect(() => {
        if (!orderToChangeInfusionDate) return;
        if (isSuccessfulCall(editInfusionDateResponse?.status)) {
            setOrderToChangeInfusionDate(undefined);
            searchRef?.current?.onSearchReset();
            reload();
        }
    }, [editInfusionDateResponse]);

    useEffect(() => {
        if (response?.orders?.data?.records && !fetchingOrderStatuses) {
            onDataReady?.();
        }
    }, [response?.orders?.data?.records, fetchingOrderStatuses]);

    useImperativeHandle(
        ref,
        () => ({
            getRecordsCount() {
                return {
                    itemCount: response?.orders?.data?.itemCount,
                };
            },
        }),
        [response?.orders?.data?.records], // Add dependency array here
    );

    /**
     * Search handler
     */
    const onSearch = (searchFilters: SearchFilters) => {
        const hasFilters = Object.values(searchFilters).some(
            filter => filter.length,
        );

        const params = constructQueryString(searchFilters);

        if (hasFilters) {
            search(params);
        } else reload();
    };

    /**
     * Unlink orders
     */
    const unlink = () => {
        unlinkOrder(orderToUnlink).then(res => {
            if (isSuccessfulCall(res?.status)) {
                setOrder(undefined);
                searchRef?.current?.onSearchReset();
            }
        });
    };

    /**
     * Status mapper
     */
    const mapOrderStatuses = useMemo(() => {
        if (!orderStatuses?.data?.records) return [];
        return orderStatuses?.data?.records?.map(status => {
            return {
                label: status?.label,
                value: status?.id,
            };
        });
    }, [orderStatuses?.data?.records]);

    /**
     * Submit PO Received
     */
    const submitPOReceived = () => {
        editPOReceived(
            orderToChangePOStatus?.orderId,
            orderToChangePOStatus?.poReceived,
        ).then(res => {
            if (isSuccessfulCall(res?.status)) {
                setOrderToChangePOStatus(undefined);
                reload();
            }
        });
    };

    /**
     * Render
     */
    return (
        <Fragment>
            <Modal
                open={!!orderToChangePOStatus}
                onClose={() => setOrderToChangePOStatus(undefined)}
                title="PO Received"
                id={`po-received`}
                primaryButton={{
                    action: submitPOReceived,
                    text: "Save",
                    disabled: editingPOReceived,
                    loading: editingPOReceived,
                }}
                secondaryButton={{
                    action: () => setOrderToChangePOStatus(undefined),
                    text: "Cancel",
                }}
            >
                <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    alignItems={"flex-end"}
                >
                    <Grid item xs={6}>
                        <FieldRenderer
                            id={`po-received-salessystemOrderId`}
                            label="Cquence Id"
                            value={orderToChangePOStatus?.salessystemOrderId}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="caption2">
                                PO Received:
                            </Typography>
                            <Switch
                                disabled={editingPOReceived}
                                checked={orderToChangePOStatus?.poReceived}
                                onChange={() =>
                                    setOrderToChangePOStatus(prev => ({
                                        orderId: prev?.orderId || "",
                                        poReceived: !prev?.poReceived,
                                        salessystemOrderId:
                                            prev?.salessystemOrderId || "",
                                    }))
                                }
                                inputProps={{
                                    "aria-label": "controlled",
                                }}
                            />
                            <Typography variant="caption2">
                                {orderToChangePOStatus?.poReceived
                                    ? "Yes"
                                    : "No"}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Modal>
            <ChangeInfusionDate
                id={`change-infusion-date-modal`}
                open={!!orderToChangeInfusionDate}
                onClose={() => {
                    setOrderToChangeInfusionDate(undefined);
                }}
                title={
                    orderToChangeInfusionDate?.infusionDate
                        ? "Edit infusion date"
                        : "Add infusion date"
                }
                onSubmit={infusionDate => {
                    editInfusionDate(
                        orderToChangeInfusionDate?.infusionDate
                            ? "edit"
                            : "add",
                        orderToChangeInfusionDate?.orderId,
                        infusionDate,
                    );
                }}
                loading={editingInfusionDate}
                disabled={editingInfusionDate}
                salessystemOrderId={
                    orderToChangeInfusionDate?.salessystemOrderId || ""
                }
                orderInfusionDate={orderToChangeInfusionDate?.infusionDate}
            />

            <Dialog
                open={!!orderToUnlink}
                id={`orders-unlink`}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Are you sure you would like to unlink this order?
                    </Typography>
                }
                primaryButton={{
                    text: "Unlink",
                    action: unlink,
                    loading: loading.unlinkingOrder,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setOrder(undefined),
                }}
            />

            <Box mt={4} display="flex" justifyContent="flex-end">
                <SearchBar
                    id={`dashboard-orders-overview-search-bar`}
                    filterKey={ROWRENDERERCONST.ORDERS}
                    handleSearch={(filters: SearchFilters) => onSearch(filters)}
                    placeholder="Search by cquence Id, ship to, or SAP order number"
                    viewingOptions={viewingOptions}
                    setViewingOptions={setViewingOptions}
                    ref={searchRef}
                    hasPagination={true}
                    status={{
                        data: mapOrderStatuses,
                        loading: fetchingOrderStatuses,
                    }}
                />
            </Box>

            <Table
                id={`dashboard-orders-overview-list`}
                headers={filterByCountry(HEADERS.ORDERS, countryIsoCode)}
                rows={response?.orders?.data?.records}
                loading={loading?.fetchingOrders}
                type={ROWRENDERERCONST.ORDERS}
                permissions={{
                    hasPermissionToReadOrder,
                    hasPermissionToCreateOrder,
                    hasPermissionToEditOrder,
                    hasPermissionToEditOutcome,
                    canLinkWithoutInfusionDate: isGermanyTeam,
                    canChangePOStatus: isSpainTeam,
                }}
                callbacks={{
                    onLinkOrder: row => {
                        navigate(`/order/link-order/${row.orderId}/`);
                    },
                    onEditOrder: row => {
                        navigate(`/order/edit-order/${row.orderId}/`);
                    },
                    onViewOrder: row => {
                        navigate(`/order/view-order/${row.orderId}/`);
                    },
                    onAddOutcome: row =>
                        navigate(`/order/add-outcome/${row.orderId}/`),
                    onUnlinkOrder: row => {
                        setOrder(row.orderId);
                    },
                    onInfusionDateChange: item => {
                        setOrderToChangeInfusionDate(item);
                    },
                    onPOReceived: row => {
                        setOrderToChangePOStatus({
                            orderId: row.orderId,
                            poReceived: row.poReceived,
                            salessystemOrderId: row.salessystemOrderId,
                        });
                    },
                }}
                viewingOptions={viewingOptions}
                emptyMsg="No orders found"
                hasPagination={true}
                pagesCount={response?.orders?.data?.itemCount}
            />
        </Fragment>
    );
});
export default Orders;
