// Lib
import { Box, Typography } from "@mui/material";
import { navigate } from "gatsby";
import GridOn from "@mui/icons-material/GridOn";
import React, {
    Fragment,
    useEffect,
    useState,
    useMemo,
    useRef,
    useCallback,
} from "react";
import clonedeep from "lodash.clonedeep";

// Own components
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    FlowLayout,
    FlowAside,
    FlowBody,
    FlowContent,
    FlowFooter,
    Dialog,
} from "@components";

// Hooks
import {
    useFinance,
    useScanner,
    useSelectedCountry,
    useFindStep,
    useAtomsReset,
} from "@hooks";

// Atoms
import {
    generateVolumeBasedBpfStepsState,
    generatePerformanceBasedBpfStepsState,
    generateApheresisBasedBpfStepsState,
} from "@atoms";

// Types
import { Location } from "@types";

// Utils
import { isSuccessfulCall } from "@utils";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { createBPFMapper } from "@selectors";
/**
 * Props type
 */
interface Props {
    location: Location;
    children: React.ReactNode;
    type: "volume-based" | "performance-based" | "apheresis-based";
}

/**
 * Generate BPF
 */
const Layout = ({ type, location, children }: Props) => {
    const retryCallbackRef =
        useRef<(shouldRetry: boolean) => void | null>(null);

    // via this ref we can save the state of each page via stepper (higher order)
    const stepRef = useRef<any>();

    // Reset all atoms
    const mappedData = useRecoilValue(createBPFMapper);

    // Use the reset hook with the selector
    const { resetKey, reset } = useAtomsReset(createBPFMapper, true);

    /**
     * Team
     */
    const { isGermanyTeam } = useSelectedCountry();
    /**
     * Evidence state
     */

    // Failed files
    const [failedFiles, setFailedFiles] = React.useState<
        {
            fileData: string;
            fileName: string;
            fileType: "DEFAULT" | "EXCEL";
            index: number;
        }[]
    >([]);

    // Scanner
    const { scan, loading: scanning } = useScanner("DEFAULT");

    /**
     * Check flow type
     */
    const { isVolumeBased, isPerformanceBased, isApheresisBased } =
        useMemo(() => {
            if (!type) {
                return {
                    isVolumeBased: false,
                    isPerformanceBased: false,
                    isApheresisBased: false,
                };
            }
            return {
                isVolumeBased: type === "volume-based",
                isPerformanceBased: type === "performance-based",
                isApheresisBased: type === "apheresis-based",
            };
        }, [type]);

    /**
     * Map flow state
     */
    const flowState = useMemo(() => {
        return isVolumeBased
            ? generateVolumeBasedBpfStepsState
            : isPerformanceBased
              ? generatePerformanceBasedBpfStepsState
              : generateApheresisBasedBpfStepsState;
    }, [isVolumeBased, isPerformanceBased]);

    /**
     * Find current step
     */
    const { currentIndex: currentStepIndex } = useFindStep(location, flowState);

    /**
     * Steps
     */
    const [steps, setSteps] = useRecoilState(flowState);

    /**
     * Reset states
     */
    // BPF steps
    const resetBpfStepsState = useResetRecoilState(
        isVolumeBased
            ? generateVolumeBasedBpfStepsState
            : isPerformanceBased
              ? generatePerformanceBasedBpfStepsState
              : generateApheresisBasedBpfStepsState,
    );

    /**
     * BPF status
     */
    const [isBpfGenerated, setBpfStatus] = useState(false);

    /**
     * Reset BPF state partially
     */
    const resetBpfStatePartially = () => {
        const clonedSteps = clonedeep(steps);
        const updatedSteps = clonedSteps.map((step, index) => {
            return {
                ...step,
                isPrepared: index >= currentStepIndex ? false : step.isPrepared,
                isCompleted:
                    index >= currentStepIndex ? false : step.isCompleted,
            };
        });

        setSteps(updatedSteps);
    };

    /**
     * API
     */
    // Get outcomes
    const {
        loading: { creating },
        upsert: generateBPF,
        forceReset,
    } = useFinance("bpf", "outcome|outcomes");

    const resetAll = useCallback(() => {
        setBpfStatus(false);
        resetBpfStepsState();
        reset();
    }, []);

    // Reset on flow initiation
    useEffect(() => {
        if (location?.state?.isGenerateBpfFlow) {
            resetAll();
        }
    }, [location?.state?.isGenerateBpfFlow, resetAll]);

    useEffect(() => {
        // Reset when navigating to first step manually
        if (currentStepIndex !== 0 && !steps[0]?.isPrepared) {
            resetAll();
            navigate(`/finances/generate-bpf/${type}/${steps[0]?.id}/`);
        }
    }, [currentStepIndex, steps, type, resetAll]);

    /**
     * Use the value of the BPF type to send it back to BE and query the correct partner
     */
    const bpfType = useMemo(() => {
        if (isPerformanceBased) return "OUTCOME";
        if (isApheresisBased) return "REBATE_AT_APHERESIS";
        if (isVolumeBased) return "CLAIM";
    }, [isVolumeBased, isApheresisBased, isPerformanceBased]);

    /**
     * Handle generate bpf
     */
    const onGenerateBPF = async (
        files?: Record<
            string,
            {
                fileName: string;
                fileKey: string;
            }
        >,
    ) => {
        generateBPF({
            ...(mappedData as any),
            ...files,
            type: bpfType,
            evidence:
                isApheresisBased && !!files?.evidence
                    ? { ...files?.evidence }
                    : undefined,
        }).then(res => {
            if (res) {
                setBpfStatus(isSuccessfulCall(res?.status));
                setFailedFiles([]);
            }
        });
    };

    /**
     * Upload handler
     */
    const handleComplete = (complete: Array<any>) => {
        forceReset();
        const files = complete.reduce(
            (acc, item) => ({
                ...acc,
                [item.originalKey]: {
                    fileName: item.fileName,
                    fileKey: item.fileKey,
                },
            }),
            {},
        );

        onGenerateBPF(files);
    };

    /**
     * Retry handler
     */
    const handleRetry = (
        incomplete: Array<any>,
        retryCallback: (shouldRetry: boolean) => void,
    ) => {
        setFailedFiles(incomplete);
        (retryCallbackRef.current as any) = retryCallback;
    };

    /**
     * Modal confirm handler
     */
    const handleModalConfirm = (shouldRetry: boolean) => {
        if (!shouldRetry) {
            setFailedFiles([]);
            return;
        }

        if (retryCallbackRef.current) {
            retryCallbackRef.current(shouldRetry);
        }
    };

    /**
     * Generate BPF
     */
    const handleGenerateBPF = () => {
        const filesToScan = isApheresisBased
            ? [
                  ...(mappedData.evidence
                      ? [{ file: mappedData.evidence, originalKey: "evidence" }]
                      : []),
                  { file: mappedData?.invoice, originalKey: "invoice" },
              ]
            : [{ file: mappedData?.invoice, originalKey: "invoice" }];

        scan(filesToScan, setFailedFiles, handleComplete, handleRetry);
    };

    const saveCurrentState = () => {
        if (stepRef?.current?.updateState) {
            stepRef?.current?.updateState();
        }
        const copySteps = clonedeep(steps);
        copySteps[currentStepIndex].isCompleted = true;
        setSteps(copySteps);
    };

    /**
     * Handle next click
     */
    const handleNextClick = () => {
        saveCurrentState();

        if (currentStepIndex !== steps.length - 1) {
            navigate(
                `/finances/generate-bpf/${type}/${steps[currentStepIndex + 1]?.id}/`,
            );

            return;
        }
        return isBpfGenerated
            ? navigate(`/dashboard/finances/bpf/`)
            : handleGenerateBPF();
    };

    /**
     * Disable submit button
     */
    const disabled = useMemo(() => {
        if (currentStepIndex === steps.length - 1) {
            return !isBpfGenerated && steps.some(step => !step.isPrepared);
        }
        return !steps[currentStepIndex]?.isPrepared;
    }, [isBpfGenerated, currentStepIndex, steps]);

    /**
     * Render
     */
    return (
        <Fragment key={resetKey}>
            <Dialog
                open={!!failedFiles?.length}
                id={`dialog-confirm-scanning`}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Following files failed malware scanning, you can retry
                        scanning these files or opt to proceed uploading only
                        those files that passed the malware scan
                        {failedFiles.map((file, idx) => (
                            <Typography key={idx} variant="body2" ml={1}>
                                {file?.fileName}
                            </Typography>
                        ))}
                    </Typography>
                }
                primaryButton={{
                    text: "Retry",
                    action: () => {
                        handleModalConfirm(true);
                        // setFailedFiles([]);
                    },
                    loading: scanning,
                }}
                secondaryButton={{
                    text: "Cancel claim file upload",
                    action: () => {
                        handleModalConfirm(false);
                    },
                    loading: false,
                }}
            />
            <Breadcrumbs
                id={`generate-bpf-breadcrumb`}
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
                subPage={"New batch payment file"}
            />

            <FlowLayout>
                <FlowAside>
                    <Stepper
                        id={`generate-bpf-stepper`}
                        title={"New batch payment file"}
                        steps={steps}
                        location={location}
                        disabled={isBpfGenerated}
                        onStepClick={path =>
                            navigate(path, {
                                state: {
                                    isCreateBpfFlow: true,
                                },
                            })
                        }
                    />
                </FlowAside>

                <FlowBody>
                    <Fragment>
                        <FlowContent>
                            {isBpfGenerated && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    height={1}
                                >
                                    <Typography variant="h2">
                                        BPF successfully generated
                                    </Typography>
                                </Box>
                            )}

                            {!isBpfGenerated &&
                                React.Children.map(
                                    children as React.ReactElement,
                                    (child: React.ReactElement) =>
                                        React.cloneElement(child, {
                                            location,
                                            disabled: creating,
                                            id: `create-contract`,
                                            isVolumeBased,
                                            isApheresisBased,
                                            isPerformanceBased,
                                            canSelectMany: isGermanyTeam,
                                            flowState,
                                            ref: stepRef,
                                            key: resetKey,
                                            onReset: resetAll,
                                            resetBpfStatePartially,
                                        }),
                                )}
                        </FlowContent>
                        <FlowFooter>
                            <StepActions
                                id={`generate-bpf-step-actions`}
                                loading={creating}
                                primaryButton={{
                                    text: isBpfGenerated
                                        ? "Return to overview"
                                        : currentStepIndex === steps.length - 1
                                          ? "Generate BPF"
                                          : "Next step",
                                    isSubmitButton:
                                        isBpfGenerated ||
                                        currentStepIndex !== steps.length - 1,
                                    action: handleNextClick,
                                    disabled: disabled || creating,
                                }}
                                secondaryButton={{
                                    text: "Back",
                                    action: () =>
                                        navigate(
                                            `/finances/generate-bpf/${type}/${steps[currentStepIndex - 1]?.id}`,
                                        ),

                                    hidden:
                                        currentStepIndex === 0 ||
                                        isBpfGenerated,
                                }}
                                tertiaryButton={{
                                    text: "New batch payment file",
                                    action: () => {
                                        resetAll();
                                        navigate(
                                            `/finances/generate-bpf/${type}/${steps[0]?.id}/`,
                                        );
                                    },
                                    disabled: creating,
                                    hidden: !isBpfGenerated,
                                }}
                            />
                        </FlowFooter>
                    </Fragment>
                </FlowBody>
            </FlowLayout>
        </Fragment>
    );
};
export default Layout;
