// Icons
import EditIcon from "@mui/icons-material/Edit";
// Libs
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React, { Fragment, useEffect, useMemo } from "react";

// Own components
import { FieldRenderer, GrayBox, UserRights } from "@components";

// Constants
import { useMaintenance, useGetTeam, useGetRights } from "@hooks";

// Style
import * as style from "./style.module.scss";

/**
 * Props type
 */
interface Props {
    teamId: string;
    onEdit?: () => void;
    isEditingFinish: boolean;
    id: string;
}
/**
 * Team overview result
 */
const TeamOverview = ({ teamId, onEdit, isEditingFinish, id }: Props) => {
    /**
     * API
     */
    // get team
    const { getTeam, response: team, loading: teamLoading } = useGetTeam();
    /**
     * Fetch countries
     */

    /**
     * Get rights
     */
    const { getRights, response: rights } = useGetRights();

    const {
        list: countries,
        loading: { listLoading: loadingCountries },
    } = useMaintenance("country", "country|countries");

    /**
     * Therapeutic areas
     */
    const {
        list: therapeuticAreas,
        loading: { listLoading: loadingAreas },
    } = useMaintenance("therapeuticArea", "therapeuticArea|therapeuticAreas");

    useEffect(() => {
        if (teamId) {
            getTeam(teamId);
            getRights();
        }
    }, [teamId]);

    /**
     * Map Therapeutic Areas
     */

    const therapeuticArea = useMemo(() => {
        const areaList: Array<any> = [];
        team?.data.therapeuticAreaIds.map(area => {
            const areaValue =
                therapeuticAreas?.maintenanceTherapeuticAreaList?.find(
                    c => c.therapeuticAreaId === area,
                );
            areaList.push(areaValue?.therapeuticAreaName);
        });
        return areaList.join(", ");
    }, [team, therapeuticAreas]);

    /**
     * Map Countries
     */
    const country = useMemo(() => {
        const countryList: Array<any> = [];

        team?.data.countries.map(value => {
            const country = countries?.maintenanceCountryList?.find(
                c => c.countryIsoCode === value?.isoCode,
            );

            countryList.push(country?.country);
        });

        return countryList.join(", ");
    }, [team, countries]);

    /**
     * Count user approver and reviewer rights
     */
    const countRights = useMemo(() => {
        let contractReviewers = 0;
        let financialApprovers = 0;
        team?.data?.users.map(user => {
            user.rightIds?.map(right => {
                if (right === "RightId05") {
                    contractReviewers += 1;
                }
                if (right === "RightId15") {
                    financialApprovers += 1;
                }
            });
        });
        return { contractReviewers, financialApprovers };
    }, [team]);

    /**
     * Render Team result
     */
    return (
        <Fragment>
            {teamLoading || loadingCountries || loadingAreas ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : (
                <Box
                    height={1}
                    display="flex"
                    flexDirection="column"
                    className={style.wrapper}
                >
                    <Box mb={3}>
                        <Typography variant="h1">
                            {isEditingFinish
                                ? "Contract team was successfully updated"
                                : "Contract team was successfully created"}
                        </Typography>
                    </Box>
                    <Box className={style.card}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            mb={2}
                            alignItems="center"
                        >
                            <Typography variant="h3">
                                Contract team details
                            </Typography>
                            <IconButton
                                color="primary"
                                aria-label="Edit team contract"
                                onClick={onEdit}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Box>

                        <Grid container spacing={3}>
                            <Grid
                                item
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, md: 4 }}
                            >
                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-name`}
                                        label="Contract team name"
                                        value={team?.data?.teamName}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-country`}
                                        label="Select one or more countries"
                                        value={country}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-therapeutic-area`}
                                        label="Select one or more therapeutic areas"
                                        value={therapeuticArea}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, md: 4 }}
                            >
                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-min-reviewers`}
                                        label="Minimum number of contract reviewers"
                                        value={
                                            team?.data?.minimumContractReviewers
                                        }
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FieldRenderer
                                        id={`${id}-min-approvers`}
                                        label="Minimum number of financial approvers"
                                        value={
                                            team?.data
                                                ?.minimumFinancialApprovers
                                        }
                                        type="number"
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} mt={5}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h3">
                                        Team members
                                    </Typography>
                                    <Typography ml={2} variant="caption1">
                                        This team contains{" "}
                                        {team?.data?.users.length} user
                                        {team?.data?.users.length > 1
                                            ? "s"
                                            : ""}
                                        .
                                    </Typography>
                                </Box>
                                {countRights?.contractReviewers <
                                    team?.data?.minimumContractReviewers && (
                                    <Box mt={1}>
                                        <Alert severity="info">
                                            This team does not contain
                                            sufficient users with “Review
                                            contract” rights.
                                        </Alert>
                                    </Box>
                                )}
                                {countRights?.financialApprovers <
                                    team?.data?.minimumFinancialApprovers && (
                                    <Box mt={1}>
                                        <Alert severity="info">
                                            This team does not contain
                                            sufficient users with “Financial
                                            approver” rights.
                                        </Alert>
                                    </Box>
                                )}
                                {team?.data?.users.map((user, userIndex) => (
                                    <Box key={userIndex} mt={4}>
                                        <GrayBox
                                            id={`${id}-user-rights-details`}
                                            header={
                                                <Fragment>
                                                    <Box>
                                                        <FieldRenderer
                                                            id={`${id}-email`}
                                                            label=""
                                                            value={user.email}
                                                        />
                                                    </Box>
                                                </Fragment>
                                            }
                                            collapsible
                                        >
                                            <UserRights
                                                id={`${id}-user-rights`}
                                                disabled={true}
                                                rights={rights?.data}
                                                values={user.rightIds}
                                                approvalRoles={
                                                    user.approvalRoles
                                                }
                                            />
                                        </GrayBox>
                                    </Box>
                                ))}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            )}
        </Fragment>
    );
};

export default React.memo(TeamOverview);
