import { useAxios } from "./useAxios";
import { useRecoilState } from "recoil";
import { notificationsState } from "@atoms";
import { NOTIFICATIONS } from "@constants";
import type { Api, NotificationSeverity } from "@types";
import { isSuccessfulCall, startDownload } from "@utils";

const BASE_URI = `/v0`;

/**
 * Finance
 */

// Get list of outcomes eligible for BPF generation

export const useFinance = (path: string, label: string, type?: string) => {
    const url = `${BASE_URI}`;

    /**
     * update notifications atom with success messages
     */
    const [_, setNotifications] = useRecoilState(notificationsState);

    const {
        response: list,
        loading: fetching,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${url}/outcome/${path}`,
        },
        { errorHandler: `Failed to fetch ${label.split("|")[1]}` },
    );

    const {
        response: generateBpFResponse,
        loading: creating,
        error: createError,
        fetch: create,
        forceReset,
    }: Api = useAxios(
        {
            method: "POST",
            url: `${url}/${path}`,
        },
        //  { errorHandler: `Failed to add / update ${label.split("|")[0]}` },
    );

    const {
        response: bpfList,
        loading: fetchingBpfList,
        error: bpfListError,
        fetch: loadBpfList,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${url}/bpf`,
        },
        { errorHandler: `Failed to fetch ${label.split("|")[0]}` },
    );

    return {
        list: list,
        generateBpFResponse,
        bpfList,
        loading: { fetching, creating, fetchingBpfList },
        error: { list: listError, create: createError, bpfListError },
        upsert: (data: any) =>
            create({ data }).then(res => {
                if (isSuccessfulCall(res?.status)) {
                    setNotifications({
                        severity: NOTIFICATIONS.SEVERITY
                            .SUCCESS as NotificationSeverity,
                        message: `"${data.bpfName}" was successfully created`,
                        autoHide: true,
                    });
                }
                return res;
            }),
        search: (searchParams?: string) => {
            if (!type) return;

            return searchParams
                ? loadList({
                      url: `${url}/bpf/${type}${searchParams}`,
                  })
                : loadList({
                      url: `${url}/bpf/${type}`,
                  });
        },
        forceReset,
        loadBpfList: () => loadBpfList(),
        searchBpfList: (searchParams?: string) =>
            searchParams
                ? loadBpfList({
                      url: `${url}/bpf${searchParams}`,
                  })
                : loadBpfList(),
    };
};

export const useCreditNotes = (isCreditNoteClaim?: boolean) => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        getCreditNotes: (query?: string) =>
            fetch(
                {
                    method: "GET",
                    url: query
                        ? `${BASE_URI}/${isCreditNoteClaim ? "claim" : "outcome"}/credit-note${query}`
                        : `${BASE_URI}/${isCreditNoteClaim ? "claim" : "outcome"}/credit-note`,
                },
                { errorHandler: "Failed to fetch credit note" },
            ),
        error,
        loading,
        response,
    };
};

export const useOutcomeDetails = (type?: string) => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        getOutcomeDetails: (id: string) =>
            fetch(
                {
                    method: "GET",
                    url: `${BASE_URI}/outcomes/${id}`,
                },
                {
                    errorHandler: type
                        ? `Failed to fetch ${type} details`
                        : `Failed to fetch outcome details`,
                },
            ),
        error,
        loading,
        response,
    };
};

export const useInvoiceRelease = () => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        getInvoices: (query?: string) =>
            fetch(
                {
                    method: "GET",
                    url: query
                        ? `${BASE_URI}/outcome/invoice-release${query}`
                        : `${BASE_URI}/outcome/invoice-release`,
                },
                {
                    errorHandler: "Failed to fetch invoices",
                },
            ),
        error,
        loading,
        response,
    };
};

export const useReprocessFinanceCreditNote = (
    key: string,
    type?: "OUTCOME" | "CLAIM",
) => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        reprocessFinanceCreditNote: (id: string) =>
            fetch(
                {
                    method: "POST",
                    url:
                        type === "OUTCOME"
                            ? `${BASE_URI}/outcomes/${id}/process`
                            : `${BASE_URI}/claims/${id}/process`,
                },
                {
                    errorHandler: `Failed to reprocess ${key.split("|")[1]}`,
                    successHandler: `${
                        key.split("|")[0]
                    } was successfully reprocessed`,
                },
            ),
        error,
        loading,
        response,
    };
};

export const useEditBpfPaymentCondition = () => {
    const {
        response: list,
        loading,
        error,
        fetch,
        forceReset,
    }: Api = useAxios(
        {
            method: "PUT",
        },
        { errorHandler: `Failed to edit BPF` },
    );

    const { loading: sendingForApproval, fetch: sendForApprovalFetch }: Api =
        useAxios(
            {
                method: "POST",
            },
            { errorHandler: `Failed to send for approval` },
        );

    return {
        list,
        loading: loading || sendingForApproval,
        error,
        load: (bpfId: string, data: any) =>
            fetch({
                url: `${BASE_URI}/bpf/${bpfId}`,
                data,
            }),
        forceReset,
        sendForApproval: (bpfId: string, name: string) =>
            sendForApprovalFetch(
                {
                    url: `${BASE_URI}/bpf/${bpfId}/approve`,
                    method: "POST",
                },
                {
                    successHandler: `BPF (${name}) was successfully sent for approval`,
                },
            ),
    };
};

export const useProcessBpf = () => {
    const {
        response: list,
        loading,
        error,
        fetch,
    }: Api = useAxios(
        {
            method: "POST",
        },
        { errorHandler: `Failed to mark BPF as "Processed"` },
    );

    return {
        list,
        loading,
        error,
        load: (bpfId: string) =>
            fetch({
                url: `${BASE_URI}/bpf/${bpfId}/process`,
            }),
    };
};

export const useDownloadBpf = () => {
    const {
        response: list,
        loading,
        error,
        fetch,
    }: Api = useAxios(
        {
            method: "GET",
        },
        { errorHandler: `Failed to download BPF` },
    );

    return {
        list,
        loading,
        error,
        load: (bpfId: string) =>
            fetch({ url: `${BASE_URI}/bpf/${bpfId}/download` }).then(res => {
                if (isSuccessfulCall(res?.status) && !!res?.data?.location) {
                    startDownload(res.data.location);
                    return res;
                }
            }),
    };
};

export const useDownloadApheresisPdf = () => {
    const {
        response: list,
        loading,
        error,
        fetch,
    }: Api = useAxios(
        {
            method: "GET",
        },
        { errorHandler: `Failed to download Apheresis validation report` },
    );

    return {
        list,
        loading,
        error,
        download: (outcomeId: string) =>
            fetch({ url: `${BASE_URI}/pdf/apheresis/${outcomeId}` }).then(
                res => {
                    if (
                        isSuccessfulCall(res?.status) &&
                        !!res?.data?.location
                    ) {
                        startDownload(res.data.location);
                        return res;
                    }
                },
            ),
    };
};

export const useDownloadOutcomePdf = () => {
    const {
        response: list,
        loading,
        error,
        fetch,
    }: Api = useAxios(
        {
            method: "GET",
        },
        { errorHandler: `Failed to download outcome PDF` },
    );

    return {
        list,
        loading,
        error,
        download: (outcomeId: string) =>
            fetch({ url: `${BASE_URI}/pdf/outcome/${outcomeId}` }).then(res => {
                if (isSuccessfulCall(res?.status) && !!res?.data?.location) {
                    startDownload(res.data.location);
                    return res;
                }
            }),
    };
};

export const useGetBpfById = () => {
    const {
        response: list,
        loading,
        error,
        fetch,
    }: Api = useAxios(
        {
            method: "GET",
        },
        { errorHandler: `Failed to fetch BPF details` },
    );

    const {
        loading: downloadingEvidence,
        error: downloadEvidenceError,
        fetch: downloadEvidence,
    }: Api = useAxios(
        {
            method: "GET",
        },
        { errorHandler: `Failed to download evidence` },
    );

    return {
        list,
        loading: { loading, downloadingEvidence },
        error: { error, downloadEvidenceError },
        load: (bpfId: string) => fetch({ url: `${BASE_URI}/bpf/${bpfId}` }),
        downloadEvidence: (bpfId: string, fileName: string, type: string) =>
            downloadEvidence(
                {
                    url: `${BASE_URI}/bpf/${bpfId}/evidence/download?type=${type}`,
                },
                {
                    errorHandler: `Failed to download ${fileName}`,
                },
            ).then(res => {
                if (isSuccessfulCall(res?.status) && !!res?.data?.location) {
                    startDownload(res.data.location);
                    return res;
                }
                return res;
            }),
    };
};

export const useRemoveFinancialTransactions = () => {
    const {
        response: list,
        loading,
        error,
        fetch,
    }: Api = useAxios(
        {
            method: "POST",
        },
        { errorHandler: `Failed to delete transactions` },
    );

    return {
        list,
        loading,
        error,
        load: (
            bpfId: string,
            transactionsList: Array<string>,
            financeType: string,
        ) =>
            fetch({
                url: `${BASE_URI}/bpf/${bpfId}/removeTransactions`,
                data: { assignedIds: transactionsList, financeType },
            }),
    };
};

export const useBpfPaymentCondition = () => {
    const {
        response: list,
        loading,
        error,
        fetch,
    }: Api = useAxios(
        {
            method: "PUT",
        },
        { errorHandler: `Failed to fetch BPF payment condition` },
    );

    return {
        list,
        loading,
        error,
        load: (contractIds: Array<string>) =>
            fetch({
                url: `${BASE_URI}/contracts/bpf/paymentCondition`,
                data: { contractIds: contractIds },
            }),
    };
};

export const useRecallBpf = () => {
    const {
        response: list,
        loading,
        error,
        fetch,
    }: Api = useAxios(
        {
            method: "POST",
        },
        { errorHandler: `Failed to recall BPF` },
    );

    return {
        list,
        loading,
        error,
        load: (bpfId: string, bpfName: string) =>
            fetch(
                {
                    url: `${BASE_URI}/bpf/${bpfId}/recall`,
                },
                {
                    errorHandler: `Failed to recall BPF (${bpfName})`,
                    successHandler: `BPF (${bpfName}) was successfully recalled`,
                },
            ),
    };
};
