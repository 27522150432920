// Lib
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useFormik, FormikProps } from "formik";
import React, {
    useEffect,
    useImperativeHandle,
    useCallback,
    useMemo,
    useState,
    Fragment,
} from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import debounce from "lodash.debounce";

// Own components
import { FormFooter, GeneralInformationForm, Dialog } from "@components";

//custom hooks
import {
    useBrands,
    useCountries,
    useIndications,
    useTherapeuticAreas,
    useUpdateStep,
    useFormValidation,
    useContractPartners,
    useValidateDuplication,
} from "@hooks";

// Atoms
import {
    generalInformationState,
    productsDetailsState,
    financialConditionsState,
    contractMilestonesState,
    createVolumeBasedContractStepsState,
    createPerformanceBasedContractStepsState,
} from "@atoms";

// Schemas
import {
    performanceGeneralInformationSchema,
    volumeGeneralInformationSchema,
} from "@schemas";

// Types
import type {
    FlowStep,
    Location,
    GeneralInformation as GeneralInformationType,
} from "@types";

// Utils
import { constructQueryString, isArrayWithContent } from "@utils";

/**
 * Props type
 */
interface Props {
    location?: Location;
    currentStep?: FlowStep;
    flowState?: Array<FlowStep>;
    contractType?: string;
}

/**
 * General Information
 */
const GeneralInformation = React.forwardRef(
    ({ location, currentStep, flowState, contractType }: Props, ref) => {
        const [resetFlowWarning, setResetFlowWarning] = useState<
            | {
                  field: string;
                  pendingKey: string;
                  pendingValue: any;
                  oldValue: any;
              }
            | undefined
        >();

        const resetProductsDetailsState =
            useResetRecoilState(productsDetailsState);
        const resetFinancialConditionsState = useResetRecoilState(
            financialConditionsState,
        );

        const resetContractMilestonesState = useResetRecoilState(
            contractMilestonesState,
        );
        const [volumeBasedSteps, setVolumeBasedSteps] = useRecoilState(
            createVolumeBasedContractStepsState,
        );
        const [performanceBasedSteps, setPerformanceBasedSteps] =
            useRecoilState(createPerformanceBasedContractStepsState);

        /**
         * Check the uniqueness of contract ref
         */
        const {
            validate,
            isUniqueValue,
            isNotUniqueValue,
            isNotUniqueMessage,
            loading: duplicationValidating,
        } = useValidateDuplication("contractRef");

        /**
         * API
         */

        const { list: brands, loading: brandsLoading } = useBrands();

        const { list: indications, loading: indicationsLoading } =
            useIndications();

        const {
            getTherapeuticAreas,
            response: therapeuticAreas,
            loading: therapeuticAreasLoading,
        } = useTherapeuticAreas();

        //countries
        const { list: countries, loading: loadingCountries } = useCountries();

        // Partners
        const {
            getPartners,
            response: partners,
            loading: partnersLoading,
        } = useContractPartners();

        /**
         * Recoil states
         */
        // General information state
        const [generalInformationInitialState, updateGeneralInformationState] =
            useRecoilState(generalInformationState);
        // Products details state
        const productDetails = useRecoilValue(productsDetailsState);

        /**
         *  Update step hook
         */
        const updateStepValidation = useUpdateStep(location, flowState);

        //Formik state
        const formik: FormikProps<GeneralInformationType> =
            useFormik<GeneralInformationType>({
                enableReinitialize: true,
                validateOnMount: true,
                initialValues: {
                    ...generalInformationInitialState,
                },
                validationSchema:
                    contractType === "PERFORMANCE"
                        ? performanceGeneralInformationSchema
                        : volumeGeneralInformationSchema,
                onSubmit: () => undefined,
            });

        /**
         * Form validation
         */

        const { isValidForm, hasErrors } = useFormValidation(
            formik.errors,
            formik.touched,
            currentStep,
            ["icdUrl"],
        );

        /**
         * Check if the current step is valid
         * then set isPrepared to true, otherwise set it false
         */

        useEffect(() => {
            const canSaveState =
                (formik.isValid || isValidForm) && isUniqueValue;

            updateStepValidation(canSaveState);
        }, [isValidForm, isUniqueValue, formik.isValid]);

        useEffect(() => {
            if (!formik.values?.country?.isoCode) return;
            const query = constructQueryString(
                {
                    countryIsoCode: formik.values?.country?.isoCode,
                },
                true,
            );

            getTherapeuticAreas(query);
        }, [formik.values.country]);

        /**
         * Save state and go to the next page (controlled by the layout)
         */
        useImperativeHandle(ref, () => ({
            updateState() {
                updateGeneralInformationState(formik.values);
            },
        }));

        const debounceLoadData = useCallback(
            debounce(
                (contractRef, query) =>
                    validate(undefined, { contractRef }, query),
                600,
            ),
            [],
        );

        useEffect(() => {
            if (!formik?.values?.reference) {
                return;
            }

            const query = constructQueryString(
                {
                    countryIsoCode: countries?.data?.records[0]?.isoCode,
                },
                true,
            );

            debounceLoadData(formik.values.reference, query);
        }, [formik?.values?.reference, countries?.data?.records]);

        const contractRefValidation = useMemo(() => {
            return {
                isNotUniqueValue,
                isNotUniqueMessage,
                duplicationValidating,
            };
        }, [isNotUniqueValue, isNotUniqueMessage, duplicationValidating]);

        const resetCountryRelatedFields = (country: {
            isoCode: string;
            name: string;
        }) => {
            formik.setValues({
                ...formik.values,
                country,
                primaryPartner: null,
                therapeuticArea: {
                    therapeuticAreaId: "",
                    therapeuticAreaName: "",
                },
            });
        };

        /**
         * Reset only general info related states if the type is volume
         * otherwise reset all
         */
        const resetOnlyGeneralInfoRelatedStates = () => {
            resetProductsDetailsState();
            resetFinancialConditionsState();
            resetContractMilestonesState();

            if (
                !!resetFlowWarning?.pendingKey &&
                !!resetFlowWarning?.pendingValue
            ) {
                formik.setFieldValue(
                    resetFlowWarning?.pendingKey,
                    resetFlowWarning?.pendingValue,
                );
            }

            if (contractType === "PERFORMANCE") {
                const copySteps = [...performanceBasedSteps];
                const updatedSteps = copySteps.map(el => {
                    if (el?.id === "general-information") return el;
                    return { ...el, isPrepared: false, isCompleted: false };
                });

                setPerformanceBasedSteps(updatedSteps);
            } else {
                const copySteps = [...volumeBasedSteps];
                const updatedSteps = copySteps.map(el => {
                    if (el?.id === "model" || el?.id === "category-tags")
                        return el;
                    return { ...el, isPrepared: false, isCompleted: false };
                });
                setVolumeBasedSteps(updatedSteps);
            }

            setResetFlowWarning(undefined);
            formik.setTouched({});

            return;
        };

        /**
         * Cancel reset flow
         */
        const onCancelRestFlow = () => {
            if (resetFlowWarning?.oldValue) {
                formik.setFieldValue(
                    resetFlowWarning?.pendingKey,
                    resetFlowWarning?.oldValue,
                );
            }
            setResetFlowWarning(undefined);
        };

        /**
         * Check if there is a product in the list
         */
        const canResetFlow = useMemo(() => {
            return isArrayWithContent(productDetails?.products);
        }, [productDetails]);

        return (
            <Fragment>
                <Dialog
                    title={"Reinitialize Contract Creation Process"}
                    id={`reset-flow-dialog`}
                    open={!!resetFlowWarning}
                    message={
                        <Typography
                            variant="subtitle2"
                            color="black"
                            component="span"
                        >
                            {`You are about to change the contract ${resetFlowWarning?.field}. Doing so will remove the filled data. This action cannot be undone.`}
                        </Typography>
                    }
                    primaryButton={{
                        text: "Confirm",
                        action: () => {
                            resetOnlyGeneralInfoRelatedStates();
                        },
                    }}
                    secondaryButton={{
                        text: "Cancel",
                        action: () => onCancelRestFlow(),
                    }}
                />

                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height={1}
                >
                    <div>
                        <Box mb={7}>
                            <Typography variant="h2">
                                General information
                            </Typography>
                        </Box>
                        <GeneralInformationForm
                            id={"general-info-form"}
                            resetCountryRelatedFields={
                                resetCountryRelatedFields
                            }
                            formik={formik}
                            brands={{
                                data: brands?.data,
                                loading: brandsLoading,
                            }}
                            indications={{
                                data: indications?.data,
                                loading: indicationsLoading,
                            }}
                            countries={{
                                data: countries?.data?.records,
                                loading: loadingCountries,
                            }}
                            therapeuticAreas={{
                                data: therapeuticAreas?.data,
                                loading: therapeuticAreasLoading,
                            }}
                            partners={{
                                data: partners?.data?.partners,
                                loading: partnersLoading,
                                onSearch: (query: string) => {
                                    getPartners(
                                        `${query}${constructQueryString({
                                            countryIsoCode:
                                                formik.values?.country?.isoCode,
                                        })}`,
                                    );
                                },
                            }}
                            contractRefValidation={contractRefValidation}
                            setResetFlowWarning={(
                                field: string,
                                pendingKey: string,
                                pendingValue: any,
                                oldValue?: any,
                            ) =>
                                setResetFlowWarning({
                                    field,
                                    pendingKey,
                                    pendingValue,
                                    oldValue,
                                })
                            }
                            canResetFlow={canResetFlow}
                            isPerformanceBasedContract={
                                contractType === "PERFORMANCE"
                            }
                        />
                    </div>
                    <FormFooter
                        error={hasErrors}
                        textAlign="right"
                        id={`general-info-footer`}
                    />
                </Box>
            </Fragment>
        );
    },
);

export default React.memo(GeneralInformation);
